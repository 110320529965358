import { createContext, useContext, useState } from "react";


interface SearchContextObj {
    keyword: string,
    setKeyword: Function
  }
  export const SearchContext = createContext<SearchContextObj|null>(null);
  
  export function useSearchBarContext() : SearchContextObj | null{
    return useContext(SearchContext);
  }
  
  /**
   * 全局搜索
   */
  export default function Search(props: {children: any}) {
      const { children} = props ;
  
      const [keyword, setKeyword] = useState<string>('');
  
      return (
          <>
            <SearchContext.Provider value={{
              keyword, setKeyword
            }}>
              {children}
            </SearchContext.Provider>
          </>
      );
  
  }