import HttpLayout from "context/httpContext";
import LoadingLayout from "context/loadingLayout";

export default function Global(props : { children : any}){
    return (
        <>
            <HttpLayout>
                <LoadingLayout>
                    {props.children}
                </LoadingLayout>
            </HttpLayout>
        </>
    )
}