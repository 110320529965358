export class UIIndex{
    constructor() {
        if (!UIIndex.instance) {
            this.mCurrentIndexs = []; // 设置属性或其他初始化操作
            UIIndex.instance = this;
        } else {
            return UIIndex.instance;
        }
    }

    generateIndex(){
        var mCurrentIndex = 1000;
        if (this.mCurrentIndexs.length == 0) {
            this.mCurrentIndexs.push(mCurrentIndex);
            return 1000;
        }
        mCurrentIndex = this.mCurrentIndexs[this.mCurrentIndexs.length - 1];
        mCurrentIndex++;
        while (this.mCurrentIndexs.includes(mCurrentIndex))
            mCurrentIndex++;
        this.mCurrentIndexs.push(mCurrentIndex);
        return mCurrentIndex;
    };

    insertProjectDataIndex(){

    };
    resetIndex(){
        this.mCurrentIndexs = [];
    };

    generateUUID()
    {
        var d = new Date().getTime(); //Timestamp
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16; //random number between 0 and 16
            if(d > 0) { //Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else { //Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
};