import { useUpdatorContext } from "../../context/updator";
import { HttpUtil } from "../../libs/utils/http";
import { useEffect, useState } from "react";

type User  = {
    email ?: string
    score ?: number
}

export default function Score(props: {children: any, className?: string}){
    const [user, setUser] = useState<User>();
    const updatorContext = useUpdatorContext();

    useEffect(() => {
        syncUserInfo();
    },[updatorContext?.statsNumber]);

    const syncUserInfo = async () => {
        const res = await HttpUtil.post('/biz/user/info',{});
        setUser(res.data)
    }

    const {children, className = ''} = props ;
    return (
        <div className={className}>
            <div className="flex gap-[10px]">
                {children}
                <div className="flex justify-center">
                    <span className="flex items-center gap-[6px] px-[14px] border border-[#002EFF] border-r-0 rounded-l-[10px] text-[#5170FF]"><img src="/icon/icon-score.png" className="w-[20px] h-[20px]"/> <span>{user?.score}</span></span>
                    <button className="flex items-center rounded-r-[10px] bg-gradient-to-r from-[#5170FF] to-[#002EFF] text-[#FFFFFF] px-[10px]">Upgrade</button>
                </div>
                <span className="flex w-[35px] h-[35px] items-center justify-center rounded-full  bg-[#e4e5e7]">{user?.email?.substring(0,2).toLocaleUpperCase()}</span>
            </div>
        </div>
    )
}