import { useEffect, useState } from 'react';
import { ChromePicker, ColorResult} from 'react-color'

type ColorPicker = {
    isShow : boolean,
    color : string,
    index : number,
    x : number, 
    y : number,
}

/**
 * 颜色选择器
 * @param props 属性，包括初始展示的颜色数组，与选择颜色值后的回调
 * @returns 
 */
export default function ColorSelector(props: {colors : string[], onSelectedColor : Function}){

    const {colors, onSelectedColor} = props ;

    const [colorPicker, setColorPicker] = useState<ColorPicker | null>(null);

    useEffect(() => {
        const hiddenPickerEvent = () => {
            setColorPicker(null);
        }

        document.body.addEventListener('click', hiddenPickerEvent);
        return () => {
            document.body.removeEventListener('click', hiddenPickerEvent);
        }
    },[]);
    
    const selectedColor = (e: any, color: string, index: number) => {
        const {x, y} = e.target.getBoundingClientRect();
        setColorPicker({
            isShow: true,
            color, index, x, y
        })
        e.stopPropagation();
    }

    const changeColor = (color: ColorResult) => {
        if(colorPicker){
            // hex hsl hsv rgb
            const {hex} = color ;

            colorPicker && setColorPicker({...colorPicker, color: hex})
            // 回调父级
            onSelectedColor && onSelectedColor(hex, colorPicker?.index);
        }
    }


    return (
        <div className="absolute right-0 top-0 bg-[#FFFFFF] w-[235px] h-full p-[20px]">
            <p className="text-[16px] font-bold">Mockup Colors</p>
            <div className="flex flex-wrap mt-[15px] gap-[10px]">
                {
                    colors.map((color: string, index: number) => (
                        <span
                            style={{ backgroundColor : color}}
                            className={`border border-[#D9D9D9] rounded-[5px] w-[25px] h-[25px] block cursor-pointer`}
                            key={color}
                            onClick={(e) => selectedColor(e, color, index)}></span>
                    ))
                }
            </div>
            {
                colorPicker?.isShow &&
                <div
                    style={{ left : `${colorPicker.x - 200}px`, top: `${colorPicker.y  + 30}px`}} 
                    className={`fixed`}
                    onClick={(e) => e.stopPropagation()}>
                    <ChromePicker color={colorPicker.color} disableAlpha={true} className="rounded-none" 
                        onChangeComplete={(color) => changeColor(color)} />
                </div>
            }
            
        </div>
    )
}