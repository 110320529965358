import { useNavigate } from "react-router";
import { useHttpUtil } from "context/httpContext";

export function useProject() : [(projectId ?: string) => Promise<void>, (projectId: string) => void]{
    const navigate = useNavigate();
    const httpUtil = useHttpUtil();

    const gotoAdd = async (projectId : string = '') : Promise<void> => {
        const data = await httpUtil.post('/biz/project/add', {
            projectId
        })
        if(!data.errCode){
            gotoEditor(data.data.projectId)
        }
    }

    const gotoEditor = (projectId: string) : void => {
        navigate('/project/editor/' + projectId)
    }

    return [gotoAdd, gotoEditor];
}