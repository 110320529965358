export const UISelectEventType={
    Selected:0,//选中了事件
    Moved:1,//移动结束事件
    Rotated:2,//旋转结束事件
    Scaled:3,//缩放结束事件
    QuitSelected:4,//取消选中事件
    Deleted: 5,//删除事件
    Added:6,//添加事件
    StartMove:7,//準備移動中
    Moving:8,//正在移动中
    None:9,
    StartScale:10,//开始缩放
    StartRotate:11,//开始旋转
    Scaling:12,//缩放中
    Rotating:13,//旋转中
    EraseDrawing:14, //擦除面板绘制中
    SwitchType:15,//改变类型
    SaveImage:16,//保存图像
};
