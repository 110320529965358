import axios, { AxiosProgressEvent } from "axios";
import Cookies from "js-cookie";
import { createContext, useContext, useState } from "react";


interface HttpContextObj {
  response: Function
}

export const HttpContext = createContext<HttpContextObj|null>(null);

export function useHttpContext() : HttpContextObj | null{
    return useContext(HttpContext)
}

enum TipType {
    NONE = 'none',
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success'
}

export default function HttpLayout(props: {children: any}) {
    const {children} = props ;

    // const [code, setCode] = useState<number>();
    const [msg, setMsg] = useState<string>('');
    const [tipType, setTipType] = useState<TipType>();

    const response = (code: number, msg?: string) => {
        console.info('response', code, msg)
        // setCode(code);
        setMsg(msg || '');

        switch(code){
            case 500:
                break;
            case 0 :
                setTipType(msg ? TipType.SUCCESS : TipType.NONE);
                break;
            default:
                setTipType(TipType.WARNING)
                break;
        }

        setTimeout(() => {
            setTipType(TipType.NONE)
        },5000)
    }

    return (
        <>
          <HttpContext.Provider value={{
            response
          }}>
            {
                
                tipType === TipType.ERROR ?
                    <div className="absolute w-full h-full left-0 top-0 z-[999999] bg-[#FFFFFF]">
                        <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[16px]">
                            {msg}
                        </div>
                    </div>
                :
                <>
                    {
                        tipType === TipType.WARNING || tipType === TipType.SUCCESS ?
                            <div className="absolute left-[50%] top-[30px] translate-x-[-50%] text-[14px] z-[9999] animate-t-b">
                                {
                                    tipType === TipType.WARNING ?
                                     <p className={`min-w-[200px] py-[8px] px-[15px] text-[#e97a17] bg-[#f5d1bb] rounded-[5px] text-center`}>{msg}</p>
                                    : 
                                     <p className={`min-w-[200px] py-[8px] px-[15px] text-[#d1edc8] bg-[#4eb112] rounded-[5px] text-center`}>{msg}</p>
                                }
                            </div>
                        : null
                    }
                    {children}
                </>
            }
           {/* <div className="absolute left-[50%] top-[30px] translate-x-[-50%] text-[14px] z-[9999] animate-t-b">
                <p className={`min-w-[200px] py-[8px] px-[15px] text-[#d1edc8] bg-[#4eb112] rounded-[5px] text-center`}>Success</p>
            </div> */}
          </HttpContext.Provider>
        </>
    );
}

    
export type ResponseData = {
    errCode: number,
    errMsg ?: string,
    data ?: any,
    token ?: string
}

const ContentTypeKind = {
    JSON : 'application/json',
    FILE : 'multipart/form-data'
}


type AuthorizationHeader = {
    Authorization: string
}

type ContentTypeHeader = {
    'Content-Type': string
}

function customerAuthorizationHeader() : AuthorizationHeader {
    return {
        Authorization : Cookies.get('Authorization') || ''
    }
}

function contentTypeHeader(contentType: string = ContentTypeKind.JSON) : ContentTypeHeader {
    return {
        "Content-Type" : contentType
    }
}

type HttpUtil = {
    download : Function,
    post: Function,
    upload: Function,
    image: Function
}

export function useHttpUtil(): HttpUtil{
    const httpContent = useHttpContext();

    const post = async (url: string, bodyData: any) : Promise<ResponseData> => {
        try{
            const response = await axios.post(process.env.REACT_APP_API_DOMAIN + url, bodyData, {
                headers : {
                    ...customerAuthorizationHeader(),
                    ...contentTypeHeader()
                }
            });
            const responseData = response.data ;
            httpContent?.response(responseData.errCode, responseData.errMsg)
            return responseData;
        }catch(e){
            httpContent?.response(500, 'Internal server error, please refresh and try again')
            return {
                errCode: 500,
                errMsg: 'Error'
            } ;
        }
    }
    
    const upload = async (file: File, progressListener?: Function) : Promise<ResponseData> => {
        const formData = new FormData();
        formData.append('file', file);
        
        try {
            const response = await axios.post(process.env.REACT_APP_API_DOMAIN + '/biz/upload', formData, {
              headers :{
                ...customerAuthorizationHeader(),
                ...contentTypeHeader(ContentTypeKind.FILE)
              },
              onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                console.info('onUploadProgress',progressEvent )
                const { loaded, total = 1 } = progressEvent;
                progressListener && progressListener(Math.ceil(loaded * 100 / total))
              }
            });
            const responseData = response.data ;
            httpContent?.response(responseData.errCode, responseData.errMsg)
            return responseData ;
          } catch (error) {
            return {
                errCode: 500,
                errMsg: 'Service error'
            }
          }
    }
    
    const download = async(url: string, name: string) => {
        const download_url = process.env.REACT_APP_API_DOMAIN + '/biz/images/download?url=' + url ;
        try{
            const response = await axios.get(download_url, {
                headers : {
                    ...customerAuthorizationHeader()
                },
                responseType: 'blob'
            });
            // 创建一个 URL 对象
            const imageUrl = window.URL.createObjectURL(new Blob([response.data]));
    
            // 创建一个链接元素
            const link = document.createElement('a');
            link.href = imageUrl;
            link.setAttribute('download', name + '.' + url.split('.').pop()); // 设置下载的文件名
    
            // 触发下载
            document.body.appendChild(link);
            link.click();
    
            // 清理
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(imageUrl);
        }catch(e){
            // console.info(e);
            return {
                errCode: 500,
                errMsg: 'Error'
            } ;
        }
    
    }


    const image = async (url: string, progressListener: Function) => {
        await axios.get(url, {
            responseType: 'blob',
            onDownloadProgress(progressEvent: AxiosProgressEvent) {
                // console.info('onDownloadProgress',progressEvent )
                const { progress = 0 } = progressEvent;
                progressListener && progressListener(Math.ceil(progress * 100))
            },
        })
    }

    return { download, post, upload, image}
}