import { useNavigate, useLocation } from "react-router";

export default function Menubar(){
    const navigate = useNavigate();
    const {pathname} = useLocation() ;

    const gotoPage = (path: string) => {
        navigate(path);
    }

    return (
        <div className="flex h-full flex-col flex-none w-[210px] mx-[10px]">
            {[
                ['Project', '/icon/icon-project.png', '/'],
                ['History', '/icon/icon-history.png', '/history'],
            ].map(([name, icon, path]) => (
                <div className={`flex items-center justify-center gap-[15px] py-[10px] cursor-pointer ${ path == pathname ? 'bg-[#F8F8F8]' : ''} `}
                    key={name}
                    onClick={() => gotoPage(path)}>
                    <img src={icon} className="w-[25px] h-[25px]"/>
                    <span>{name}</span>
                </div>
            ))}
        </div>
    )
}