export class UISorptionBoundLines {
    constructor() {
        if (!UISorptionBoundLines.instance) {
            UISorptionBoundLines.instance = this;
        } else {
            return UISorptionBoundLines.instance;
        }
    }

    //平行线吸附逻辑
//获取平衡线
    getBoundLines(elementBaseValue)
    {

        var leftUP=[elementBaseValue.mPosX,elementBaseValue.mPosY];
        leftUP= this.transformPoint(leftUP,[elementBaseValue.mPosX,elementBaseValue.mPosY],elementBaseValue.rotation/180*Math.PI,1,1,0,0,elementBaseValue.mShowWidth,elementBaseValue.mShowHeight);

        var rightUP=[elementBaseValue.mPosX+elementBaseValue.mShowWidth,elementBaseValue.mPosY];
        rightUP= this.transformPoint(rightUP,[elementBaseValue.mPosX,elementBaseValue.mPosY],elementBaseValue.rotation/180*Math.PI,1,1,0,0,elementBaseValue.mShowWidth,elementBaseValue.mShowHeight);


        var leftDown=[elementBaseValue.mPosX,elementBaseValue.mPosY+elementBaseValue.mShowHeight];
        leftDown= this.transformPoint(leftDown,[elementBaseValue.mPosX,elementBaseValue.mPosY],elementBaseValue.rotation/180*Math.PI,1,1,0,0,elementBaseValue.mShowWidth,elementBaseValue.mShowHeight);


        var rightDown=[elementBaseValue.mPosX+elementBaseValue.mShowWidth,elementBaseValue.mPosY+elementBaseValue.mShowHeight];
        rightDown=this.transformPoint(rightDown,[elementBaseValue.mPosX,elementBaseValue.mPosY],elementBaseValue.rotation/180*Math.PI,1,1,0,0,elementBaseValue.mShowWidth,elementBaseValue.mShowHeight);


        var center=[elementBaseValue.mPosX+elementBaseValue.mShowWidth/2,elementBaseValue.mPosY+elementBaseValue.mShowHeight/2];

        var centerLeft=[0,0];
        centerLeft[0]=(leftUP[0]+leftDown[0])/2;
        centerLeft[1]=(leftUP[1]+leftDown[1])/2;

        var centerRight=[0,0];
        centerRight[0]=(rightUP[0]+rightDown[0])/2;
        centerRight[1]=(rightUP[1]+rightDown[1])/2;

        var centerTop=[0,0];
        centerTop[0]=(leftUP[0]+rightUP[0])/2;
        centerTop[1]=(leftUP[1]+rightUP[1])/2;

        var centerBottom=[0,0];
        centerBottom[0]=(leftDown[0]+rightDown[0])/2;
        centerBottom[1]=(leftDown[1]+rightDown[1])/2;


        var lineLeft=[leftUP,centerLeft,leftDown];
        var lineTop=[leftUP,centerTop,rightUP];
        var lineRight=[rightUP,centerRight,rightDown];
        var lineBottom=[rightDown,centerBottom,leftDown];
        var lineCenterHor=[centerLeft,center,centerRight];
        var lineCenterVer=[centerTop,center,centerBottom];

        return [lineLeft,lineTop,lineRight,lineBottom,lineCenterHor,lineCenterVer];
    }



    //一点绕另外一点缩放，旋转，平移
    transformPoint(point, origin, rotation, scalex, scaley,translatex,translatey,dstw,dsth) {
        var x1 = point[0];
        var y1 = point[1];
        var x2 = origin[0];
        var y2 = origin[1];
        // 平移至原点
        var x3 = x1 - x2;
        var y3 = y1 - y2;


        // 缩放
        var x5 = x3 * scalex;
        var y5 = y3 * scaley;


        // 旋转
        var cos = Math.cos(rotation);
        var sin = Math.sin(rotation);
        var cx=dstw/2;
        var cy=dsth/2;
        var xoffset=x5-cx;
        var yoffset=y5-cy;
        var x4 = xoffset * cos - yoffset * sin;
        var y4 = xoffset * sin + yoffset * cos;

        var x6=x4+cx;
        var y6=y4+cy;



        // 平移回原来的位置
        var x = x6 + x2;
        var y = y6 + y2;
        return [(x+translatex), (y+translatey)];
    }


//判断是否吸附
    sorptionBoundLines(x,y,elementBaseValue,elementBaseValueList,sorptionDistance)
    {
        var lines=[];
        var alignLines=this.getBoundLines(elementBaseValue);


        var xMinOffset=sorptionDistance;
        var yMinOffset=sorptionDistance;

        for(var i=0;i<elementBaseValueList.length;i++)
        {
            if(elementBaseValueList[i].uuid!=elementBaseValue.uuid) {
                var align = this.getBoundLines(elementBaseValueList[i]);

                for(var k=0;k<alignLines.length;k++)
                {
                    for(var m=0;m<align.length;m++)
                    {
                        var result = this.getDistanceBetweenParallelLines(alignLines[k],align[m]);
                        //console.log("当前数据为：："+k+"::"+m+"::"+result);

                        if(result[0]==1)
                        {
                            if(result[4]<=sorptionDistance&&result[4]>=0)
                            {

                                //得到吸附线
                                var result_lines=[];

                                //计算线段的中心点位置离原点的距离来判断线段的先后顺序
                                var center1=alignLines[k][1];
                                var center2=align[m][1];

                                if(Math.pow(center1[0],2)+Math.pow(center1[1],2)>Math.pow(center2[0],2)+Math.pow(center2[1],2))
                                {
                                    for(var g=0;g<align[m].length;g++)
                                    {
                                        if(!result_lines.includes(align[m][g]))
                                        {
                                            result_lines.push(align[m][g]);
                                        }
                                    }

                                    for(var g=0;g<alignLines[k].length;g++)
                                    {
                                        if(!result_lines.includes(alignLines[k][g]))
                                        {
                                            //需要判断哪个点与末尾的点的位置距离最近
                                            result_lines.push(alignLines[k][g]);
                                        }
                                    }

                                }else
                                {
                                    for(var g=0;g<alignLines[k].length;g++)
                                    {
                                      //  if(!result_lines.includes(alignLines[m][g]))
                                        {
                                            result_lines.push(alignLines[k][g]);
                                        }
                                    }

                                    for(var g=0;g<align[m].length;g++)
                                    {
                                       // if(!result_lines.includes(align[m][g]))
                                        {
                                            result_lines.push(align[m][g]);
                                        }
                                    }
                                }
                                //console.log("当前索引为："+k+"::"+m);

                                //计算x和y值
                                if(result[3]==0)
                                {
                                    //减少
                                    if(result[1]==0){
                                        if(result[4]<Math.abs(yMinOffset))
                                            yMinOffset= -result[4];


                                    }else if(result[1]==1)
                                    {

                                        if(result[4]<Math.abs(xMinOffset))
                                            xMinOffset= -result[4];
                                    }else
                                    {
                                        var angel = Math.atan(1/result[2]);
                                        if(result[4]*Math.cos(angel)<Math.abs(yMinOffset))
                                            yMinOffset= -result[4]*Math.cos(angel);
                                        if(result[4]*Math.sin(angel)<Math.abs(xMinOffset))
                                            xMinOffset= -result[4]*Math.sin(angel);

                                    }
                                    lines.push(result_lines);
                                }else
                                {
                                    //添加

                                    if(result[1]==0)
                                    {
                                        if(result[4]<Math.abs(yMinOffset))
                                            yMinOffset= result[4];

                                    }else if(result[1]==1)
                                    {
                                        if(result[4]<Math.abs(xMinOffset))
                                            xMinOffset= result[4];
                                    }else
                                    {
                                        var angel = Math.atan(1/result[2]);
                                        if(result[4]*Math.cos(angel)<Math.abs(yMinOffset))
                                            yMinOffset= result[4]*Math.cos(angel);
                                        if(result[4]*Math.sin(angel)<Math.abs(xMinOffset))
                                            xMinOffset= result[4]*Math.sin(angel);
                                    }
                                    lines.push(result_lines);
                                }

                            }
                        }
                    }
                }
            }
        }

        if(xMinOffset==sorptionDistance)
            xMinOffset=0;
        if(yMinOffset==sorptionDistance)
            yMinOffset=0;
        elementBaseValue.mPosX+=xMinOffset;
        elementBaseValue.mPosY+=yMinOffset;

        return lines;
    }

//判断线段是否平行[[line1_x1,line1_y1],[line1_x2,line1_y2]].返回这out[0]为0，代表不平行，1代表平行，out[1]的值为0代表x轴平行，1代表y平行,2代表不是x轴和y轴平行; out[2]的值代表曲率x/y
   areParallel(line1, line2) {
        // 计算两条线段的斜率x/y进行比较

       var xOffset1=line1[1][0]-line1[0][0];
       var yoffset1=line1[1][1]-line1[0][1];
       var xOffset2=line2[1][0]-line2[0][0];
       var yoffset2=line2[1][1]-line2[0][1];

       if(yoffset2==0&&yoffset1==0)
               return [1,0,0];

        const slope1 =xOffset1 / yoffset1;
        const slope2 = xOffset2 / yoffset2;

        //console.log("当前曲率：："+slope1+"::"+slope2+"::"+line1[1][0]+"::"+line1[0][0]+"::"+line1[1][1]+"::"+line1[0][1]+":"+(slope1 / slope2 === Number.POSITIVE_INFINITY)+"::"+ isFinite(slope2)+"::");



        if(slope1===0&&slope2===0)
            return [1,1,0];

        if( slope1 === slope2)
            return [1,2,slope1];

        // 判断斜率是否相同
        return [0,3,0];
    }

//得到两端平行线之间的距离输入[[line1_x1,line1_y1],[line1_x2,line1_y2],[line2_x1,line2_y1],[line2_x2,line2_y2]]
//输出是否结果为第一个代表是否平行，第二个表示平行的轴，第三个代表line1相对于line2是减少还是增加，第四个表示平行线之间的最短距离
    getDistanceBetweenParallelLines(line1, line2) {

        var result=this.areParallel(line1,line2);
        var distance=-1;
        var direction=0;//0代表减少，1代表增加，是指line1相对于line2而言
        if(result[0]==1)
        {
            if(result[1]==0) {
                var offset=line2[0][1] - line1[0][1];
                if(offset>0)
                {
                    direction=1;
                }else
                    direction=0;

                distance = Math.abs(offset);

            }
            else if(result[1]==1) {
                var offset=line2[0][0] - line1[0][0]
                if(offset>0)
                {
                    direction=1;
                }else
                    direction=0;

                distance = Math.abs(offset);
            }
            else if(result[1]==2)
            {
                var slope=result[2];
                //先计算得到线段1第一个点在线段2上的投影
                var y=line2[0][1]+(1/slope)*(line1[0][0]-line2[0][0]);
                var offset=y-line1[0][1]
                if(offset>0)
                {
                    direction=1;
                }else
                    direction=0;

                distance = Math.abs(offset);
            }
        }

        return [result[0],result[1],result[2],direction,distance];
    }



}



