export class RulerLineBaseValue{
    /** 总时间长度 - 毫秒*/

    //一个像素对应0.3mm,则1mm=33dp
    constructor() {
        this.MIN_CLIP_POSITION = 33;//最小片段是一个像素点
        this.totalLength = 10000;//这里对应的是mm单位
        this.position = 0;
        this.seekposition = -1;
        this.scale = 1.0;
        this.standPxInMark= 33;
        this.pxInMark = 33;
        this.frameWidth = 60;
        this.maxScale = 2.0;
        this.minScale = 0.5;
        //最小一帧对应的时间，用于直接分割帧，避免缩放的时候产生无数的图片
        this.minFramePosition = 33; // frameposition / maxScale
        //最小时间切片的时间
        this.minClipPosition = this.MIN_CLIP_POSITION;
        // val minStandPxInSecond = 1000 * App.instance.dp2px(8f) / minClipposition //最小切片占-帧边框的长度，标准是两倍，此时缩放到最小刚好满足
        this.minStandPxInMark = 1000 * 12 / this.minClipPosition; //最小切片占-帧边框的长度，标准是两倍，此时缩放到最小刚好满足
    }

    static generateRulerValue(totalLength)
    {
        var rulerLineValue = new RulerLineBaseValue();
        rulerLineValue.totalLength = totalLength;
        if (rulerLineValue.position > rulerLineValue.totalLength) rulerLineValue.position = rulerLineValue.totalLength
        rulerLineValue.resetStandPxInMark(false);
        return rulerLineValue;
    }

    static updateRulerBaseValue(totalLength,rulerLineValue)
    {
        rulerLineValue.totalLength = totalLength;
        if (rulerLineValue.position > rulerLineValue.totalLength) rulerLineValue.position = rulerLineValue.totalLength
        rulerLineValue.fitScaleForScreen();
    }

    getPosition()
    {
        return this.position;
    }

    setRulerLineBaseValue(rulerLineBaseValue)
    {
        this.MIN_CLIP_POSITION = rulerLineBaseValue.MIN_CLIP_POSITION;
        this.totalLength = rulerLineBaseValue.totalLength;
        this.position = rulerLineBaseValue.position;
        this.seekposition = rulerLineBaseValue.seekposition;
        this.scale = rulerLineBaseValue.scale;
        this.standPxInMark= rulerLineBaseValue.standPxInMark;
        this.pxInMark = rulerLineBaseValue.pxInMark;
        this.frameWidth = RulerLineBaseValue.frameWidth;
        this.maxScale = rulerLineBaseValue.maxScale;
        this.minScale = rulerLineBaseValue.minScale;
        //最小一帧对应的时间，用于直接分割帧，避免缩放的时候产生无数的图片
        this.minFramePosition = rulerLineBaseValue.minFramePosition; // frameposition / maxScale
        //最小时间切片的时间
        this.minClipPosition = rulerLineBaseValue.minClipPosition;
        // val minStandPxInSecond = 1000 * App.instance.dp2px(8f) / minClipposition //最小切片占-帧边框的长度，标准是两倍，此时缩放到最小刚好满足
        this.minStandPxInMark = rulerLineBaseValue.minStandPxInMark; //最小切片占-帧边框的长度，标准是两倍，此时缩放到最小刚好满足
    }


    set_position(value) {
    //    if (value < 0)
 //           this.position = 0;
  //      else if(value>this.totalLength)
  //          this.position = this.totalLength;
 //       else
            this.position=value;
    }
    set_scale(value) {
        if (value < this.minScale)
            this.scale = this.minScale;
        else if (value > this.maxScale)
            this.scale = this.maxScale;
        else
            this.scale = value;
        this.pxInMark = this.standPxInMark* this.scale;
    }

    set_standPxInMark(value) {
        this.field = value;
        this.pxInMark = this.field * this.scale;
    }

    /** 时间转成像素 */
    position2px(position) {
        return position * this.pxInMark / 1000.00;
    }

    /** 像素转成时间*/
    px2position(px) {
        return (px * 1000.000 / this.pxInMark);
    }

    //
    /** 时间 -> X坐标
     * @param position 目标时间
     * @param cursorX 竖线（光标）的位置，一般在屏幕中央
     * @param currentposition 光标指向的时间
     * */
    position2X(position, cursorX, currentposition = this.position) {
        var offsetposition = position - currentposition;
        return cursorX + this.position2px(offsetposition);
    }

    /**
     * 重置标准选区
     * @param holdPxInSecond 尽量保持pxInSecond不变
     */
    resetStandPxInMark(holdPxInSecond = false) {

        //默认 65dp = 1刻度
        //    standPxInSecond = App.instance.dp2px(65f)
        // this.standPxInMark= 100;
        // //单段视频是一帧宽48dp 多段视频
        // var framePosition = this.frameWidth * 1000 / this.standPxInMark;
        //
        // this.maxScale = framePosition / this.minFramePosition;//最大精度就是 0.25s 1帧
        // this.minScale = this.frameWidth * 100 / this.totalLength / this.standPxInMark; //最小精度为总长度到一帧
        //
        // if (holdPxInSecond) {
        //     this.set_scale(this.pxInMark / this.standPxInMark);
        //     this.scale = this.pxInMark / this.standPxInMark;
        // } else {
        //     this.set_scale(1.0);
        // }
    }

//    private val frameWidth = App.instance.dp2px(48f)

//    private val screenWidth = App.instance.getScreenWidth()

    /**
     * 将scale整理为适合屏幕大小的值，解决片断增删
     * 调整区域后整个时间轴显示区域过小的问题
     *
     * - 尽量保持原有的 pxInSecond 比例不变
     * -
     */
    fitScaleForScreen() {
//        val currentDurationPx = duration * standPxInSecond / 1000
//        if (currentDurationPx < App.instance.dp2px(48f) || currentDurationPx > screenWidth) {
        this.resetStandPxInMark(true);
//        }
    }

    //最小是默认的一半，占半屏


    dp2px(dp) {
        var value = window.devicePixelRatio;
        // return Math.round(dp * value);
        return dp;
    }

    getScreenWidth() {

        return window.innerWidth;
    }
    // javaScript中，定义接口有三种方式
    // 1.注解描述(特点，1.程序员可以有一个参考，2缺点是还是属于文档范畴,3如果程序员忘记了定义方法，不会出错。)
    // 缺点，方式太过松散，并没有检查接口方式是否被完全实现。
    /**
     * interface Composite {
     *
     *         var rulerLineValue: RulerLineBaseValue?
     *         fun scaleChange()
     *         updateposition()
     * }
     */
    // CompositImpl implements Composite

};
