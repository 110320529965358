import {UISelectType} from "./UISelectType";

export class ElementBaseValue {
    /** 总时间长度 - 毫秒*/

    constructor() {
        this.uuid = -1;
        this.type = -1;
        this.start = 0;
        this.end = 1000;
        this.trackIndex = -1;
        this.trackTopMargin = 60;
        this.trackHeight = 60;
        this.mPosX = 0.0;
        this.mPosY = 0.0;
        this.mShowWidth = 100;
        //最小一帧对应的时间，用于直接分割帧，避免缩放的时候产生无数的图片
        this.mShowHeight = 100; // frameTime / maxScale
        //最小时间切片的时间
        this.rotation = 0;
        this.mRatio = 1;
        // val minStandPxInSecond = 1000 * App.instance.dp2px(8f) / minClipTime //最小切片占-帧边框的长度，标准是两倍，此时缩放到最小刚好满足
        this.bEnableTimeMove = true; //最小切片占-帧边框的长度，标准是两倍，此时缩放到最小刚好满足
        this.bEnableTimeRangeChange = true;
        this.bEnableTrackChange = true;
        this.bEnableShowSizeChange = true;
        this.bEnableShowRotationChange = true;
        this.bEnableShowMove = true;

        this.ELEMENTSPACING = 10; //其余行之间的间隙
        this.ELEMENTHEIGHT = 100; //其余行的高度
        this.FIRSTELEMENTHEIGHT = 140; //第一行的高度
        this.FIRSTSPACING = 70; //第一行与顶边的间隙
    }

    static getTrackIndex(elementBaseValue,elementBaseValueList)
    {
        var listBackGroungTracks=[];
        var otherBackGroundTracks=[];

        for(var i=0;i<elementBaseValueList.length;i++)
        {
            if(elementBaseValueList[i].uuid==elementBaseValue.uuid)
                continue;
            if(elementBaseValueList[i].type===UISelectType.BackgroundImage)
            {
                listBackGroungTracks.push(elementBaseValueList[i]);
            }else
            {
                otherBackGroundTracks.push(elementBaseValueList[i]);
            }
        }

        ////console.log("当前元素：："+elementBaseValue.type);
       if(elementBaseValue.type===UISelectType.BackgroundImage)
       {
           var tracketIndex=0;
           if(listBackGroungTracks.length>0) {

               ElementBaseValue.sortByTrackIndex(listBackGroungTracks);
               tracketIndex  = listBackGroungTracks[listBackGroungTracks.length - 1].trackIndex + 1;
           }
           ////console.log("当前元素1：："+tracketIndex);
           if(tracketIndex>100)
               tracketIndex=-2;

           return tracketIndex;


       }else
       {
           var tmp_tracketIndex=101;
           if(otherBackGroundTracks.length>0) {

               ElementBaseValue.sortByTrackIndex(otherBackGroundTracks);
               tmp_tracketIndex  = otherBackGroundTracks[otherBackGroundTracks.length - 1].trackIndex + 1;
           }

           ////console.log("当前元素：："+tracketIndex);

           if(tmp_tracketIndex>10000)
               tmp_tracketIndex=-1;
           return tmp_tracketIndex;
       }


    }



   static CanPush(start, end, elementBaseValueList) {
        for(var i=0;i<elementBaseValueList.length;i++){

            var elementBaseValue=elementBaseValueList[i].elementBaseValue;
            if(elementBaseValue===null)
                continue;

            if (elementBaseValue.start > start && elementBaseValue.start < end)
                return false;
            if (elementBaseValue.end > start && elementBaseValue.end < end)
                return false;
            if (elementBaseValue.start < start && elementBaseValue.end > end)
                return false;
            if (elementBaseValue.start > start && elementBaseValue.end < end)
                return false;
        };
        return true;
    }

   static getSelected(time, y, elementBaseValueList) {
        for(var i=0;i<elementBaseValueList.length;i++) {

            var elementBaseValue=elementBaseValueList[i].elementBaseValue;

            if(elementBaseValue===null)
                continue;
            ////console.log(elementBaseValue.start+"::"+time+"::"+elementBaseValue.end+"::"+y+"::"+elementBaseValue.trackTopMargin+"::"+elementBaseValue.trackTopMargin + elementBaseValue.trackHeight);


            if (time > elementBaseValue.start
                && time < elementBaseValue.end &&
                elementBaseValue.trackTopMargin <= y
                && y <= elementBaseValue.trackTopMargin + elementBaseValue.trackHeight) {
           //     ////console.log("选中...."+elementBaseValue.start);
                return elementBaseValueList[i];
            }
        };

        return null;
    }

    static isLayoutconFlictInSameTrack(elementBaseValue, elementBaseValueList) {

      //  ////console.log("判断布局是否冲突："+elementBaseValue.start+"::"+elementBaseValue.trackIndex);
        if (elementBaseValue === null)
            return true;

        if (elementBaseValue.start < 0)
            return true;

        if (elementBaseValue.end - elementBaseValue.start < 100)
            return true;

        if (elementBaseValue.trackIndex < 0)
            return true;

        if (elementBaseValue.trackIndex === 0) {

            for(var mk=0;mk<elementBaseValueList.length; mk++){

                if(elementBaseValue[mk]===null)
                    continue;

                if (elementBaseValue[mk].trackIndex === 0)
                    return true;
            };
        }

        for(var i=0;i<elementBaseValueList.length; i++){

            var elementBaseValue1=elementBaseValueList[i].elementBaseValue;

            if(elementBaseValue1===null)
                continue;

            if (elementBaseValue.trackIndex === elementBaseValue1.trackIndex && elementBaseValue.uuid !== elementBaseValue1.uuid) {
                if (elementBaseValue.start > elementBaseValue1.start && elementBaseValue.start < elementBaseValue1.end)
                    return true;
                if (elementBaseValue.end > elementBaseValue1.start && elementBaseValue.end < elementBaseValue1.end)
                    return true;
                if (elementBaseValue.start < elementBaseValue1.start && elementBaseValue.end > elementBaseValue1.end)
                    return true;
                if (elementBaseValue.start > elementBaseValue1.start && elementBaseValue.end < elementBaseValue1.end)
                    return true;
                if (elementBaseValue.start === elementBaseValue1.start && elementBaseValue.end === elementBaseValue1.end)
                    return true;
                if (elementBaseValue.start === elementBaseValue1.start && elementBaseValue.end > elementBaseValue1.end)
                    return true;
                if (elementBaseValue.start < elementBaseValue1.start && elementBaseValue.end === elementBaseValue1.end)
                    return true;
            }
        };

        return false;
    }


    static getNextTrackTopMargin(currentTrack, moveTrackDirection, elementBaseValueList) {
        var nextTrack = currentTrack;
        if (moveTrackDirection === 1)
            nextTrack = currentTrack + 1;
        else
            nextTrack = currentTrack - 1;
        if (nextTrack < 0)
            nextTrack = currentTrack;

        var maxTrack = 0;
        var topMarginHeight = 0;
        var trackHeight = 0;

        for(var i=0;i<elementBaseValueList.length;i++) {

            var elementBaseValue1=elementBaseValueList[i].elementBaseValue;

            if(elementBaseValue1===null)
                continue;

            if (elementBaseValue1.trackIndex > maxTrack) {
                maxTrack = elementBaseValue1.trackIndex;
                topMarginHeight = elementBaseValue1.trackTopMargin;
                trackHeight = elementBaseValue1.trackHeight;
            }

            if (nextTrack === elementBaseValue1.trackIndex)
                return elementBaseValue1.trackTopMargin;
        };

        //第一行有问题

        var elementBaseValue2=(new ElementBaseValue());
        if (nextTrack === 0)
            return elementBaseValue2.FIRSTSPACING;

        if (nextTrack === 1)
            return elementBaseValue2.FIRSTELEMENTHEIGHT + elementBaseValue2.FIRSTSPACING + elementBaseValue2.ELEMENTSPACING + elementBaseValue2.ELEMENTSPACING;


        if (nextTrack > maxTrack) {
            return topMarginHeight + trackHeight + elementBaseValue2.ELEMENTSPACING;
        } else
            return topMarginHeight - (maxTrack - nextTrack) * (trackHeight + elementBaseValue2.ELEMENTSPACING);

    }

    static getPushedInfo(elementBaseValue, elementBaseValueList) {
        //先确定可放置的轨道
        while (ElementBaseValue.isLayoutconFlictInSameTrack(elementBaseValue, elementBaseValueList)) {
            elementBaseValue.trackIndex = elementBaseValue.trackIndex + 1;
        }
        if (elementBaseValue.trackIndex === 0) {
            elementBaseValue.trackHeight = elementBaseValue.FIRSTELEMENTHEIGHT;
            elementBaseValue.trackTopMargin = elementBaseValue.ELEMENTSPACING + elementBaseValue.FIRSTSPACING;
        } else {
            elementBaseValue.trackHeight = elementBaseValue.ELEMENTHEIGHT;
            elementBaseValue.trackTopMargin = elementBaseValue.ELEMENTSPACING + elementBaseValue.FIRSTSPACING + elementBaseValue.FIRSTELEMENTHEIGHT + elementBaseValue.ELEMENTHEIGHT * (elementBaseValue.trackIndex - 1) + elementBaseValue.ELEMENTSPACING * elementBaseValue.trackIndex;
        }
        //      System.out.println("  elementBaseValue.trackHeight::"+ elementBaseValue.trackIndex+"::"+elementBaseValue.trackHeight+"::"+elementBaseValue.trackTopMargin);
    }

    static sortByTrackIndex(elementBaseValueList) {
        function compare(o1, o2) {
            return (o1.trackIndex - o2.trackIndex);
        }

        elementBaseValueList.sort(compare);
    }






}
