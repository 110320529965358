export  class UIEraseGraphics {

    constructor() {
        if (!UIEraseGraphics.instance) {
            this.drawType=0;//"0代表绘制，1代表擦除";
            this.canvas=null;
            this.ctx=null;
            this.enableDraw=false;
            this.lineWidth=10;
            this.lineColor="#3653dd";
            this.uiCoordinateView=null;
            this.eraseImage=null;
            this.parent=null;

            UIEraseGraphics.instance = this;
        } else {
            return UIEraseGraphics.instance;
        }
    }


    init(parentContainer,eraseImage,callBack)
    {
        if(this.canvas!=null)
        {
            if(callBack!=null)
            {
                callBack(-1,"已经创建了画板，请勿重复创建画板");
                return;
            }
        }

        this.eraseImage=eraseImage;
        this.clearParentChildrens(parentContainer,"canvas_erase_edit");
        this.parent=parentContainer;
        this.canvas = document.createElement('canvas');
        this.canvas.style.display="block";
        this.canvas.style.position="absolute";
        this.canvas.style.background="transparent";
        this.canvas.style.zIndex="1100";
        this.canvas.id="canvas_erase_edit";
        this.parent.appendChild(this.canvas);

        this.canvas.width=this.eraseImage.oriWidth;
        this.canvas.height=this.eraseImage.oriHeight;

        this.ctx=this.canvas.getContext('2d');
        this.ctx.strokeWidth=this.lineWidth;
        this.ctx.strokeColor='blue';
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round';

        this.parent.style.cursor='crosshair';

        this.updateUI(eraseImage);

        var tmp=this;

        if(eraseImage.eraseImageBase64Data!=null&&eraseImage.eraseImageBase64Data.length>0) {
            var image = new Image();

            image.src = eraseImage.eraseImageBase64Data;
            image.onload = function () {
                // 绘制图片到canvas, 大小为图片实际大小
                tmp.ctx.drawImage(image, 0, 0, eraseImage.dstWidth, eraseImage.dstHeight);
            };
        }

        this.canvas.onmousedown=function (e) {
           //console.log("点击点击点击")
            var sx = e.offsetX;
            var sy=e.offsetY;
            if(tmp.drawType==0) {
                tmp.ctx.beginPath();
                tmp.ctx.moveTo(sx, sy);
            }
            tmp.enableDraw=true;
        }

        this.canvas.onmousemove=function (e) {
            if(!tmp.enableDraw)
                return;

            var sx = e.offsetX;
            var sy=e.offsetY;
            //绘制
            if(tmp.drawType==0) {
                tmp.ctx.strokeWidth=tmp.lineWidth;
                tmp.ctx.lineWidth=tmp.lineWidth;
                tmp.ctx.lineTo(sx, sy);
                tmp.ctx.stroke();
                tmp.ctx.moveTo(sx, sy);
            }else
            {
                tmp.ctx.clearRect(sx,sy,tmp.lineWidth,tmp.lineWidth);

            }
        }

        this.canvas.onmouseup=function (e) {

            tmp.enableDraw=false;
            tmp.ctx.closePath();

        }

        this.canvas.onmouseout=function (e) {
            tmp.enableDraw=false;
            tmp.ctx.closePath();
        }

        if(callBack!=null)
        {
            callBack(0,"创建画板成功");
            return;
        }
    }



    setLineWidth(width)
    {
        this.lineWidth=width;

       //console.log("1111111111111111111111111111111:::"+this.ctx.lineWidth);
    }

    switchDrawType(type)
    {

        this.drawType=type;
    }


    clearParentChildrens(element,id)
    {
        var childElements=[];
        for(var i=0;i<element.childElementCount;i++)
        {
            if(element.children[i].id==id)
            {
                childElements.push(element.children[i]);
            }
        }

        for(var i=0;i<childElements.length;i++)
        {
            element.removeChild(childElements[i]);
        }
    }

    //保存图像
    saveCanvasToImage(callBack)
    {
        //base64编码
        var dataURL = this.canvas.toDataURL('image/png');
        if(callBack!=null)
        {
            callBack(this.eraseImage.uuid,dataURL);
        }


        this.release();
    }

    //释放所有的资源
    release()
    {
        this.parent.style.cursor='default';

        this.canvas=null;
        this.ctx=null;
        this.enableDraw=false;
        this.lineWidth=10;
        this.lineColor="#3653dd";
        this.uiCoordinateView=null;
        this.eraseImage=null;
        this.clearParentChildrens(this.parent,"canvas_erase_edit");
    }

    updateUI(imageData)
    {
        this.eraseImage=imageData;

        if(this.eraseImage==null||this.canvas==null)
            return;

        var imgData=null;
        if(this.ctx!=null)
           imgData=this.ctx.getImageData(0,0,this.canvas.width,this.canvas.height);

        this.canvas.style.width=this.eraseImage.dstWidth+"px";
        this.canvas.style.height=this.eraseImage.dstHeight+"px";
        this.canvas.style.left=this.eraseImage.dstx+"px";
        this.canvas.style.top=this.eraseImage.dsty+"px";
        this.canvas.style.transform="rotate(" + this.eraseImage.dstAngel + "deg)";
        var sx=this.eraseImage.oriWidth/this.eraseImage.dstWidth;
        var sy=this.eraseImage.oriHeight/this.eraseImage.dstHeight;
        this.canvas.width=this.eraseImage.oriWidth;
        this.canvas.height=this.eraseImage.oriHeight;

        this.ctx.scale(sx,sy);

        this.ctx.translate(0,0);
        // this.canvas.width=this.eraseImage.dstWidth;
        // this.canvas.height=this.eraseImage.dstHeight;
        if(imgData!=null)
           this.ctx.putImageData(imgData,0,0);
    }




}
