import { createContext, useContext, useState } from "react";


interface UpdatorContextObj {
    statsNumber: number,
    updator: Function
  }
  export const UpdatorContext = createContext<UpdatorContextObj|null>(null);
  
  export function useUpdatorContext() : UpdatorContextObj | null{
    return useContext(UpdatorContext);
  }
  
  /**
   * 全局搜索
   */
  export default function Updator(props: {children: any}) {
      const { children} = props ;
  
      const [statsNumber, setStatsNumber] = useState<number>(0);

      const updator = () => {
        setStatsNumber(statsNumber + 1);
      }
  
      return (
          <>
            <UpdatorContext.Provider value={{
              statsNumber, updator
            }}>
              {children}
            </UpdatorContext.Provider>
          </>
      );
  
  }