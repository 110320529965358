import {RulerLineXView} from "./RulerLineXView";
import {RulerLineYView} from "./RulerLineYView";
import {RulerLineBaseValue} from "./RulerLineBaseValue";

export class UICoordinateView {
    constructor() {
        if (!UICoordinateView.instance) {
            this.rulerXBaseValue=null;
            this.rulerYBaseValue=null;
            this.divContainer=null;
            this.dispatchUpdateRuler=null;
            this.dispatchUpdateScaleRuler=null;
            this.scaleSpan=0.1;
            this.rulerY=null;
            this.rulerX=null;
            this.timer=null;
            UICoordinateView.instance = this;
        } else {
            return UICoordinateView.instance;
        }
    }


    init(parent,xMaxCoord,yMaxCoord,dispatchUpdateRuler,dispatchUpdateScaleRuler,callBack)
    {
        this.divContainer=parent;
        var tmp=this;
        this.dispatchUpdateRuler=dispatchUpdateRuler;
        this.dispatchUpdateScaleRuler=dispatchUpdateScaleRuler;
        var result=[];
        var timer=setTimeout(()=>{
            //获取parent宽高
            tmp.rulerX=new RulerLineXView();
            var ruleXView = tmp.rulerX.initRulerView(tmp.divContainer,xMaxCoord,function () {
                if( tmp.dispatchUpdateRuler!=null) {
                    tmp.dispatchUpdateRuler(tmp.rulerXBaseValue, tmp.rulerYBaseValue);
                }
            },function () {
                tmp.dispatchUpdateScaleRuler(tmp.rulerXBaseValue,tmp.rulerYBaseValue);
            });

            tmp.rulerXBaseValue=tmp.rulerX.rulerLineBaseValue;
            result.push(ruleXView);
            tmp.rulerY=new RulerLineYView();
            var ruleYView = tmp.rulerY.initRulerView(tmp.divContainer,yMaxCoord,function () {
                if( tmp.dispatchUpdateRuler!=null)
                    tmp.dispatchUpdateRuler(tmp.rulerXBaseValue,tmp.rulerYBaseValue);
            },function () {
                tmp.dispatchUpdateRuler(tmp.rulerXBaseValue,tmp.rulerYBaseValue);
            });
            tmp.rulerYBaseValue= tmp.rulerY.rulerLineBaseValue;
            result.push(ruleYView);
            clearTimeout(timer);

            tmp.initEvents();
            callBack(0,result);
        },500);
    }


    initEvents()
    {
       var tmp=this;
       var bCtrl=false;
        this.divContainer.onmousewheel = function(e){
            e.preventDefault();
            const parent_rect =  tmp.divContainer.getBoundingClientRect();
            if(bCtrl) {
                if (tmp.bEnableScroll)
                    return;
                let offset = e.wheelDelta;
                if (offset == 0)
                    return;
                var scale = offset / Math.abs(offset) * tmp.scaleSpan;
                var target = tmp.rulerXBaseValue.scale + scale;
                tmp.start_scaleAnimation(target, e.clientX-parent_rect.left, e.clientY-parent_rect.top);
            }else
            {
                //上下移动
                let offset = -e.wheelDelta;
                if (offset == 0)
                    return;
                 tmp.rulerY.scrollByPx(0,offset);
            }
        };

        document.addEventListener("keydown",function (e) {
       //     e.preventDefault();

            if(e.key=="Control")
            {
                bCtrl=true;
            }

           //console.log(e.key);

        });

        document.addEventListener("keyup",function (e) {
         //   e.preventDefault();
           //console.log("1::"+e.key);
            if(e.key=="Control")
            {
                bCtrl=false;
            }
        });


        var mOriginRawX=0;
        var mOriginRawY=0;
        var bEnableScroll=false;
        this.divContainer.addEventListener('mousedown',function(e){
            e.preventDefault();
            if(e.button==1) {
                bEnableScroll = true;
                mOriginRawX = e.clientX;
                mOriginRawY = e.clientY;
                tmp.divContainer.style.cursor='pointer';
            }
        });

        document.addEventListener('mousemove',function (e){
            e.preventDefault();
            if(!bEnableScroll)
                return;
            if(e.buttons<=0) {
                bEnableScroll=false;
                return;
            }

            //计算差值
            var offsetPositionX=(tmp.rulerXBaseValue.px2position(e.clientX)-tmp.rulerXBaseValue.px2position(mOriginRawX));
            mOriginRawX=e.clientX;

            var offsetPositionY=(tmp.rulerYBaseValue.px2position(e.clientY)-tmp.rulerYBaseValue.px2position(mOriginRawY));
            mOriginRawY=e.clientY;

            tmp.rulerX.scroll(-offsetPositionX,0);
            tmp.rulerY.scroll(0,-offsetPositionY);

        });
        document.addEventListener('mouseup',function(e){
            bEnableScroll=false;
            tmp.divContainer.style.cursor='default';
        });

        // this.divContainer.ondblclick=function(e){
        //     var  target=tmp.rulerXBaseValue.maxScale;
        //     if(Math.abs(tmp.rulerXBaseValue.scale- tmp.rulerXBaseValue.maxScale)<0.2)
        //     {
        //         target=tmp.rulerXBaseValue.minScale;
        //     }
        //
        //    // //console.log("当前动画缩放值为：："+target+"::"+tmp.rulerXBaseValue.scale);
        //
        //     tmp.start_scaleAnimation(target,e.clientX,e.clientY);
        // };
    }
    start_scaleAnimation(target,x,y)
    {
        var obj = this.divContainer;
        //①先清除之前的定时器
        clearInterval(this.timer);
        //②利用对象属性定义定时器
        if(target>this.rulerXBaseValue.maxScale)
            target=this.rulerXBaseValue.maxScale;
        if(target<this.rulerXBaseValue.minScale)
            target=this.rulerXBaseValue.minScale;
        var step = (target - this.rulerXBaseValue.scale)/5.00;
        var mlastx=this.rulerXBaseValue.px2position(x);
        var mlasty=this.rulerYBaseValue.px2position(y);
        this.rulerXBaseValue.set_scale(this.rulerXBaseValue.scale + step);
        this.rulerYBaseValue.set_scale( this.rulerXBaseValue.scale);
        var mNewx=this.rulerXBaseValue.px2position(x);
        var mNewy=this.rulerXBaseValue.px2position(y);
        this.rulerXBaseValue.set_position(this.rulerXBaseValue.position-mNewx+mlastx);
        this.rulerYBaseValue.set_position(this.rulerYBaseValue.position-mNewy+mlasty);



        this.onAnimationUpdateScale(this.rulerXBaseValue.scale);
        var tmp=this;
        this.timer = window.setInterval(()=>{
            if(Math.abs(tmp.rulerXBaseValue.scale- target)<0.01){
                clearInterval(tmp.timer);//到达，清除定时器
            }else{
                var mlastx1=tmp.rulerXBaseValue.px2position(x);
                var mlasty1=tmp.rulerYBaseValue.px2position(y);
                this.rulerXBaseValue.set_scale(tmp.rulerXBaseValue.scale + step);
                this.rulerYBaseValue.set_scale( tmp.rulerXBaseValue.scale);
                var mNewx1=tmp.rulerXBaseValue.px2position(x);
                var mNewy1=tmp.rulerXBaseValue.px2position(y);
                tmp.rulerXBaseValue.set_position(tmp.rulerXBaseValue.position-mNewx1+mlastx1);
                tmp.rulerYBaseValue.set_position(tmp.rulerYBaseValue.position-mNewy1+mlasty1);
            }
            //        //console.log("滚动！！");
            tmp.onAnimationUpdateScale(tmp.rulerXBaseValue.scale);
        },40);
    }

    onAnimationUpdateScale(scale) {
        // this.rulerXBaseValue.set_scale(scale);
        // this.rulerYBaseValue.set_scale( this.rulerXBaseValue.scale);
        //这里分发缩放事件事件
        if(this.dispatchUpdateScaleRuler!=null)
            this.dispatchUpdateScaleRuler(this.rulerXBaseValue,this.rulerYBaseValue);
    }

    updateCoord(xMaxCoord,yMaxCoord)
    {
        if(this.rulerXBaseValue!=null)
           RulerLineBaseValue.updateRulerBaseValue(xMaxCoord, this.rulerXBaseValue);
        if(this.rulerYBaseValue!=null)
           RulerLineBaseValue.updateRulerBaseValue(yMaxCoord, this.rulerYBaseValue);

        if(this.dispatchUpdateScaleRuler!=null)
            this.dispatchUpdateScaleRuler(this.rulerXBaseValue,this.rulerYBaseValue);

        if( this.dispatchUpdateRuler!=null)
            this.dispatchUpdateRuler(this.rulerXBaseValue,this.rulerYBaseValue);
    }

    setEnableScroll(bscroll)
    {
        if(this.rulerX!=null)
        {
            this.rulerX.setEnableScroll(bscroll);
        }

        if(this.rulerY!=null)
        {
            this.rulerY.setEnableScroll(bscroll);
        }


    }

    position2px(x1,y1)
    {
        var xOffset = this.rulerXBaseValue.position2px( this.rulerXBaseValue.position);
        var yOffset = this.rulerYBaseValue.position2px(this.rulerYBaseValue.position);
        var x = (this.rulerXBaseValue.position2px(x1) - xOffset);
        var y = (this.rulerYBaseValue.position2px(y1) - yOffset);
        return [x,y];
    }

    size2px(w,h)
    {

        var iwidth = UICoordinateView.instance.rulerXBaseValue.position2px(w);
        var iheight = UICoordinateView.instance.rulerXBaseValue.position2px(h);
        return [iwidth,iheight];
    }


    px2position(x1,y1)
    {
        var x = this.rulerXBaseValue.px2position( x1)+this.rulerXBaseValue.position;
        var y = this.rulerYBaseValue.px2position(y1)+this.rulerYBaseValue.position;
        return [x,y];
    }

    px2Size(w,h)
    {
        var xOffset = this.rulerXBaseValue.px2position(w);
        var yOffset = this.rulerYBaseValue.px2position(h);
        return [xOffset,yOffset];
    }






}
