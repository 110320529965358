import { UISelectType } from "./UISelectType.js";

export class UISelectKeyPair {

    constructor()
    {
        this.type=UISelectType.None;
        this.uuid=-1;
    }

    static transformUISelectTypeToElementType(uiSelectType)
    {

        switch (uiSelectType)
        {
            case UISelectType.None:
                return 0;
            case UISelectType.Text:
                return 1;
            case UISelectType.Subtitle:
                return 2;
            case UISelectType.Image:
                return 3;
            case UISelectType.Video:
                return 4;
            case UISelectType.Audio:
                return 5;
            default:
                return -1;
        }


    };
    static transformElementTypeToUISelectType(index)
    {
        switch (index)
        {
            case 0:
                return UISelectType.None;
            case 1:
                return UISelectType.Text;
            case 2:
                return UISelectType.Subtitle;
            case 3:
                return UISelectType.Image;
            case 4:
                return UISelectType.Video;
            case 5:
                return UISelectType.Audio;
            default:
                return UISelectType.None;
        }
    };

}