import {UISelectEventType} from "../../Data/UISelectEventType";
export class ShowSelectAreaView{

    constructor()
    {
        this.showFrameLayout=null;
        this.selectChildElements=[];
        this.mOriginalX=0;
        this.mOriginalY=0;
        this.mOriginalRawX=0;
        this.mOriginalRawY=0;
        this.eventsCallBack=null;

        this.mOriginalAngel=0;


        this.oldRawX=0;
        this.oldRawY=0;

        this.bLongPress=false;
        this.mScaleX=1;
        this.mScaleY=1;
        this.firstX=0;
        this.firstY=0;
        this.scaleDirect=0;

        this.isGestureScale=false;
        this.isLongPressMove=false;
        this.isPenetrate =false;
        this.isFixedAspect=false;

        this.elementBaseValue=null;
        this.elementBaseValues=[];

        this.divContainer=null;

        this.bgViewDiv=null;
        this.leftDownDiv=null;
        this.leftUpDiv=null;
        this.rightDownDiv=null;
        this.rightUpDiv=null;
        this.rotationDiv=null;

        this.parent=null;
        this.span=20;
        this.rulerXBaseValue=null;
        this.rulerYBaseValue=null;
        this.eventState=UISelectEventType.None;
        //更新缩放
        this.scaleChange=()=>{

            this.updateUI();
        };

        this.updateRuler=()=>{
            this.updateUI();
        }

        //更新UI布局
        this.updateUIEvent=()=>{

            this.updateUI();

        };

        this.updateTrack=(distance)=>{
            this.divContainer.style.zIndex=this.elementBaseValue.trackIndex;
        };
    }

    setVisibility(bvisible)
    {
        if(!bvisible) {
            this.divContainer.style.visibility = "hidden";
            this.bgViewDiv.style.visibility = "hidden";
            this.leftDownDiv.style.visibility = "hidden";
            this.leftUpDiv.style.visibility = "hidden";
            this.rightDownDiv.style.visibility = "hidden";
            this.rightUpDiv.style.visibility = "hidden";
            this.rotationDiv.style.visibility = "hidden";
        }else
        {
            this.divContainer.style.visibility = "visible";
            this.bgViewDiv.style.visibility = "visible";
            this.leftDownDiv.style.visibility = "visible";
            this.leftUpDiv.style.visibility = "visible";
            this.rightDownDiv.style.visibility = "visible";
            this.rightUpDiv.style.visibility = "visible";
            this.rotationDiv.style.visibility = "visible";
        }
    }

    updateUI()
    {
        if(this.elementBaseValue==null|| this.rulerXBaseValue==null||  this.rulerYBaseValue==null)
            return;

       //console.log("llllllllllllllllllllllllllllllllllllllllll::"+this.elementBaseValue.scaleEffect+"::"+this.elementBaseValue.type);
        this.updateLayout(this.elementBaseValue.mPosX,this.elementBaseValue.mPosY,
            this.elementBaseValue.mShowWidth,this.elementBaseValue.mShowHeight,
            this.elementBaseValue.rotation,
            this.elementBaseValue.type,Math.abs(this.rulerXBaseValue.px2position(this.elementBaseValue.scaleEffect)),
            this.elementBaseValue.shapeOffset
            );
    }

    init(parentContainer,showframelayoutContainer)
    {

        this.setParent(parentContainer,showframelayoutContainer);
        this.initUI();
    }

    initUI()
    {
        this.divContainer = document.createElement('div');
        this.divContainer.style.zIndex="1000";
        this.divContainer.style.position="absolute";
        this.divContainer.style.width="170px";
        this.divContainer.style.height="200px";
        this.divContainer.style.background="transparent";
        this.parent.append(this.divContainer);

        this.bgViewDiv =document.createElement('div');
        this.bgViewDiv.style.position="absolute";
        this.bgViewDiv.style.width="140px";
        this.bgViewDiv.style.height="140px";
        this.bgViewDiv.style.background = "transparent";
        this.bgViewDiv.style.marginLeft = "15px";
        this.bgViewDiv.style.marginTop = "15px";
        this.bgViewDiv.style.border="2px solid #3DB8DA";
        this.bgViewDiv.style.borderRadius="0px";
        this.bgViewDiv.style.boxShadow='0 4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 上阴影
                                       '0 -4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 下阴影
                                       '4px 0 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 左阴影
                                       '-4px 0 4px rgba(0.24, 0.72, 0.85, 0.1)';
        this.divContainer.append(this.bgViewDiv);

        this.leftDownDiv=document.createElement('div');
        this.leftDownDiv.style.position="absolute";
        this.leftDownDiv.style.width="15px";
        this.leftDownDiv.style.height="15px";
        this.leftDownDiv.style.background = "#0F3DB8DA";
        this.leftDownDiv.style.marginLeft = "0px";
        this.leftDownDiv.style.marginTop = "140px";
        this.leftDownDiv.style.border="2px solid #3DB8DA";
        this.leftDownDiv.style.borderRadius="0px";
        this.leftDownDiv.style.boxShadow='0 4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 上阴影
            '0 -4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 下阴影
            '4px 0 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 左阴影
            '-4px 0 4px rgba(0.24, 0.72, 0.85, 0.1)';


        this.divContainer.append(this.leftDownDiv);
        this.leftUpDiv=document.createElement('div');
        this.leftUpDiv.style.position="absolute";
        this.leftUpDiv.style.width="15px";
        this.leftUpDiv.style.height="15px";
        this.leftUpDiv.style.background = "#0F3DB8DA";
        this.leftUpDiv.style.marginLeft = "0px";
        this.leftUpDiv.style.marginTop = "0px";
        this.leftUpDiv.style.border="2px solid #3DB8DA";
        this.leftUpDiv.style.borderRadius="0px";
        this.divContainer.append(this.leftUpDiv);
        this.leftUpDiv.style.boxShadow='0 4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 上阴影
            '0 -4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 下阴影
            '4px 0 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 左阴影
            '-4px 0 4px rgba(0.24, 0.72, 0.85, 0.1)';

        this.rightDownDiv=document.createElement('div');
        this.rightDownDiv.style.position="absolute";
        this.rightDownDiv.style.width="15px";
        this.rightDownDiv.style.height="15px";
        this.rightDownDiv.style.background = "#0F3DB8DA";
        this.rightDownDiv.style.marginLeft = "140px";
        this.rightDownDiv.style.marginTop = "140px";
        this.rightDownDiv.style.border="2px solid #3DB8DA";
        this.rightDownDiv.style.borderRadius="0px";
        this.divContainer.append(this.rightDownDiv);
        this.rightDownDiv.style.boxShadow='0 4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 上阴影
            '0 -4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 下阴影
            '4px 0 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 左阴影
            '-4px 0 4px rgba(0.24, 0.72, 0.85, 0.1)';

        this.rightUpDiv=document.createElement('div');
        this.rightUpDiv.style.position="absolute";
        this.rightUpDiv.style.width="15px";
        this.rightUpDiv.style.height="15px";
        this.rightUpDiv.style.background = "#0F3DB8DA";
        this.rightUpDiv.style.marginLeft = "140px";
        this.rightUpDiv.style.marginTop = "0px";
        this.rightUpDiv.style.border="2px solid #3DB8DA";
        this.rightUpDiv.style.borderRadius="0px";
        this.divContainer.append(this.rightUpDiv);
        this.rightUpDiv.style.boxShadow='0 4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 上阴影
            '0 -4px 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 下阴影
            '4px 0 4px rgba(0.24, 0.72, 0.85, 0.1),' + // 左阴影
            '-4px 0 4px rgba(0.24, 0.72, 0.85, 0.1)';

        this.rotationDiv=document.createElement('div');
        this.rotationDiv.style.position="absolute";
        this.rotationDiv.style.width="30px";
        this.rotationDiv.style.height="30px";
        this.rotationDiv.style.background = "#FFFFFF";
        this.rotationDiv.style.marginLeft = "60px";
        this.rotationDiv.style.marginTop = "160px";
        this.rotationDiv.style.border="1px solid #3DB8DA";
        this.rotationDiv.style.borderRadius="15px";
        this.divContainer.append(this.rotationDiv);

        this.setScaleView( this.leftUpDiv,0);
        this.setScaleView( this.rightUpDiv,1);
        this.setScaleView( this.leftDownDiv,2);
        this.setScaleView( this.rightDownDiv,3);
        this.setContentView(this.bgViewDiv);
        this.setRotationView(this.rotationDiv);

    }

    setRulerLineBaseValue(rulerX,rulerY)
    {
        this.rulerXBaseValue=rulerX;
        this.rulerYBaseValue=rulerY;
        this.updateUI();
    }

    updateLayout(l,t,w,h,angel,type,scaleEffect,shapeOffset)
    {

        var width=this.rulerXBaseValue.position2px(w);
        var height=this.rulerXBaseValue.position2px(h);
        if(type==2&&shapeOffset==1)
        {
            width=this.rulerXBaseValue.position2px(w+scaleEffect);
            height=this.rulerXBaseValue.position2px(h+scaleEffect);
        }


        var left=this.rulerXBaseValue.position2px(l)-this.rulerXBaseValue.position2px(this.rulerXBaseValue.position);
        var top=this.rulerYBaseValue.position2px(t)-this.rulerYBaseValue.position2px(this.rulerYBaseValue.position);

        if(type==2&&shapeOffset==1)
        {
           left=this.rulerXBaseValue.position2px(l-scaleEffect/2)-this.rulerXBaseValue.position2px(this.rulerXBaseValue.position);
           top=this.rulerYBaseValue.position2px(t-scaleEffect/2)-this.rulerYBaseValue.position2px(this.rulerYBaseValue.position);

        }


        this.leftUpDiv.style.marginLeft=0+"px";
        this.leftUpDiv.style.marginTop=0+"px";

        this.leftDownDiv.style.marginLeft=0+"px";
        this.leftDownDiv.style.marginTop=height+"px";

        this.rightUpDiv.style.marginLeft=width+"px";
        this.rightUpDiv.style.marginTop=0+"px";

        this.rightDownDiv.style.marginLeft=width+"px";
        this.rightDownDiv.style.marginTop=height+"px";

        this.rotationDiv.style.marginLeft=(width/2-this.rotationDiv.offsetWidth/4)+"px";
        this.rotationDiv.style.marginTop=height+this.span+"px";

        this.bgViewDiv.style.width=width+2+"px";
        this.bgViewDiv.style.height=height+2+"px";

        this.bgViewDiv.style.marginLeft=this.leftDownDiv.offsetWidth/2+"px";
        this.bgViewDiv.style.marginTop=this.leftDownDiv.offsetWidth/2+"px";

        this.divContainer.style.width=this.leftDownDiv.offsetWidth+width+"px";

        this.divContainer.style.height= height+this.span+this.rotationDiv.offsetHeight+"px";

        var pleft=left-this.leftDownDiv.offsetWidth/2;
        var ptop=top-this.leftDownDiv.offsetWidth/2;

        this.divContainer.style.marginLeft=pleft+"px";
        this.divContainer.style.marginTop=ptop+"px";

        this.mOriginalRotateX=pleft+width/2+this.leftDownDiv.offsetWidth/2;
        this.mOriginalRotateY=ptop+height+this.span+this.leftDownDiv.offsetHeight/2;

        var centerY=this.leftDownDiv.offsetWidth/2+height/2;
        this.divContainer.style.transformOrigin = "50% "+centerY+"px";
        this.divContainer.style.transform="rotate(" + angel + "deg)";

     //  //console.log("布局数据为：："+pleft+"::"+ptop+"::"+this.elementBaseValue.mShowWidth+"::"+this.elementBaseValue.mShowHeight);
    }
    setElementBaseValue(value,values)
    {
        this.elementBaseValue=value;
        this.elementBaseValues=values;

     //  //console.log("数据是否给到了：："+this.elementBaseValue.uuid+"::");

        this.setVisibility(true);
        this.updateUI();
    }

    setElementBaseChildValue(selectChildElements)
    {
        this.selectChildElements=selectChildElements;
    }

    //按比例缩放
    setScaleView(v,mode){

        var bmove=false;
        var tmp=this;

        v.onmousedown=(e)=>{
            e.preventDefault();
            if(tmp.elementBaseValue==null)
                return;

            if(!tmp.elementBaseValue.bEnableShowSizeChange)
                return;

            tmp.eventState=UISelectEventType.StartScale;
            tmp.mOriginalX = e.clientX;
            tmp.mOriginalY = e.clientY;
            bmove=true;

            if(bmove&&tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.StartScale,tmp.elementBaseValue)
            }
        }

        document.addEventListener("mousemove", (e)=>{
            e.preventDefault();
            if(!bmove)
                return;

            if(e.buttons<=0) {
                bmove=false;
                return;
            }

            if(tmp.elementBaseValue==null)
                return;

            if(!tmp.elementBaseValue.bEnableShowSizeChange)
                return;

            var videoAspect= tmp.elementBaseValue.mRatio;
            if(videoAspect<=0)
                videoAspect=1;
            var offset=0;
            var offsetX=tmp.rulerXBaseValue.px2position(e.clientX)-tmp.rulerXBaseValue.px2position(tmp.mOriginalX);
            var offsetY=tmp.rulerYBaseValue.px2position(e.clientY)-tmp.rulerYBaseValue.px2position(tmp.mOriginalY);

            if(Math.abs(offsetX)>Math.abs(offsetY)) {

                if(mode==0)
                {
                    offset=-offsetX;
                }else if(mode==1)
                {
                    offset=offsetX;
                }else if(mode==2)
                {
                    offset=-offsetX;
                }else if(mode==3)
                {
                    offset=offsetX;
                }
            }
            else {
                if(mode==0)
                {
                    offset=-offsetY;
                }else if(mode==1)
                {
                    offset=-offsetY;
                }else if(mode==2)
                {
                    offset=offsetY;
                }else if(mode==3)
                {
                    offset=offsetY;
                }
            }

            if(offset==0)
                return;

            offset=offset;

            var offsetHeight=offset/videoAspect;
            tmp.elementBaseValue.mShowWidth+=offset;
            tmp.elementBaseValue.mShowHeight+=offsetHeight;

            tmp.elementBaseValue.mPosX-=offset/2;
            tmp.elementBaseValue.mPosY-=offsetHeight/2;

         //   tmp.updateUI();

            tmp.dispatchElementBaseValue();

            tmp.mOriginalX=e.clientX;
            tmp.mOriginalY=e.clientY;

            if(bmove&&tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.Scaling,tmp.elementBaseValue)
            }


        });
        document.addEventListener("mouseup", (e)=>{
            bmove=false;
            tmp.eventState=UISelectEventType.None;
            if(tmp.elementBaseValue==null)
                return;

            if(!tmp.elementBaseValue.bEnableShowSizeChange)
                return;

            if(bmove&&tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.Scaled,tmp.elementBaseValue)
            }


            if(tmp.elementBaseValue==null)
                return;


        });
    }

    //自由缩放
    setStrectView(v){

        var bmove=false;

        v.onmousedown=(e)=>{
            this.mOriginalX = e.clientX;
            this.mOriginalY = e.clientY;
            bmove=true;
        }

        document.addEventListener("mousemove", (e)=>{

            e.preventDefault();
            if(!bmove)
                return;

            if(e.buttons<=0) {
                bmove=false;
                return;
            }

            if(this.elementBaseValue==null)
                return;

            if(!this.elementBaseValue.bEnableShowSizeChange)
                return;

            var videoAspect= this.elementBaseValue.mRatio;
            if(videoAspect<=0)
                videoAspect=1;
            var offset=0;
            var offsetX=e.clientX-this.mOriginalX;
            var offsetY=e.clientY-this.mOriginalY;

            if(Math.abs(offsetX)>Math.abs(offsetY))
                offset=offsetX;
            else
                offset=offsetY;

            if(offset==0)
                return;

            var offsetHeight=offset/videoAspect;
            this.elementBaseValue.mShowWidth+=offset;
            this.elementBaseValue.mShowHeight+=offsetHeight;

         //   this.updateUI();

        });
        document.addEventListener("mouseup", (e)=>{
            bmove=false;

            if(this.elementBaseValue==null)
                return;
        });

    }

    toRadians(degrees) {
        return degrees * Math.PI / 180; // 将角度转换为弧度
    }

    toDegrees(radians) {
        return radians * 180 / Math.PI; // 将弧度转换为角度
    }

    calculateAngle(x,y) {
       if(x==0)
       {
           if(y>0)
           {
               return 90;
           }else if(y<0)
           {
               return 270;
           }else
               return 0;
       }else
       {
           return this.toDegrees(Math.atan2(y,x));
       }
    }

    setRotationView(v){
        var bRotate=false;
        var tmp=this;
        v.onmousedown=(e)=>{

            if(tmp.elementBaseValue==null)
                return;
            if(!tmp.elementBaseValue.bEnableShowRotationChange)
                return;

            var rect = tmp.bgViewDiv.getBoundingClientRect(); // 获取div元素的位置信息
            var x=(rect.left+rect.right)/2;
            var y=(rect.top+rect.bottom)/2;
            tmp.mOriginalAngel=-tmp.calculateAngle(e.clientY-y,e.clientX-x);
            if(tmp.elementBaseValue==null)
                return;
            bRotate=true;
            tmp.eventState=UISelectEventType.StartRotate;

            if(bRotate&&tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.StartRotate,tmp.elementBaseValue)
            }

        }

        document.addEventListener("mousemove", (e)=>{

            e.preventDefault();
            if(!bRotate)
                return;

            if(e.buttons<=0) {
                bRotate=false;
                return;
            }

            if(tmp.elementBaseValue==null)
                return;

            if(!tmp.elementBaseValue.bEnableShowRotationChange)
                return;
            var rect = tmp.bgViewDiv.getBoundingClientRect(); // 获取div元素的位置信息
            var x=(rect.left+rect.right)/2;
            var y=(rect.top+rect.bottom)/2;
            var currentAngel=-tmp.calculateAngle(e.clientY-y,e.clientX-x);

            var offsetAngel=currentAngel-tmp.mOriginalAngel;

            tmp.elementBaseValue.rotation+=offsetAngel;

            tmp.mOriginalAngel=currentAngel;

       //     tmp.updateUI();

            tmp.dispatchElementBaseValue();

            if(bRotate&&tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.Rotating,tmp.elementBaseValue)
            }

        });
        document.addEventListener("mouseup", (e)=>{


            tmp.eventState=UISelectEventType.None;
            if(tmp.elementBaseValue==null)
                return;
            if(!tmp.elementBaseValue.bEnableShowRotationChange)
                return;

            if(bRotate&&tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.Rotated,tmp.elementBaseValue)
            }

            bRotate=false;
            if(tmp.elementBaseValue==null)
                return;
        });
    }

    dispatchElementBaseValue()
    {
        //将事件反馈给父节点，由父节点分发给子控件
        this.showFrameLayout.dispatchUpdateUIEvent();
    }

    setParent(parentContainer,zoomFrameLayoutParent)
    {
        this.parent=parentContainer;
        this.showFrameLayout=zoomFrameLayoutParent;
    }

    setContentView(v){

        var bmove=false;
        var tmp=this;
        var benter=false;
        v.onmousedown=(e)=>{
            e.preventDefault();
            //返回点击事件给上层
            if(tmp.elementBaseValue==null)
                return;
            if(!tmp.elementBaseValue.bEnableShowMove)
                return;

            if(tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.StartMove,tmp.elementBaseValue);
            }
            if(e.button==1) {
                bmove=false;
                return;
            }

            tmp.mOriginalRawX =e.clientX;
            tmp.mOriginalRawY = e.clientY;
            bmove=true;


           //console.log("鼠标点击：：：："+bmove+"::"+tmp.elementBaseValue);
        };

        v.onmouseenter=(e)=>{
            benter=true;
        };

        v.onmouseleave=(e)=>{
            benter=false;
        }



        document.addEventListener("mousemove", (e)=>{
            e.preventDefault();

            if(!benter)
                return;

            if(tmp.eventState!=UISelectEventType.None)
                return;

           //console.log("鼠标移动：：：："+bmove+"::"+tmp.elementBaseValue+"::"+e.buttons);
            if(e.buttons==1&&!bmove)
            {
                tmp.mOriginalRawX =e.clientX;
                tmp.mOriginalRawY = e.clientY;
                bmove=true;
            }

            if(!bmove)
                return;

            if(e.buttons<=0) {
                bmove=false;
                return;
            }



            if(tmp.elementBaseValue==null)
                return;

            if(!tmp.elementBaseValue.bEnableShowMove)
                return;

            var offsetx= tmp.rulerXBaseValue.px2position(e.clientX)- tmp.rulerXBaseValue.px2position(tmp.mOriginalRawX);
            var offsety= tmp.rulerYBaseValue.px2position(e.clientY)-tmp.rulerYBaseValue.px2position(tmp.mOriginalRawY);

            tmp.elementBaseValue.mPosX =tmp.elementBaseValue.mPosX +offsetx;

            tmp.elementBaseValue.mPosY =tmp.elementBaseValue.mPosY +offsety ;

            for(var i=0;i<tmp.selectChildElements.length;i++)
            {
                tmp.selectChildElements[i].mPosX=tmp.selectChildElements[i].mPosX+offsetx;
                tmp.selectChildElements[i].mPosY=tmp.selectChildElements[i].mPosY+offsety;
            }


       //     tmp.updateUI();

            tmp.dispatchElementBaseValue();

            tmp.mOriginalRawX=e.clientX;
            tmp.mOriginalRawY=e.clientY;

            if(tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.Moving,tmp.elementBaseValue);
            }

        });
        document.addEventListener("mouseup", (e)=>{

            if(bmove&&tmp.eventsCallBack!=null)
            {
                tmp.eventsCallBack(UISelectEventType.Moved,tmp.elementBaseValue);
            }
            bmove=false;

            if(tmp.elementBaseValue==null)
                return;

            if(!tmp.elementBaseValue.bEnableShowMove)
                return;


            if(tmp.elementBaseValue==null)
                return;


        });
    }

    //是否在缩放和旋转按钮上

    isMouseInScaleAndRotateButton(e)
    {

        return this.isMouseInElement(this.leftUpDiv,e)
            ||  this.isMouseInElement(this.rightUpDiv,e)
            ||  this.isMouseInElement(this.leftDownDiv,e)
            ||  this.isMouseInElement(this.rightDownDiv,e)
            ||  this.isMouseInElement(this.rotationDiv,e);
    }


    isMouseInElement(element, e) {
        const rect = element.getBoundingClientRect();
        var zoom=document.body.style.zoom;



        if(zoom=="undefined"||zoom==null||zoom=="")
            zoom=1;

       //console.log(rect.left+"::"+rect.right+":"+rect.top+":"+rect.bottom+":"+e.clientY+"::"+e.clientX+"::"+zoom);
        return e.clientX/zoom >= rect.left &&
            e.clientX/zoom <= rect.right &&
            e.clientY/zoom >= rect.top &&
            e.clientY/zoom <= rect.bottom;
    }
}
