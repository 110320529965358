import Layout from "../components/base/layout";
import ProjectList from "../components/biz/projectList";

export default function Index() {
  return (
    <Layout>
      <ProjectList />
    </Layout>
  );
}
