export class UIRevocationRollback {
    constructor() {
        if (!UIRevocationRollback.instance) {
            this.maxUndoSize=20;
            this.undoDatas=[];
            this.redoDatas=[];
            UIRevocationRollback.instance = this;
        } else {
            return UIRevocationRollback.instance;
        }
    }

    //撤销一步

    clear()
    {
        this.undoDatas=[];
        this.redoDatas = [];
    }


    redo()
    {
        if (this.redoDatas.length <= 0)
            return null;
        //当当前数据一直到redo上去
        var tmps = this.redoDatas[0];
   //     this.undoDatas.push(this.deepCopyData(tmps));
        if (this.redoDatas.length > 0)
            this.redoDatas.splice(0,1);
        return tmps;
    }

    undo()
    {
        //console.log("撤销的步骤："+this.undoDatas.length+"::"+this.redoDatas.length);
        if (this.undoDatas.length <= 0)
            return null;
        var tmps=this.undoDatas[this.undoDatas.length - 1];
    //    this.redoDatas.unshift(this.deepCopyData(tmps));
        if (this.undoDatas.length>0)
            this.undoDatas.splice(this.undoDatas.length - 1,1);
        return tmps;
    }

    AddDataToUndo(datas)
    {
     //   var tmps = this.deepCopyData(datas);
        this.undoDatas.push(datas);
        if(this.undoDatas.length> this.maxUndoSize)
        {
            this.undoDatas.splice(0,1);
        }
        //清空redo里的数据
        this.redoDatas=[];
    }

    AddDataToUndoNoDeepCopy(datas)
    {
      //  var tmps = this.deepCopyData(datas);
        this.undoDatas.push(datas);
        if(this.undoDatas.length> this.maxUndoSize)
        {
            this.undoDatas.splice(0,1);
        }
        //清空redo里的数据
        this.redoDatas=[];
    }

     deepCopyData(obj) {
        if (obj === null || typeof obj != 'object') {
            return obj;
        }

        if (obj instanceof Date) {
            return new Date(obj.getTime());
        }

        if (obj instanceof Array) {
            return obj.reduce((arr, item, i) => {
                arr[i] = this.deepCopyData(item);
                return arr;
            }, []);
        }

        if (obj instanceof Object) {
            return Object.keys(obj).reduce((newObj, key) => {
                newObj[key] = this.deepCopyData(obj[key]);
                return newObj;
            }, {});
        }
    }





}
