
export  class UIMattingImage {
    constructor() {
        if (!UIMattingImage.instance) {
            this.matingUrl="http://y1.qbaidata.com:5000/process_image";
            UIMattingImage.instance = this;
        } else {
            return UIMattingImage.instance;
        }
    }
    init() {
    }

    requireImageMatting(bytes,fileName,resultCallBack)
    {
        if(bytes==null||bytes.length==0)
        {
            if(resultCallBack!=null)
            {
                resultCallBack(-1,"数据不能为空");
            }
            return;
        }
        this.uploadFileToUrl(bytes,fileName,this.matingUrl,function (code,result) {
            resultCallBack(code,result);
        });
    }

    //根据分割完的图像对前景进行裁剪，裁剪大小为透明度的最左边和最右边，最上边和最下边




    readBlobByBinary(blob,callBack) {
        const reader = new FileReader();
// 定义读取完毕时的回调函数
        reader.onload = function (event) {
            if (event.target.result) {
                // 将读取的结果转换为ArrayBuffer
                const arrayBuffer = event.target.result;
                // 将ArrayBuffer转换为字节数组（Uint8Array）
                const uint8Array = new Uint8Array(arrayBuffer);
                // 处理字节数组
                if (callBack != null)
                    callBack(uint8Array);
            }
        };
        reader.readAsArrayBuffer(blob);
    }

    uploadFileToUrl(bytes,fileName,url,callback) {
        var tmp=this;
        const file = new Blob([bytes]); // 获取选中的文件对象
        if (file) {
            var formData = new FormData(); // 创建表单数据对象
            formData.append('file', file,fileName); // 添加要上传的文件到表单数据对象
            var xhr = new XMLHttpRequest(); // 创建XMLHttpRequest对象
            //console.log("当前上传的地址为1：："+url);
            xhr.open('POST', url); // 设置请求URL为服务器接收文件的路由或API
            xhr.responseType='blob';
            xhr.timeout=30*1000;
            xhr.onload = function () {
                const  blon=new Blob([xhr.response]);
                tmp.readBlobByBinary(blon,function (readDatas) {
                    if(callback!=null)
                        callback(2,readDatas);
                });
            };


            xhr.ontimeout = function() {
                if(callback!=null)
                    callback(-1,"请求超时");

                //console.log("请求超时");
            };
            xhr.onerror = function() {
                if(callback!=null)
                    callback(-1,"连接被拒绝或者连接失败");
            };

            xhr.onreadystatechange = function() {

                console.log(xhr.status);
                if (xhr.status === 200&&xhr.response&&xhr.readyState==4) {

                }else if(xhr.readyState == 4)
                {
                    if(callback!=null)
                        callback(-1,"服务器未响应");
                }
            };

            xhr.upload.addEventListener("progress", function(event) {
                if (event.lengthComputable) {
                    var percentComplete = event.loaded / event.total * 100; // 计算上传进度百分比
                    //console.log("上传进度： " + Math.round(percentComplete) + "%"); // 输出上传进度信息
                    if(callback!=null)
                        callback(1,percentComplete);
                }
            });

            try {
                xhr.send(formData); // 发送表单数据到服务器
            }catch (e) {
                console.log("服务器拒绝连接");
                if(callback!=null)
                    callback(-1,"服务器拒绝连接");
            }

        } else {
            if(callback!=null)
                callback(-1,"上传失败");
        }
    }
}
