
// import CanvasKitInit, {PaintStyle} from "canvaskit-wasm";
// import CanvasKitWasm from "canvaskit-wasm/bin/canvaskit.wasm";
import React from "react";
export  class UIDrawGraphics {
    constructor() {
        if (!UIDrawGraphics.instance) {
            this.parent = null
            this.surface = null;
            this.canvas = null;
            this.CK = null;
            this.alphaThreload=0.2;//在深度图中，r,g,b中低于该值时将设置为透明
            this.blendParameter=0.3;//深度图*(1-blendParameter）+灰度图*blendParameter;
            this.vectorAlpha=0.8;
            this.maxSplitCount=50;//最大图像分割的数
            this.splitBackgroundImageEffect=null;
            this.splitBackgroundImageNoMaskEffect=null;
            this.splitForegroundImageEffect=null;
            this.normalImageEffect=null;
            this.splitForegroundImageNoMaskEffect=null;
            this.defaultEffect=null;
            this.alphaEffect=null;
            this.mattingEffect=null;
            this.vectorImageEffect=null;
            this.imgFormat=null;
            this.colorImgFormat=null;
            this.defaultEraseImage=null;

            UIDrawGraphics.instance = this;
        } else {
            return UIDrawGraphics.instance;
        }
    };

    initNoUI(CanvasKitInit,CanvasKitWasm,callBack)
    {
        var tmp=this;
        CanvasKitInit({
            locateFile:  (file) => CanvasKitWasm,
        }).then((CanvasKit) => {
            tmp.CK = CanvasKit;
            tmp.initImageFormat();
            tmp.initShader();
            if (callBack != null)
                callBack(0, "初始化成功");
        });
    }


    init(parentContainer,CanvasKitInit,CanvasKitWasm,callBack)
    {
        if(this.surface!=null) {
            return;
        }

        this.parent=parentContainer;
        this.canvas = document.createElement('canvas');

        //获取父物体的大小
        const rect =  this.parent.getBoundingClientRect();
        this.canvas.style.width=rect.width+"px";
        this.canvas.style.height=rect.height+"px";
        this.canvas.width= rect.width;
        this.canvas.height=rect.height;
        this.canvas.style.display="block";
        this.canvas.style.position="absolute";
        this.canvas.style.background="transparent";
        this.canvas.style.left=0+"px";
        this.canvas.style.top=0+"px";
        //this.canvas.style.zIndex="999";
        this.canvas.style.pointerEvents = 'none';
        this.canvas.id="canvasedit";
        this.parent.appendChild(this.canvas);

        var tmp=this;
        CanvasKitInit({
            locateFile:  (file) => CanvasKitWasm,
        }).then((CanvasKit) => {
            tmp.CK = CanvasKit;
            tmp.surface = tmp.CK.MakeWebGLCanvasSurface("canvasedit",tmp.CK.ColorSpace.SRGB, {
                enableExtensionsByDefault: 3,});
            tmp.canvas = tmp.surface.getCanvas();
            tmp.canvas.clear(CanvasKit.Color4f(0, 0, 0, 0));
            tmp.initImageFormat();
            tmp.initShader();
            if (callBack != null)
                callBack(0, "初始化成功");
        });
    };

    initImageFormat()
    {
       this.imgFormat = {
            alphaType: this.CK.AlphaType.Unpremul,
            colorType:  this.CK.ColorType.RGBA_8888,
            colorSpace:  this.CK.ColorSpace.SRGB,
            width:1,
            height:1
        };

        this.colorImgFormat = {
            alphaType: this.CK.AlphaType.Unpremul,
            colorType:  this.CK.ColorType.RGBA_8888,
            colorSpace:  this.CK.ColorSpace.SRGB,
            width:2,
            height:2
        };

        var bytes=[];
        for(var i=0;i<this.colorImgFormat.width*this.colorImgFormat.height;i++)
        {
            bytes.push(0);
            bytes.push(0);
            bytes.push(0);
            bytes.push(0);
        }
       this.defaultEraseImage=this.CK.MakeImage(this.colorImgFormat,bytes,this.colorImgFormat.width*4);
    }

    initShader()
    {
        //分割出背景图像，并融合细节
        const defaultSksl=`
  half4 main(float2 xy) {
 return half4(0,0,0,0);;
}`;
        this.defaultEffect = this.CK.RuntimeEffect.Make(defaultSksl);
        const splitBackgroundImageSksl=`
             uniform shader depthImage;
             uniform shader greyImage;
             uniform shader maskImage;
             uniform float alphaThreload;
             uniform float blendParameter;
        half4 main(float2 xy) {
        half4 depthColor=depthImage.eval(xy);
        if(depthColor.b < alphaThreload)
        {
           //这里作为背景图判断,给出细节
             half4 greyColor=greyImage.eval(xy).rgba;
             float r=depthColor.r*(1-blendParameter)+blendParameter*greyColor.r;
             float g=depthColor.g*(1-blendParameter)+blendParameter*greyColor.g;
             float b=depthColor.b*(1-blendParameter)+blendParameter*greyColor.b;
             return  half4(r,g,b,1);
        }else
        {
             return half4(0,0,0,0);
        }
      }`;
        this.splitBackgroundImageEffect = this.CK.RuntimeEffect.Make(splitBackgroundImageSksl);



        const splitBackgroundImageNoMaskSksl=`
             uniform shader depthImage;
             uniform shader greyImage;
             uniform float blendParameter;
        half4 main(float2 xy) {
        
            
        
             half4 depthColor=depthImage.eval(xy);
             half4 greyColor=greyImage.eval(xy).rgba;
             float r=depthColor.r*(1-blendParameter)+blendParameter*greyColor.r;
             float g=depthColor.g*(1-blendParameter)+blendParameter*greyColor.g;
             float b=depthColor.b*(1-blendParameter)+blendParameter*greyColor.b;
             return  half4(r,g,b,1);
      }`;

        this.splitBackgroundImageNoMaskEffect=this.CK.RuntimeEffect.Make(splitBackgroundImageNoMaskSksl);

        //根据mask对前景图像进行分割
        const splitForegroundImageSksl=`
             uniform shader depthImage;
             uniform shader greyImage;
             uniform shader maskImage;
             uniform float blendParameter;
             uniform float areaIdentify;
             
        half4 main(float2 xy) {
             half4 depthColor=depthImage.eval(xy).rgba;
             half4 maskColor=maskImage.eval(xy).rgba;
             half4 greyColor=greyImage.eval(xy).rgba;
             if(areaIdentify == -1)
             {
                 if(maskColor.r >=0.99)
                 {
                     return  half4(0,0,0,0);
                 }else
                 {
                      float r=depthColor.r*(1-blendParameter)+blendParameter*greyColor.r;
                      float g=depthColor.g*(1-blendParameter)+blendParameter*greyColor.g;
                      float b=depthColor.b*(1-blendParameter)+blendParameter*greyColor.b;
                      return half4(r,g,b,1);
                 }
             }else
             {
                  if(maskColor.r >= 0.99)
                 {
                      float r=depthColor.r*(1-blendParameter)+blendParameter*greyColor.r;
                      float g=depthColor.g*(1-blendParameter)+blendParameter*greyColor.g;
                      float b=depthColor.b*(1-blendParameter)+blendParameter*greyColor.b;
                      
                      return half4(r,g,b,1);

                 }else
                 {
                      return  half4(0,0,0,0);
                 }
             }
      }`;


        this.splitForegroundImageEffect=this.CK.RuntimeEffect.Make(splitForegroundImageSksl);


        const yingsheSksl=`
             uniform shader blendImage;   
             uniform float bx;
             uniform float by;
             uniform float bw;
             uniform float bh;
             
        half4 main(float2 xy) {
             half4 blendColor=blendImage.eval(xy).rgba;
             float depth=blendColor.r;
             vec3 angel=vec3(0,0,3.14159);
             vec3 a=vec3(xy.x,xy.y,depth);
             vec3 c=vec3((bx+bw/2),(by+bh/2),4);
             vec3 e=vec3((bx+bw/2),(by+bh/2),3.8);
             float dx = cos(angel.y)*(sin(angel.z)*(a.y-c.y)+cos(angel.z)*(a.x-c.x))-sin(angel.y)*(a.z-c.z);
             float dy=sin(angel.x)*(cos(angel.y)*(a.z-c.z)+sin(angel.y)*(sin(angel.z)*(a.y-c.y)+cos(angel.z)*(a.x-c.x)))+cos(angel.x)*(cos(angel.z)*(a.y-c.y)-sin(angel.z)*(a.x-c.x));
             float dz=cos(angel.x)*(cos(angel.y)*(a.z-c.z)+sin(angel.y)*(sin(angel.z)*(a.y-c.y)+cos(angel.z)*(a.x-c.x)))-sin(angel.x)*(cos(angel.z)*(a.y-c.y)-sin(angel.z)*(a.x-c.x));
             float x0=(dx-e.x)*(e.z/dz);
             float y0=(dy-e.y)*(e.z/dz);
             return half4(x0/bw,y0/bh,xy.x/bw,xy.y/bh);
             
      }`;

        this.yingsheImageEffect=this.CK.RuntimeEffect.Make(yingsheSksl);


        const splitForegroundImageNoMaskSksl=`
             uniform shader srcImage;
             uniform shader maskImage;
        half4 main(float2 xy) {
             half4 maskColor=maskImage.eval(xy).rgba;
             half4 srcColor=srcImage.eval(xy).rgba;
             
             if(maskColor.r >= 0.9)
                 {
                  return srcColor;   
                 }else
                 {        
                      return  half4(0,0,0,0);
                 }
        
      }`;
        this.splitForegroundImageNoMaskEffect=this.CK.RuntimeEffect.Make(splitForegroundImageNoMaskSksl);

        const splitSksl=`
             uniform shader srcImage;
             uniform shader maskImage;
             uniform int layor;
        half4 main(float2 xy) {
             half4 maskColor=maskImage.eval(xy).rgba;
             half4 srcColor=srcImage.eval(xy).rgba;
             
             if(layor!=-1)
             {
             
             if(maskColor.r >= 0.9)
                 {
                      return srcColor;   
                 }else
                 {        
                      return  half4(0,0,0,0);
                 }
            }else
            {
                  if(maskColor.r >= 0.9)
                 {
                      return  half4(0,0,0,0);
                 }else
                 {        
                       return srcColor;
                 }
            }
        
      }`;
        this.splitEffect=this.CK.RuntimeEffect.Make(splitSksl);

        const vectorImageSksl=`
             uniform shader vectorImage;
             uniform shader mapImage;
             uniform shader mapEraseImage;
             uniform shader vectorEraseImage;
             uniform shader lightImage;
             uniform shader normalImage;
             uniform int type;
             uniform float xScale;
             uniform float yScale;
             uniform float offset1;
             uniform float offset2;
             uniform float offset3;
             uniform float shapeScaleX;
             uniform float shapeScaleY;
             uniform float alphaScale;
             uniform float lightAverage;
             uniform float dstx;
             uniform float dsty;
             uniform float dstw;
             uniform float dsth;
             
             uniform float bx;
             uniform float by;
             uniform float bw;
             uniform float bh;
             
          vec2 projectPointOnToXYPlane(vec3 point,vec3 normal)
          {
               vec3 unitNormal = normalize(normal);
               vec3 orthogonalNormal = vec3(unitNormal.y, unitNormal.z, unitNormal.x);
               float x=point.x-orthogonalNormal.x*point.z;
               float y=point.y-orthogonalNormal.y*point.z;
               return vec2(x,y);
          }  
   
         vec3 getNormal(vec2 point)
         {
               float cx=point.x;
               float cy=point.y;
               float cz=2*mapImage.eval(vec2(cx,cy)).r-1;
                
               float x1=cx+1;
               float y1=cy;
               float z1=2*mapImage.eval(vec2(x1,y1)).r-1;

               float x2=cx;
               float y2=cy+1;
               float z2=2*mapImage.eval(vec2(x2,y2)).r-1;


               vec3 edge1=vec3(x1-cx,y1-cy,z1-cz);
               vec3 edge2=vec3(x2-cx,y2-cy,z2-cz);
               vec3 normal=vec3(edge1.y * edge2.z - edge1.z * edge2.y, edge1.z * edge2.x - edge1.x * edge2.z,edge1.x * edge2.y - edge1.y * edge2.x);
               normal= normalize(normal);
               return  normalize(normal);
         }
         
         float2 ReliefMapping(float2 uv,vec3 viewDirTS,float heightScale)
            {
            
                float2 offlayerUV = viewDirTS.xy / viewDirTS.z * heightScale;
                float RayNumber = 20;
                float layerHeight = 1.0 / RayNumber;
                float2 SteppingUV = offlayerUV / RayNumber;
                float offlayerUVL = length(offlayerUV);
                float currentLayerHeight = 0;
                
                float2 offuv= float2(0,0);
                for (float i = 0; i < 20; i++)
                {
                    offuv += SteppingUV;

                    float currentHeight = 2*mapImage.eval(uv + offuv).r-1;
                    currentLayerHeight += layerHeight;
                    if (currentHeight < currentLayerHeight)
                    {
                        break;
                    }
                }

                float2 T0 = uv-SteppingUV, T1 = uv + offuv;

                for (float j = 0;j<20;j++)
                {
                    float2 P0 = (T0 + T1) / 2;

                    float P0Height =  2*mapImage.eval(P0).r-1;

                    float P0LayerHeight = length(P0) / offlayerUVL;

                    if (P0Height < P0LayerHeight)
                    {
                        T0 = P0;

                    }
                    else
                    {
                        T1= P0;
                    }

                }

                return (T0 + T1) / 2 - uv;
            }
         
               
         half4 main(float2 xy) {
        
             half4 mapImageColor=mapImage.eval(xy);
             half4 vectorColor=vectorImage.eval(xy);
         if(type==-1)
        {
              if(mapImageColor.a==0)
            {
                return half4(0,0,0,0);
            }else
            {    return  half4(vectorColor.rgb*vectorColor.a,vectorColor.a);
            }
           
        }else{
        
            if(mapImageColor.a==0)
            {
                return mapImageColor;
            }else
            { 
            
            float xNext=xy.x+5;
            float yNext=xy.y+5;
            float xPre=xy.x-5;
            float yPre=xy.y-5;
            
            if(mapImage.eval(vec2(xNext,xy.y)).a == 0)
               return half4(0,0,0,0);
            if(mapImage.eval(vec2(xNext,yPre)).a == 0)
               return half4(0,0,0,0);
             if(mapImage.eval(vec2(xNext,yNext)).a == 0)
               return half4(0,0,0,0);
                     
            if(mapImage.eval(vec2(xPre,xy.y)).a == 0)
               return half4(0,0,0,0);
            if(mapImage.eval(vec2(xPre,yPre)).a == 0)
               return half4(0,0,0,0);
            if(mapImage.eval(vec2(xPre,yNext)).a == 0)
               return half4(0,0,0,0);
              
            
                       
            if(mapEraseImage.eval(xy).a == 0
            )
            {  
                 float height=2*mapImageColor.r-1;
                 vec3 a_normal =normalImage.eval(xy).rgb;
                 a_normal=normalize(a_normal);
                 vec3 c1 = cross(a_normal, vec3(0.0, 0.0, 1));
                 vec3 c2 = cross(a_normal, vec3(0.0, 1.0, 0.0));
                 vec3 tangent=vec3(0);
                 if (length(c1) < length(c2))
                 {                 
                    tangent = c1;
                    
                  }
                  else
                 {
                   tangent = c2;
                 }
                 
                 tangent = normalize(tangent);

                 vec3 binormal = cross(a_normal, tangent);
                 binormal = normalize(binormal);
                 
                 mat3 T2W = mat3(tangent,binormal, a_normal);
                 
                
                 
                 //归一化当前坐标点
                 vec3 viewDirWS=normalize(vec3(xy.x,xy.y,height));
                 
                 //转换到切向空间
                 vec3 viewDirTS=normalize(viewDirWS*T2W);
             
                 
                 float2 uv= viewDirTS.xy / viewDirTS.z;
                 float2 offuv = uv* height*xScale;
                 xy=xy+offuv;
               
                 
                     
                  return half4(vectorImage.eval(xy).rgb*vectorImage.eval(xy).a*alphaScale,vectorImage.eval(xy).a*alphaScale);
                  
                  
             }else
             {
                return half4(0,0,0,0);
             }
            
          }
        }
      }`;
        this.vectorImageEffect=this.CK.RuntimeEffect.Make(vectorImageSksl);

        const normalImageEffectSksl=`
             uniform shader depthImage;
             uniform float texelSize;
        half4 main(float2 xy) {
        
       vec3 gx = vec3(
            depthImage.eval(xy + vec2(1.0, 0.0) * texelSize).r - depthImage.eval(xy- vec2(1.0, 0.0) * texelSize).r,
             depthImage.eval(xy+ vec2(2.0, 0.0) * texelSize).r - depthImage.eval(xy - vec2(2.0, 0.0) * texelSize).r,
             depthImage.eval(xy+ vec2(3.0, 0.0) * texelSize).r - depthImage.eval(xy - vec2(3.0, 0.0) * texelSize).r
        );
        // Scharr GY
       vec3 gy = vec3(
             depthImage.eval(xy+ vec2(0.0, 1.0) * texelSize).r - depthImage.eval(xy- vec2(0.0, 1.0) * texelSize).r,
             depthImage.eval(xy+ vec2(0.0, 2.0) * texelSize).r - depthImage.eval(xy- vec2(0.0, 2.0) * texelSize).r,
             depthImage.eval(xy+ vec2(0.0, 3.0) * texelSize).r - depthImage.eval(xy - vec2(0.0, 3.0) * texelSize).r
        );
        float mag = length(gx) + length(gy);
        float ggx=length(gx);
        float ggy=length(gy);
        float ggz=sqrt(ggx * ggx + ggy * ggy);
          
          return half4(ggx,ggy,ggz,1.0);
      }`;

        this.normalImageEffect=this.CK.RuntimeEffect.Make(normalImageEffectSksl)

        const alphaSksl=`

             uniform shader vectorImage1;
             
             uniform float offsetr;
             uniform float offsetg;
             uniform float offsetb;
      
        half4 main(float2 xy) {
        //计算偏移中指
        // bool br=vectorImage1.eval(xy).r >= offsetr;
        // bool bg=vectorImage1.eval(xy).g >= offsetg;
        // bool bb=vectorImage1.eval(xy).b >=offsetb;
        //  if(br && bg && bb)
        //        return  half4(0,0,0,0);
        
           half4 vectorColor=vectorImage1.eval(xy);
           if(vectorImage1.eval(xy).a>0)
               return vectorColor; 
           
           return  half4(0,0,0,0);
        
               // return   half4(vectorImage1.eval(xy).rgb*vectorImage1.eval(xy).a,vectorImage1.eval(xy).a);    
      }`;
        this.alphaEffect=this.CK.RuntimeEffect.Make(alphaSksl);



        const mattingSksl=`

             uniform shader vectorImage1;
             uniform shader depthImage1;
             
             uniform float threload;
             
        half4 main(float2 xy) {
        //计算偏移中指
        if(depthImage1.eval(xy).r <= threload)
        {
            return half4(0,0,0,0);
        }else
        {
           return vectorImage1.eval(xy);
        }
      }`;
        this.mattingEffect=this.CK.RuntimeEffect.Make(mattingSksl);

    }

    //对彩色图像灰度化
    loadGreyImageFromColorImage(oriImageData,imageWidth,imageHeight)
    {
        const oriImage = this.CK.MakeImageFromEncoded(oriImageData);
        const greyCf=this.CK.ColorFilter.MakeMatrix(Float32Array.of(
            0.2126, 0.7152, 0.07152, 0,0,// 第一个是R的缩放，第二个是G的分量，第三个是B的分量，第四个是A的分量，第五个是A的缩放值，最终的R=(rs*g+gs*g+bs*b+as*a)*as;
            0.2126, 0.7152, 0.07152,0,0, // 绿色入口
            0.2126, 0.7152, 0.07152,0,0,  // 蓝色入口
            0, 0, 0, 0, 1//第四个参数是透明度的缩放，第五个参数也是透明度缩放，as=a5+a4;
        ));

        var tmp=this;
        var subWidth=Math.max(this.surface.width(),imageWidth);
        var subHeight=Math.max(this.surface.height(),imageHeight);
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });



        var canvas = subSurface.getCanvas();
        canvas.clear(this.CK.Color4f(0,0,0,0));
        const oriImageFilter = this.CK.ImageFilter.MakeImage(oriImage, {filter: this.CK.FilterMode.Nearest,mipmap:this.CK.MipmapMode.Nearest });
        const blendIF=this.CK.ImageFilter.MakeColorFilter(greyCf,oriImageFilter);
        //    const extractForegroundShaderShaderIF =tmp.CK.ImageFilter.MakeBlur(3,3,tmp.CK.TileMode.Decal,blendIF);
        var paint=new tmp.CK.Paint();
        paint.setColor(tmp.CK.Color(0,0,0,0));
        paint.setImageFilter(blendIF);
        canvas.drawPaint(paint);
        var greyImage= subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0,0,imageWidth,imageHeight));
        //读取字节
        blendIF.delete();
        //   extractForegroundShaderShaderIF.delete();
        paint.delete();
        subSurface.delete();
        var bytes =greyImage.encodeToBytes(this.CK.ImageFormat.PNG, 100);
        return [greyImage,bytes];
    }

    blurNormalImage(normalImageRgbaData,imageWidth,imageHeight)
    {
        var normalImage=this.transformRGBADataToImage(normalImageRgbaData,imageWidth,imageHeight);

        var tmp=this;
        var subWidth=Math.max(this.surface.width(),imageWidth);
        var subHeight=Math.max(this.surface.height(),imageHeight);
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });

        var canvas = subSurface.getCanvas();
        canvas.clear(this.CK.Color4f(0,0,0,0));

        const oriImageFilter = tmp.CK.ImageFilter.MakeImage(normalImage, {filter: this.CK.FilterMode.Nearest,mipmap:this.CK.MipmapMode.Nearest });

        const normalShaderIF =tmp.CK.ImageFilter.MakeBlur(5,5,tmp.CK.TileMode.Decal,oriImageFilter);
        var paint=new tmp.CK.Paint();
        paint.setColor(tmp.CK.Color(0,0,0,0));
        paint.setImageFilter(normalShaderIF);
        canvas.drawPaint(paint);
        var normalImage= subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0,0,imageWidth,imageHeight));
        //读取字节
        normalShaderIF.delete();
        paint.delete();
        subSurface.delete();
        var bytes =normalImage.encodeToBytes(this.CK.ImageFormat.PNG, 100);
        return [normalImage,bytes];
    }

    //对深度图进行提取法向量贴图
    loadNormalImageFromColorImage(depthData,imageWidth,imageHeight)
    {
        const oriImage = this.CK.MakeImageFromEncoded(depthData);
        var tmp=this;
        var subWidth=Math.max(this.surface.width(),imageWidth);
        var subHeight=Math.max(this.surface.height(),imageHeight);
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });

        var canvas = subSurface.getCanvas();
        canvas.clear(this.CK.Color4f(0,0,0,0));

        const depthShader = oriImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);

        const oriImageShader = this.normalImageEffect.makeShaderWithChildren([1], [depthShader]);
        const oriImageFilter = tmp.CK.ImageFilter.MakeShader(oriImageShader);
        const normalShaderIF =tmp.CK.ImageFilter.MakeBlur(0,0,tmp.CK.TileMode.Decal,oriImageFilter);
        //    const extractForegroundShaderShaderIF =tmp.CK.ImageFilter.MakeBlur(3,3,tmp.CK.TileMode.Decal,blendIF);
        var paint=new tmp.CK.Paint();
        paint.setColor(tmp.CK.Color(0,0,0,0));
        paint.setImageFilter(normalShaderIF);
        canvas.drawPaint(paint);
        var normalImage= subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0,0,imageWidth,imageHeight));
        //读取字节
        normalShaderIF.delete();
        depthShader.delete();
        paint.delete();
        subSurface.delete();
        var bytes =normalImage.encodeToBytes(this.CK.ImageFormat.PNG, 100);
        return [normalImage,bytes];

    }


    //对普通的图像根据像素点进行抠图
   mattingImageData(srcData,depthData,threload,imageWidth,imageHeight)
   {

       const srcImage = this.CK.MakeImageFromEncoded(srcData);
       const depthImage = this.CK.MakeImageFromEncoded(depthData);
       if(srcImage==null||depthData==null)
           return null;
      //console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq"+imageWidth+"::"+imageHeight);
       var subWidth = imageWidth;
       var subHeight =imageHeight;

       var tmp=this;
       var subSurface = this.surface.makeSurface({
           width: subWidth,
           height: subHeight,
           alphaType: tmp.CK.AlphaType.Premul,
           colorType: tmp.CK.ColorType.RGBA_8888,
           colorSpace: tmp.CK.ColorSpace.SRGB
       });

       var canvas = subSurface.getCanvas();
       canvas.clear(tmp.CK.Color(0, 0, 0, 0));
       const vectorImageShader= srcImage.makeShaderOptions(this.CK.TileMode.Decal,this.CK.TileMode.Decal,this.CK.FilterMode.Nearest,this.CK.MipmapMode.Linear);
       const depthImageShader= srcImage.makeShaderOptions(this.CK.TileMode.Decal,this.CK.TileMode.Decal,this.CK.FilterMode.Nearest,this.CK.MipmapMode.Linear);


       const mattingShader=this.mattingEffect.makeShaderWithChildren([threload],[vectorImageShader,depthImageShader]);
       const shaderIF = tmp.CK.ImageFilter.MakeShader(mattingShader);
       var paint = new tmp.CK.Paint();
       //这里对图像进行平滑操作
       paint.setColor(tmp.CK.Color(0, 0, 0, 0));
       paint.setImageFilter(shaderIF);
       canvas.drawPaint(paint);

       //取图像
       var mattingImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
       var bytes = mattingImage.encodeToBytes(this.CK.ImageFormat.PNG, 100);

       mattingShader.delete();
       paint.delete();
       canvas.clear(tmp.CK.Color(0, 0, 0, 0));
       subSurface.dispose();
       return bytes;
   }

    //对深度图，灰度图，mask图三张图进行预处理，得到最终的合成图，最后返回shader
    loadHighEffectImageData(uuid,depthData,maskData,imageWidth,imageHeight,callBack)
    {
        var greyImage=this.defaultEraseImage;

        const depthImage = this.CK.MakeImageFromEncoded(depthData);
        if(depthImage==null)
        {
            if(callBack!=null)
            {
                callBack(-1, "深度图为空");
            }
            return;
        }
        //分割图像逻辑
        const maskImage = this.CK.MakeImageFromEncoded(maskData);
        if(maskImage==null)
        {
            //这里采用深度图进行分割
            const depthShader = depthImage.makeShaderCubic(
                this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                1 / 3, 1 / 3);

            const greyShader = greyImage.makeShaderCubic(
                this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                1 / 3, 1 / 3);
            var tmp = this;
            var subWidth = Math.max(this.surface.width(), imageWidth);
            var subHeight = Math.max(this.surface.height(), imageHeight);
            var subSurface = this.surface.makeSurface({
                width: subWidth,
                height: subHeight,
                alphaType: tmp.CK.AlphaType.Premul,
                colorType: tmp.CK.ColorType.RGBA_8888,
                colorSpace: tmp.CK.ColorSpace.SRGB
            });
            var canvas = subSurface.getCanvas();
             var background_images = [];
            // canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            // const extractBackGoungShader = tmp.splitBackgroundImageNoMaskEffect.makeShaderWithChildren([tmp.alphaThreload, tmp.blendParameter], [depthShader, greyShader]);
            // var paint = new tmp.CK.Paint();
            // //这里对图像进行平滑操作
            // const shaderIF = tmp.CK.ImageFilter.MakeShader(extractBackGoungShader);
            const colorImageFilter = tmp.CK.ColorFilter.MakeLinearToSRGBGamma();
            // const imageIF = tmp.CK.ImageFilter.MakeColorFilter(colorImageFilter, shaderIF);
            //
            // paint.setColor(tmp.CK.Color(0, 0, 0, 0));
            // paint.setImageFilter(imageIF);
            // canvas.drawPaint(paint);
            // imageIF.delete();
            // shaderIF.delete();
            // extractBackGoungShader.delete();
            // paint.delete();
            // //取图像
            // var backgroungImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
            // var backBounds = [{x: 0, y: 0}, {x: imageWidth, y: 0}, {x: imageWidth, y: imageHeight}, {
            //     x: 0,
            //     y: imageHeight
            // }];
            // var data1 = {
            //     layor: -1,
            //     images: backgroungImage,
            //     bounds: backBounds
            // };
            //
            // background_images.push(data1);

            canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            var layor = 0;
            const extractForegroundShader = tmp.splitForegroundImageNoMaskEffect.makeShaderWithChildren([0, tmp.blendParameter, layor], [depthShader, greyShader]);
            const extractForegroundShaderIF = tmp.CK.ImageFilter.MakeShader(extractForegroundShader);

            const extractForegroundShaderShaderIF =tmp.CK.ImageFilter.MakeBlur(0.8,0.8,tmp.CK.TileMode.Decal,extractForegroundShaderIF);
            //   const extractForegroundShaderIF = extractForegroundShaderShaderIF;
            var fPaint = new tmp.CK.Paint();
            fPaint.setColor(tmp.CK.Color(0, 0, 0, 0));
            fPaint.setImageFilter(extractForegroundShaderShaderIF);
            canvas.drawPaint(fPaint);
            extractForegroundShaderShaderIF.delete();
            extractForegroundShaderIF.delete();
            extractForegroundShader.delete();
            fPaint.delete();

            var foregroundImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
            canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            var data = {
                layor: layor,
                images: foregroundImage,
                bounds: ""
            };

            background_images.push(data);

            canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            depthShader.delete();
            greyShader.delete();
            colorImageFilter.delete();
            subSurface.dispose();
            this.maxSplitCount=2;
            if (callBack != null) {
                callBack(0, {uuid: uuid, maps: background_images});
            }

        }else {
            const depthShader = depthImage.makeShaderCubic(
                this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                1 / 3, 1 / 3);

            const greyShader = greyImage.makeShaderCubic(
                this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                1 / 3, 1 / 3);

            const maskShader = maskImage.makeShaderCubic(
                this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                1 / 3, 1 / 3);

            var tmp = this;
            var subWidth = Math.max(this.surface.width(), imageWidth);
            var subHeight = Math.max(this.surface.height(), imageHeight);
            var subSurface = this.surface.makeSurface({
                width: subWidth,
                height: subHeight,
                alphaType: tmp.CK.AlphaType.Premul,
                colorType: tmp.CK.ColorType.RGBA_8888,
                colorSpace: tmp.CK.ColorSpace.SRGB
            });
            var canvas = subSurface.getCanvas();
            var background_images = [];
            canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            const extractBackGoungShader = tmp.splitBackgroundImageEffect.makeShaderWithChildren([tmp.alphaThreload, tmp.blendParameter], [depthShader, greyShader, maskShader]);
            var paint = new tmp.CK.Paint();
            //这里对图像进行平滑操作
            const shaderIF = tmp.CK.ImageFilter.MakeShader(extractBackGoungShader);
            const colorImageFilter = tmp.CK.ColorFilter.MakeLinearToSRGBGamma();
            const imageIF = tmp.CK.ImageFilter.MakeColorFilter(colorImageFilter, shaderIF);
            paint.setColor(tmp.CK.Color(0, 0, 0, 0));
            paint.setImageFilter(imageIF);
            canvas.drawPaint(paint);
            imageIF.delete();
            shaderIF.delete();
            extractBackGoungShader.delete();
            paint.delete();
            //取图像
            var backgroungImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
            var backBounds = [{x: 0, y: 0}, {x: imageWidth, y: 0}, {x: imageWidth, y: imageHeight}, {
                x: 0,
                y: imageHeight
            }];
            var data1 = {
                layor: -1,
                images: backgroungImage,
                bounds: backBounds
            };

            background_images.push(data1);
            // 提取各个前景图的各个区域，最大区域个数为20
            for (var i = 0; i < this.maxSplitCount; i++) {
                canvas.clear(tmp.CK.Color(0, 0, 0, 0));
                var layor = i / 255;
                const extractForegroundShader = tmp.splitForegroundImageEffect.makeShaderWithChildren([tmp.alphaThreload, tmp.blendParameter, layor], [depthShader, greyShader, maskShader]);
                //平滑像素点
                const extractForegroundShaderIF = tmp.CK.ImageFilter.MakeShader(extractForegroundShader);
                // const extractForegroundShaderShaderIF1 = tmp.CK.ImageFilter.MakeColorFilter(colorImageFilter, extractForegroundShaderIF);
                //边缘模糊，高斯模糊
                const extractForegroundShaderShaderIF = tmp.CK.ImageFilter.MakeBlur(1,1,tmp.CK.TileMode.Decal,extractForegroundShaderIF);

                var fPaint = new tmp.CK.Paint();
                fPaint.setColor(tmp.CK.Color(0, 0, 0, 0));
                fPaint.setImageFilter(extractForegroundShaderShaderIF);
                canvas.drawPaint(fPaint);
                extractForegroundShaderShaderIF.delete();
                extractForegroundShaderIF.delete();
                extractForegroundShader.delete();
                fPaint.delete();

                var foregroundImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
                canvas.clear(tmp.CK.Color(0, 0, 0, 0));
                var data = {
                    layor: layor,
                    images: foregroundImage,
                    bounds: ""
                };

                background_images.push(data);

            }
            canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            depthShader.delete();
            greyShader.delete();
            maskShader.delete();
            subSurface.dispose();

            colorImageFilter.delete();
            if (callBack != null) {
                callBack(0, {uuid: uuid, maps: background_images});
            }
        }
    }


    //对原图进行分割成前景和背景
    splitSingleEffectImageData(uuid,srcImageData,maskImageData,imageWidth,imageHeight,layor)
    {
       var maskImage=this.CK.MakeImageFromEncoded(maskImageData);
       var srcImage=this.CK.MakeImageFromEncoded(srcImageData);
        var maskShader= null;
        if( maskImage!=null) {
            maskShader = maskImage.makeShaderCubic(
                this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                1 / 3, 1 / 3);
        }


        const srcShader = srcImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);

        var tmp = this;
        var subWidth = imageWidth
        var subHeight = imageHeight;
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });
        var canvas = subSurface.getCanvas();
        canvas.clear(tmp.CK.Color(0, 0, 0, 0));

        console.log(":::"+"::"+srcShader+"::"+maskShader);
        var extractBackGoungShader =tmp.splitEffect.makeShaderWithChildren([layor], [srcShader, maskShader]);
        var extractForegroundShaderShaderIF=tmp.CK.ImageFilter.MakeShader(extractBackGoungShader);


        var paint = new tmp.CK.Paint();
        //这里对图像进行平滑操作
        paint.setColor(tmp.CK.Color(0, 0, 0, 0));
        paint.setImageFilter(extractForegroundShaderShaderIF);
        canvas.drawPaint(paint);
        extractForegroundShaderShaderIF.delete();
        extractBackGoungShader.delete();
        paint.delete();
        //取图像
        var backgroungImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));

        var bbytes=backgroungImage.encodeToBytes(tmp.CK.ImageFormat.PNG,100);
        var data1 = {
            layor: layor,//标识符
            image: backgroungImage,//分割原图像
            imageBytes:bbytes,//分割后的原图像对应的字节
            depthImage:null,//深度图像
            depthData:null,//深度图像数据
            normalImage:null,//法线贴图
            normalBytes:null,//发现图像对应字节数
            bounds: null,//边界
            effectImage:null, //融合灰度图和深度信息的最终图像
            effectBytes:null
        };

        //加载映射图
        canvas.clear(tmp.CK.Color(0, 0, 0, 0));
        if(maskShader!=null)
            maskShader.delete();
        subSurface.dispose();
        return data1;
    }

    //裁剪图像
    clipImageData(srcImage,clipRect)
    {

        const extractImgFormat = {
            alphaType: this.CK.AlphaType.Unpremul,
            colorType:  this.CK.ColorType.RGBA_8888,
            colorSpace:  this.CK.ColorSpace.SRGB,
            width:clipRect[2],
            height:clipRect[3]
        };

       var bytes = srcImage.readPixels(clipRect[0],clipRect[1],extractImgFormat,null,extractImgFormat.width*4);

       var clipImage=this.CK.MakeImage(extractImgFormat,bytes,extractImgFormat.width*4);

       //添加纯色背景
        var tmp=this;
        var subWidth=clipRect[2];
        var subHeight=clipRect[3];
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });

        var canvas = subSurface.getCanvas();
        canvas.clear(this.CK.Color4f(255,255,255,255));

        const oriImageFilter = tmp.CK.ImageFilter.MakeImage(clipImage, {filter: this.CK.FilterMode.Nearest,mipmap:this.CK.MipmapMode.Nearest });
        var paint=new tmp.CK.Paint();
        paint.setColor(tmp.CK.Color(255,255,255,255));
        paint.setImageFilter(oriImageFilter);
        canvas.drawPaint(paint);
        var normalImage= subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0,0,subWidth,subHeight));
        //读取字节
        oriImageFilter.delete();
        paint.delete();
        subSurface.delete();
        var bytes =normalImage.encodeToBytes(this.CK.ImageFormat.PNG, 100);

       return bytes;
    }



    merageImageData(dstImage,srcImage,imageWidth,imageHeight,clipRect)
    {
        var tmp = this;
        var subWidth = imageWidth
        var subHeight = imageHeight;
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });
        var canvas = subSurface.getCanvas();
        canvas.clear(tmp.CK.Color(0, 0, 0, 0));
        var paint = new tmp.CK.Paint();
        //这里对图像进行平滑操作
        paint.setColor(tmp.CK.Color(0, 0, 0, 0));
        canvas.drawImage(dstImage,0,0,null);
        canvas.drawImage(srcImage,clipRect[0],clipRect[1],null);
        paint.delete();
        //取图像
        var backgroungImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
        subSurface.dispose();
        return backgroungImage;
    };

    //与黑色图像进行融合
    merageBlackImageData(srcImageData,imageWidth,imageHeight,clipRect)
    {
        var srcImage=this.CK.MakeImageFromEncoded(srcImageData);
        var tmp = this;
        var subWidth = imageWidth
        var subHeight = imageHeight;
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });
        var canvas = subSurface.getCanvas();
        canvas.clear(tmp.CK.Color(255, 255, 255, 255));
        var paint = new tmp.CK.Paint();
        //这里对图像进行平滑操作
        paint.setColor(tmp.CK.Color(0, 0, 0, 0));
        canvas.drawImage(srcImage,clipRect[0],clipRect[1],null);
        paint.delete();
        //取图像
        var backgroungImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
        subSurface.dispose();
        return backgroungImage;
    }

    merageEffectImageData(greyImage,depthImage,maskImage,layor,imageWidth,imageHeight)
    {
        const depthShader = depthImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);
        const greyShader = greyImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);

        const maskShader = maskImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);

        var tmp = this;
        var subWidth = imageWidth
        var subHeight = imageHeight;
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });
        var canvas = subSurface.getCanvas();
        canvas.clear(tmp.CK.Color(0, 0, 0, 0));

        var extractBackGoungShader = tmp.splitForegroundImageEffect.makeShaderWithChildren([tmp.blendParameter,layor], [depthShader, greyShader,maskShader]);
        var paint = new tmp.CK.Paint();
        //这里对图像进行平滑操作
        const shaderIF = tmp.CK.ImageFilter.MakeShader(extractBackGoungShader);
        const colorImageFilter = tmp.CK.ColorFilter.MakeLinearToSRGBGamma();
        const imageIF = tmp.CK.ImageFilter.MakeColorFilter(colorImageFilter, shaderIF);
        const extractForegroundShaderShaderIF =tmp.CK.ImageFilter.MakeBlur(2,2,tmp.CK.TileMode.Decal,imageIF);
        paint.setColor(tmp.CK.Color(0, 0, 0, 0));
        paint.setImageFilter(extractForegroundShaderShaderIF);
        canvas.drawPaint(paint);
        extractForegroundShaderShaderIF.delete();
        imageIF.delete();
        shaderIF.delete();
        extractBackGoungShader.delete();
        paint.delete();
        //取图像
        var backgroungImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
        var bbytes=backgroungImage.encodeToBytes(tmp.CK.ImageFormat.PNG,100);
        canvas.clear(tmp.CK.Color(0, 0, 0, 0));
        depthShader.delete();
        greyShader.delete();
        colorImageFilter.delete();
        subSurface.dispose();

        return [backgroungImage,bbytes];
    }


    //根据分割出来的类型指定
    loadSingleEffectImageData(uuid,greyImage,depthImage,maskImage,normalImage,imageWidth,imageHeight,layor)
    {

        var maskShader= null;
        if( maskImage!=null)
            maskShader= maskImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);

        const depthShader = depthImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);
        const greyShader = greyImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);

        var tmp = this;
        var subWidth = imageWidth
        var subHeight = imageHeight;
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });
        var canvas = subSurface.getCanvas();
        canvas.clear(tmp.CK.Color(0, 0, 0, 0));

        var extractBackGoungShader = null;
        if(maskShader!=null) {
            extractBackGoungShader=tmp.splitForegroundImageEffect.makeShaderWithChildren([tmp.blendParameter,layor], [depthShader, greyShader,maskShader]);
        }else {
            extractBackGoungShader=tmp.splitBackgroundImageNoMaskEffect.makeShaderWithChildren([tmp.blendParameter], [depthShader, greyShader]);
        }
        var paint = new tmp.CK.Paint();
        //这里对图像进行平滑操作
        const shaderIF = tmp.CK.ImageFilter.MakeShader(extractBackGoungShader);
        const colorImageFilter = tmp.CK.ColorFilter.MakeLinearToSRGBGamma();
        const imageIF = tmp.CK.ImageFilter.MakeColorFilter(colorImageFilter, shaderIF);
        const extractForegroundShaderShaderIF =tmp.CK.ImageFilter.MakeBlur(2,2,tmp.CK.TileMode.Decal,imageIF);
        paint.setColor(tmp.CK.Color(0, 0, 0, 0));
        paint.setImageFilter(extractForegroundShaderShaderIF);
        canvas.drawPaint(paint);
        extractForegroundShaderShaderIF.delete();
        imageIF.delete();
        shaderIF.delete();
        extractBackGoungShader.delete();
        paint.delete();
        //取图像
        var backgroungImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
        var backBounds = [{x: 0, y: 0}, {x: imageWidth, y: 0}, {x: imageWidth, y: imageHeight}, {
            x: 0,
            y: imageHeight
        }];
        var bbytes=backgroungImage.encodeToBytes(tmp.CK.ImageFormat.PNG,100);
        var data1 = {
            layor: layor,
            images: backgroungImage,
            showImage:bbytes,
            normalImage:normalImage,
            bounds: backBounds
        };

        //加载映射图
        console.log("pppppppppppppppppppppp::"+layor);





        canvas.clear(tmp.CK.Color(0, 0, 0, 0));
        depthShader.delete();
        greyShader.delete();
        if(maskShader!=null)
           maskShader.delete();
        colorImageFilter.delete();
        subSurface.dispose();



        return data1;
    }


    mattingSingleEffectImageData(uuid,srcBytes,maskBytes,imageWidth,imageHeight)
    {

        const srcImage = this.CK.MakeImageFromEncoded(srcBytes);
        if(srcImage==null)
            return null;

        const maskImage = this.CK.MakeImageFromEncoded(maskBytes);
        if(maskImage==null)
            return null;

        const maskShader= maskImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);

        const srcShader = srcImage.makeShaderCubic(
            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1 / 3, 1 / 3);
        var tmp = this;
        var subWidth = imageWidth
        var subHeight = imageHeight;
        var subSurface = this.surface.makeSurface({
            width: subWidth,
            height: subHeight,
            alphaType: tmp.CK.AlphaType.Premul,
            colorType: tmp.CK.ColorType.RGBA_8888,
            colorSpace: tmp.CK.ColorSpace.SRGB
        });
        var canvas = subSurface.getCanvas();
        canvas.clear(tmp.CK.Color(0, 0, 0, 0));

        var extractBackGoungShader=tmp.splitForegroundImageNoMaskEffect.makeShaderWithChildren([], [srcShader,maskShader]);
        var paint = new tmp.CK.Paint();
        //这里对图像进行平滑操作
        const shaderIF = tmp.CK.ImageFilter.MakeShader(extractBackGoungShader);
        paint.setColor(tmp.CK.Color(0, 0, 0, 0));
        paint.setImageFilter(shaderIF);
        canvas.drawPaint(paint);
        shaderIF.delete();
        extractBackGoungShader.delete();
        paint.delete();
        //取图像
        var backgroungImage = subSurface.makeImageSnapshot(new tmp.CK.XYWHiRect(0, 0, imageWidth, imageHeight));
        var bbytes=backgroungImage.encodeToBytes(tmp.CK.ImageFormat.PNG,100);
        canvas.clear(tmp.CK.Color(0, 0, 0, 0));
        srcShader.delete();
        maskShader.delete();
        subSurface.dispose();
        return bbytes;
    }




    //对深度图，灰度图，mask图三张图进行预处理，得到最终的合成图，最后返回shader
    loadEffectImageData(uuid,greyData,depthData,maskData,normalData,imageWidth,imageHeight,alphaBThreload,callBack)
    {
        const greyImage = this.CK.MakeImageFromEncoded(greyData);

        if(greyImage==null)
        {
            if(callBack!=null)
            {
                callBack(-1, "灰度图为空");
            }
            return;
        }

        const depthImage = this.CK.MakeImageFromEncoded(depthData);
        if(depthImage==null)
        {
            if(callBack!=null)
            {
                callBack(-1, "深度图为空");
            }
            return;
        }

        const normalImage = this.CK.MakeImageFromEncoded(normalData);
        if(normalImage==null)
        {
            if(callBack!=null)
            {
                callBack(-1, "法线图为空");
            }
            return;
        }
        //分割图像逻辑
        const maskImage = this.CK.MakeImageFromEncoded(maskData);
        var background_images=[];
        if(maskImage==null)
        {
            //没有遮罩数据则图片不进行分割

            var effect = this.loadSingleEffectImageData(uuid,greyImage,depthImage,null,normalImage,imageWidth,imageHeight,0);
            background_images.push(effect);
            this.maxSplitCount=1;
            if (callBack != null) {
                callBack(0, {uuid: uuid, maps: background_images});
            }

        }else {


            var effect_backgrounf = this.loadSingleEffectImageData(uuid,greyImage,depthImage,maskImage,normalImage,imageWidth,imageHeight,-1);
            background_images.push(effect_backgrounf);

            var effect_foregrounf = this.loadSingleEffectImageData(uuid,greyImage,depthImage,maskImage,normalImage,imageWidth,imageHeight,0);
            background_images.push(effect_foregrounf);

            if (callBack != null) {
                callBack(0, {uuid: uuid, maps: background_images});
            }
        }
    }

    // generateBackgroundShaderFilter(backgroundImages)
    // {
    //     var backgroundIF=null;
    //     for(var i=0;i<backgroundImages.length;i++)
    //     {
    //         var backgroundImage=backgroundImages[i];
    //
    //         const bow=Math.ceil(backgroundImage.oriWidth);
    //         const boh=Math.ceil(backgroundImage.oriHeight);
    //         const bdx=Math.ceil(backgroundImage.dstx);
    //         const bdy=Math.ceil(backgroundImage.dsty);
    //         const bdw=Math.ceil(backgroundImage.dstWidth);
    //         const bdh=Math.ceil(backgroundImage.dstHeight);
    //         const bsx=bdw/bow;
    //         const bsy=bdh/boh;
    //         const btranslateM3=this.CK.Matrix.translated(bdx,bdy);
    //         const bangel=Math.PI/180*backgroundImage.dstAngel;
    //         const bscaleM3 =this.CK.Matrix.scaled(bsx,bsy,0,0);
    //
    //         var bctx=Math.ceil(bdw/2);
    //         var bcty=Math.ceil(bdh/2);
    //         const brotationM3=this.CK.Matrix.rotated(bangel,bctx,bcty);
    //         //矩阵是是从右往左依次执行，先缩放，后旋转，在平移
    //         const btotalM3=this.CK.Matrix.multiply(btranslateM3,brotationM3,bscaleM3);
    //         const imageMatrixFilter = this.CK.ImageFilter.MakeMatrixTransform(btotalM3,
    //             { B: 0.5, C: 0.5 }, null);
    //
    //         const background_Image=this.CK.MakeImageFromEncoded(backgroundImage.srcData);
    //         if(background_Image==null)
    //         {
    //             // const imageIF =this.generateColorImageFilter(backgroundImage.colors,bdx,bdy,bdw,bdh,backgroundImage.dstAngel);
    //             // backgroundIF = this.CK.ImageFilter.MakeBlend(this.CK.BlendMode.SrcOver, backgroundIF, imageIF);
    //             // imageIF.delete();
    //
    //         }else {
    //
    //             const backImageShader= background_Image.makeShaderCubic(
    //                 this.CK.TileMode.Decal, this.CK.TileMode.Decal,
    //                 1/3, 1/3,btotalM3);
    //             const backImageShader1 =this.splitsTest.makeShaderWithChildren([0.1],[backImageShader]);
    //          if(backgroundIF==null)
    //          {  backgroundIF=backImageShader1}
    //          else
    //          {
    //              backgroundIF=this.splitsTest.makeShaderWithChildren([0.1],[backImageShader1]);
    //          }
    //         }
    //     }
    //
    //     return backgroundIF;
    // }


    processImagesToShader(vectorImages,backgroundImages)
    {
        var shaders=[];
        var backShaders=this.generateBackgroundShaders(backgroundImages);
        var vectorShaders=this.generateVectorShader(vectorImages,backgroundImages);

        var shadBack=this.defaultEffect.makeShaderWithChildren([],[]);
        if(backShaders.length>0) {
            shadBack = this.makeBlendShadesSrcOver(backShaders);
        }

        var shadVector=this.defaultEffect.makeShaderWithChildren([],[]);
        if(backShaders.length>0) {
            shadVector = this.makeBlendShadesSrcOver(vectorShaders);
        }

        return vectorShaders[0];
    }

    updataDrawCanvas(shader,callBack)
    {
        var tmp=this;
        this.canvas.clear(this.CK.Color4f(0,0,0,0));
        tmp.surface.requestAnimationFrame(function (canvas) {
            var dateObj3 = new Date();
            var mmms3=dateObj3.getMilliseconds();
            const paint=new tmp.CK.Paint();
            paint.setAntiAlias(true);
            paint.setShader(shader);
            tmp.canvas.drawPaint(paint);
            shader.delete();
            paint.delete();
            var dateObj2 = new Date();
            //console.log("绘制一帧的耗时为："+(dateObj2.getMilliseconds()-mmms3)+"::");
            if(callBack!=null)
                callBack();
        });
    }

    //更新绘制
    updateDraw(vectorImages,backgroundImages)
    {
        var dateObj = new Date();
        var mmms=dateObj.getMilliseconds();
        this.canvas.clear(this.CK.Color4f(0,0,0,0));
        var tmp=this;

        var shaders=[];
        var backShaders=this.generateBackgroundShaders(backgroundImages);
        var vectorShaders=this.generateVectorShader(vectorImages,backgroundImages);
        shaders=backShaders.concat(vectorShaders);
        //console.log("当前shader的总数为："+backShaders.length+":::"+shaders.length);
        var shad=this.defaultEffect.makeShaderWithChildren([],[]);

        if(shaders.length>0) {
           shad = this.makeBlendShadesSrcOver(shaders);
        }

        //测试代码
        tmp.surface.requestAnimationFrame(function (canvas) {
            var dateObj3 = new Date();
            var mmms3=dateObj3.getMilliseconds();
            const paint=new tmp.CK.Paint();
            paint.setAntiAlias(true);
            paint.setShader(shad);
            tmp.canvas.drawPaint(paint);
            var dateObj2 = new Date();
            //console.log("绘制一帧的耗时为："+(dateObj2.getMilliseconds()-mmms3)+"::");
        });
    }

    //将初始数据转换成image材质


    //将初始数据转换成image材质
    transformSrcDataToImage(srcData)
    {
        return this.CK.MakeImageFromEncoded(srcData);
    }

    transformRGBADataToImagePngData(oriData,width,height)
    {
            const colorTransformImgFormat = {
                alphaType: this.CK.AlphaType.Unpremul,
                colorType:  this.CK.ColorType.RGBA_8888,
                colorSpace:  this.CK.ColorSpace.SRGB,
                width:width,
                height:height
            };
            const image=this.CK.MakeImage(colorTransformImgFormat,oriData,colorTransformImgFormat.width*4);

           return image.encodeToBytes(this.CK.ImageFormat.PNG,100);
    }

    transformRGBADataToImage(oriData,width,height)
    {
        const colorTransformImgFormat = {
            alphaType: this.CK.AlphaType.Unpremul,
            colorType:  this.CK.ColorType.RGBA_8888,
            colorSpace:  this.CK.ColorSpace.SRGB,
            width:width,
            height:height
        };
        const image=this.CK.MakeImage(colorTransformImgFormat,oriData,colorTransformImgFormat.width*4);
        return image;
    }

    transformRGBAFloat32DataToImage(oriData,width,height)
    {
        const colorTransformImgFormat = {
            alphaType: this.CK.AlphaType.Unpremul,
            colorType:  this.CK.ColorType.RGBA_F32,
            colorSpace:  this.CK.ColorSpace.SRGB,
            width:width,
            height:height
        };
        console.log("gggggggggggggggg::"+width+"::"+height+"::"+oriData);
        const image=this.CK.MakeImage(colorTransformImgFormat,oriData,colorTransformImgFormat.width*8);
        console.log("gggggggggggggggg::"+width+"::"+height+"");
        return image;
    }


    extractOriDataFormImage(image,width,height)
    {
        const extractImgFormat = {
                    alphaType: this.CK.AlphaType.Unpremul,
                    colorType:  this.CK.ColorType.RGBA_8888,
                    colorSpace:  this.CK.ColorSpace.SRGB,
                    width:width,
                    height:height
                };

        var bytes =image.readPixels(0, 0, extractImgFormat, null, width * 4);
        return bytes;
    }

    extractPngDataFormImage(image)
    {
        console.log("dadddddddddddddd::"+image);
        return image.encodeToBytes(this.CK.ImageFormat.PNG,100);
    }

    // drawExrData(srcData)
    // {
    //     const { data, width, height } = parseExr(srcData, 1015);
    //     const color32ImgFormat = {
    //         alphaType: this.CK.AlphaType.Unpremul,
    //         colorType:  this.CK.ColorType.RGBA_F32,
    //         colorSpace:  this.CK.ColorSpace.SRGB,
    //         width:1075,
    //         height:1433
    //     };
    //     const image=this.CK.MakeImage(color32ImgFormat,data,color32ImgFormat.width);
    //     console.log("当前绘制字节"+image+"::"+data);
    //     this.canvas.clear(this.CK.Color4f(0,0,0,0));
    //     var tmp=this;
    //     const imageFilter = this.CK.ImageFilter.MakeImage(image,{filter: this.CK.FilterMode.Nearest,mipmap:this.CK.MipmapMode.Nearest });
    //     tmp.surface.requestAnimationFrame(function (canvas) {
    //         const paint1=new tmp.CK.Paint();
    //         paint1.setImageFilter(imageFilter);
    //         tmp.canvas.drawPaint(paint1);
    //         paint1.delete();
    //     });
    // }

    updateDrawCallBack(vectorImages,backgroundImages,callBack)
    {
        var dateObj = new Date();
     //   var mmms=dateObj.getMilliseconds();
        this.canvas.clear(this.CK.Color4f(0,0,0,0));
        var tmp=this;

        var shaders=[];
        var backShaders=this.generateBackgroundShaders(backgroundImages);
        var vectorShaders=this.generateVectorShader(vectorImages,backgroundImages);
        var shadBack=this.defaultEffect.makeShaderWithChildren([],[]);
        if(backShaders.length>0) {
            shadBack = this.makeBlendShadesSrcOver(backShaders);
        }

        var shadVector=this.defaultEffect.makeShaderWithChildren([],[]);
        if(vectorShaders.length>0) {
            shadVector = this.makeBlendShadesSrcOver(vectorShaders);
        }



        tmp.surface.requestAnimationFrame(function (canvas) {
      //      var dateObj3 = new Date();
      //      var mmms3=dateObj3.getMilliseconds();
            const paint1=new tmp.CK.Paint();
            paint1.setShader(shadBack);
            tmp.canvas.drawPaint(paint1);
            shadBack.delete();
            paint1.delete();

            const paint=new tmp.CK.Paint();
            paint.setShader(shadVector);
            tmp.canvas.drawPaint(paint);
            shadVector.delete();
            paint.delete();
            if(callBack!=null)
                callBack();
        });


    }

    //更新绘制
    // updateDraw(vectorImages,backgroundImages)
    // {
    //     var dateObj = new Date();
    //     var mmms=dateObj.getMilliseconds();
    //     this.canvas.clear(this.CK.Color4f(0,0,0,0));
    //     var tmp=this;
    //
    //
    //     var sksl="";
    //     var uniformss="";
    //     var preocessss="";
    //     var shaders=[];
    //     for(var i=0;i<backgroundImages.length;i++)
    //     {
    //         if(backgroundImages[i].srcData!=null&&backgroundImages[i].srcData.length>0) {
    //
    //             var backgroundImage=backgroundImages[i];
    //             const bow=Math.ceil(backgroundImage.oriWidth);
    //             const boh=Math.ceil(backgroundImage.oriHeight);
    //             const bdx=Math.ceil(backgroundImage.dstx);
    //             const bdy=Math.ceil(backgroundImage.dsty);
    //             const bdw=Math.ceil(backgroundImage.dstWidth);
    //             const bdh=Math.ceil(backgroundImage.dstHeight);
    //             const bsx=bdw/bow;
    //             const bsy=bdh/boh;
    //             const btranslateM3=tmp.CK.Matrix.translated(bdx,bdy);
    //             const bangel=Math.PI/180*backgroundImage.dstAngel;
    //             const bscaleM3 =tmp.CK.Matrix.scaled(bsx,bsy,0,0);
    //
    //             var bctx=Math.ceil(bdw/2);
    //             var bcty=Math.ceil(bdh/2);
    //             const brotationM3=tmp.CK.Matrix.rotated(bangel,bctx,bcty);
    //             //矩阵是是从右往左依次执行，先缩放，后旋转，在平移
    //             const btotalM3=tmp.CK.Matrix.multiply(btranslateM3,brotationM3,bscaleM3);
    //             const background_Image=tmp.CK.MakeImageFromEncoded(backgroundImage.srcData);
    //             var backImageShader = background_Image.makeShaderCubic(
    //                 tmp.CK.TileMode.Decal, tmp.CK.TileMode.Decal,
    //                 1/3, 1/3,btotalM3);
    //             shaders.push(backImageShader);
    //
    //            // shaders.push(backImageShader);
    //             var text = "uniform shader image" + i + ";";
    //             var process = "";
    //             if (i == 0)
    //                 process = " if(image" + i + ".eval(xy).r>0) {return image" + i + ".eval(xy).rgba;}";
    //             else
    //                 process = " if(image" + i + ".eval(xy).r>0) {return image" + i + ".eval(xy).rgba;}";
    //             uniformss += text + "\r\n";
    //             preocessss += process + "\r\n";
    //         }
    //     }
    //
    //
    //     preocessss+="return half4(0,0,0,0);";
    //
    //
    //     uniformss+="uniform int type;"+"\r\n";
    //
    //     sksl = uniformss + "\r\n  half4 main(float2 xy) {" + "\r\n" + preocessss + "}";
    //
    //     //console.log(sksl);
    //
    //     const tmpEffect = tmp.CK.RuntimeEffect.Make(sksl);
    //
    //
    //     var shad=null;
    //     if(shaders.length>0) {
    //         shad = tmpEffect.makeShaderWithChildren([1], shaders);
    //     }else
    //     {
    //         shad = tmpEffect.makeShaderWithChildren([1], []);
    //     }
    //
    //     tmp.surface.requestAnimationFrame(function (canvas) {
    //         var dateObj3 = new Date();
    //         var mmms3=dateObj3.getMilliseconds();
    //         const paint=new tmp.CK.Paint();
    //         paint.setShader(shad);
    //         tmp.canvas.drawPaint(paint);
    //         var dateObj2 = new Date();
    //         //console.log("绘制一帧的耗时为："+(dateObj2.getMilliseconds()-mmms3)+"::");
    //     });
    //
    //
    // }

    //产生绘制纯色的图片过滤器
    generateColorImageFilter(colors,dstx,dsty,dstWidth,dstHeight,dstAngel)
    {
        var bytes=[];
        for(var i=0;i<this.colorImgFormat.width*this.colorImgFormat.height;i++)
        {
            bytes.push(colors[0]);
            bytes.push(colors[1]);
            bytes.push(colors[2]);
            bytes.push(colors[3]);
        }
        const color_image=this.CK.MakeImage(this.colorImgFormat,bytes,this.colorImgFormat.width*4);

        const ow=Math.ceil(this.colorImgFormat.width);
        const oh=Math.ceil(this.colorImgFormat.height);
        const dx=Math.ceil(dstx);
        const dy=Math.ceil(dsty);
        const dw=Math.ceil(dstWidth);
        const dh=Math.ceil(dstHeight);
        const sx=dw/ow;
        const sy=dh/oh;
        const translateM3=this.CK.Matrix.translated(dx,dy);
        const angel=Math.PI/180*dstAngel;
        const scaleM3 =this.CK.Matrix.scaled(sx,sy,0,0);

        var ctx=Math.ceil(dstWidth/2);
        var cty=Math.ceil(dstHeight/2);
        const rotationM3=this.CK.Matrix.rotated(angel,ctx,cty);

        //矩阵是是从右往左依次执行，先缩放，后旋转，在平移
        const totalM3=this.CK.Matrix.multiply(translateM3,rotationM3,scaleM3);

        const imageShader=color_image.makeShaderOptions(this.CK.TileMode.Decal, this.CK.TileMode.Decal,
            1/3, 1/3,totalM3);

        //console.log("当前纯色背景："+imageShader);
       return imageShader;
    }

    generateVectorShader(vectorImages,backgroundImages)
    {
        //先绘制向量图
        //第一步，确定每个向量图所在的区域
        //处理向量图
        var vectorShaders=[];
        for(var i=vectorImages.length-1;i>=0;i--)
        {
            var vectorImage = vectorImages[i];
            //得到向量图的中心点
            var x=vectorImage.dstx+vectorImage.dstWidth/2;
            var y=vectorImage.dsty+vectorImage.dstHeight/2;

            var currentBackgroundImage=null;
            //根据中心点去选择在哪个区域
            for(var j=0;j<backgroundImages.length;j++)
            {
                var backgroundImage = backgroundImages[j];
                var rect=[[backgroundImage.dstx,backgroundImage.dsty],
                    [backgroundImage.dstx+backgroundImage.dstWidth,backgroundImage.dsty],
                    [backgroundImage.dstx+backgroundImage.dstWidth,backgroundImage.dsty+backgroundImage.dstHeight],
                    [backgroundImage.dstx,backgroundImage.dsty+backgroundImage.dstHeight]
                ];

                //将点进行旋转
                var rotation_angel=-(backgroundImage.dstAngel/180)*Math.PI;
                var point = this.rotatePoint([x,y],backgroundImage.dstWidth,backgroundImage.dstHeight,rotation_angel);

                if(this.isPointInsideShape([point[0],point[1]],rect))
                {
                    //console.log("已经定义到具体的图像");
                    currentBackgroundImage=backgroundImage;

                    break;
                }
            }

            if(currentBackgroundImage==null)
            {
                vectorImage.shapeOffset=0;
                //没有任何效果的绘制,只做透明化操作
                const vector_Image=vectorImage.drawImageData;
                const vow=Math.ceil(vectorImage.oriWidth);
                const voh=Math.ceil(vectorImage.oriHeight);
                const vdx=Math.ceil(vectorImage.dstx);
                const vdy=Math.ceil(vectorImage.dsty);
                const vdw=Math.ceil(vectorImage.dstWidth);
                const vdh=Math.ceil(vectorImage.dstHeight);
                const vsx=vdw/vow;
                const vsy=vdh/voh;
                const vtranslateM3=this.CK.Matrix.translated(vdx,vdy);
                const vangel=Math.PI/180*vectorImage.dstAngel;
                const vscaleM3 =this.CK.Matrix.scaled(vsx,vsy,0,0);

                var vctx=Math.ceil(vdw/2);
                var vcty=Math.ceil(vdh/2);
                const vrotationM3=this.CK.Matrix.rotated(vangel,vctx,vcty);
                //矩阵是是从右往左依次执行，先缩放，后旋转，在平移
                const vtotalM3=this.CK.Matrix.multiply(vtranslateM3,vrotationM3,vscaleM3);
                //应用旋转、平移、缩放到vectorImage

                if(vector_Image==null)
                {
                    const vectorShader =this.generateColorImageFilter(vectorImage.colors,vdx,vdy,vdw,vdh,vectorImage.dstAngel);
                    vectorShaders.push(vectorShader);
                }else
                {
                    const vectorImageShader= vector_Image.makeShaderOptions(this.CK.TileMode.Decal,this.CK.TileMode.Decal,this.CK.FilterMode.Nearest,this.CK.MipmapMode.Linear,vtotalM3);
                    const alphaR=vectorImage.alphaVectorColor[0];
                    const alphaG=vectorImage.alphaVectorColor[1];
                    const alphaB=vectorImage.alphaVectorColor[2];
                    const vectorShader=this.alphaEffect.makeShaderWithChildren([alphaR,alphaG,alphaB],[vectorImageShader]);
                    vectorShaders.push(vectorShader);
                }
            }else
            {
                //更近一步查找到具体区域
                //将中心点进行方向旋转、缩放和平移
                vectorImage.shapeOffset=0;
                var angel=-(currentBackgroundImage.dstAngel/180)*Math.PI;
                var dstx=currentBackgroundImage.dstx;
                var dsty=currentBackgroundImage.dsty;
                var dstWidth=currentBackgroundImage.dstWidth;
                var dstHeight=currentBackgroundImage.dstHeight;
                var oriWidth=currentBackgroundImage.oriWidth;
                var oriHeight=currentBackgroundImage.oriHeight;
                var scaleX=oriWidth/currentBackgroundImage.dstWidth;
                var scaleY=oriHeight/currentBackgroundImage.dstHeight;
                var point = this.transformPoint([x,y],[dstx,dsty],angel,scaleX,scaleY,-dstx,-dsty,dstWidth,dstHeight);
             //   var point_left_top = this.transformPoint([x-vectorImage.dstWidth/2-vectorImage.scaleEffectX/2,y-vectorImage.dstHeight/2-vectorImage.scaleEffectY/2],[dstx,dsty],angel,scaleX,scaleY,-dstx,-dsty,dstWidth,dstHeight);
          //      var point_right_top = this.transformPoint([x+vectorImage.dstWidth/2+vectorImage.scaleEffectX,y-vectorImage.dstHeight/2-vectorImage.scaleEffectY],[dstx,dsty],angel,scaleX,scaleY,-dstx,-dsty,dstWidth,dstHeight);
            //    var point_left_bottom = this.transformPoint([x-vectorImage.dstWidth/2-vectorImage.scaleEffectX/2,y+vectorImage.dstHeight/2+vectorImage.scaleEffectY/2],[dstx,dsty],angel,scaleX,scaleY,-dstx,-dsty,dstWidth,dstHeight);
            //    var point_right_bottom = this.transformPoint([x+vectorImage.dstWidth/2+vectorImage.scaleEffectX,y+vectorImage.dstHeight/2+vectorImage.scaleEffectY],[dstx,dsty],angel,scaleX,scaleY,-dstx,-dsty,dstWidth,dstHeight);
                //整合shader
                //现将效果图进行整合
                const ow=Math.ceil(oriWidth);
                const oh=Math.ceil(oriHeight);
                const dx=Math.ceil(dstx);
                const dy=Math.ceil(dsty);
                const dw=Math.ceil(dstWidth);
                const dh=Math.ceil(dstHeight);
                const sx=dw/ow;
                const sy=dh/oh;
                const translateM3=this.CK.Matrix.translated(dx,dy);
                const scaleM3 =this.CK.Matrix.scaled(sx,sy,0,0);
                var ctx=Math.ceil(dstWidth/2);
                var cty=Math.ceil(dstHeight/2);
                const rotationM3=this.CK.Matrix.rotated(-angel,ctx,cty);
                //矩阵是是从右往左依次执行，先缩放，后旋转，在平移
                const totalM3=this.CK.Matrix.multiply(translateM3,rotationM3,scaleM3);

                //同时将向量图进行shader化
                const vector_Image=vectorImage.drawImageData;

                const vow=Math.ceil(vectorImage.oriWidth);
                const voh=Math.ceil(vectorImage.oriHeight);
                const vdx=Math.ceil(vectorImage.dstx);
                const vdy=Math.ceil(vectorImage.dsty);
                const vdw=Math.ceil(vectorImage.dstWidth);
                const vdh=Math.ceil(vectorImage.dstHeight);
                const vsx=vdw/vow;
                const vsy=vdh/voh;
                const vtranslateM3=this.CK.Matrix.translated(vdx,vdy);
                const vangel=Math.PI/180*vectorImage.dstAngel;
                const vscaleM3 =this.CK.Matrix.scaled(vsx,vsy,0,0);

                var vctx=Math.ceil(vdw/2);
                var vcty=Math.ceil(vdh/2);
                const vrotationM3=this.CK.Matrix.rotated(vangel,vctx,vcty);
                //矩阵是是从右往左依次执行，先缩放，后旋转，在平移
                const vtotalM3=this.CK.Matrix.multiply(vtranslateM3,vrotationM3,vscaleM3);

                if(vector_Image==null)
                {
                    const vectorShader =this.generateColorImageFilter(vectorImage.colors,vdx,vdy,vdw,vdh,vectorImage.dstAngel);
                    vectorShaders.push(vectorShader);
                }else {

                    //console.log("进入了融合向量图");
                    //应用旋转、平移、缩放到vectorImage
                    // const vectorImageShader = vector_Image.makeShaderCubic(
                    //     this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                    //     1 / 3, 1 / 3, vtotalM3);

                    const vectorImageShader=vector_Image.makeShaderOptions(this.CK.TileMode.Decal,this.CK.TileMode.Decal,this.CK.FilterMode.Nearest,this.CK.MipmapMode.Linear,vtotalM3);
                    var effectImages=currentBackgroundImage.effectImages;
                    if(effectImages==null||effectImages.length==0) {


                        //否则按图像原来的样子进行绘制
                        const alphaR=vectorImage.alphaVectorColor[0];
                        const alphaG=vectorImage.alphaVectorColor[1];
                        const alphaB=vectorImage.alphaVectorColor[2];
                        const vectorShader=this.alphaEffect.makeShaderWithChildren([alphaR,alphaG,alphaB],[vectorImageShader]);
                        vectorShaders.push(vectorShader);


                    }else
                    {

                        var currentArea =effectImages[0];
                        for (var k = 1; k < effectImages.length; k++) {
                            var bytes = effectImages[k].image.readPixels(point[0], point[1], this.imgFormat, null, oriWidth * 4);
                            if (bytes!=null&&bytes.length > 3 && bytes[3] != 0) {
                                //代表选中了改区域
                                //console.log("代表选中了改区域::");
                                currentArea = effectImages[k];
                                break;
                            }
                        }
                        //应用旋转、平移、缩放到effectImage
                        const layor = currentArea.layor;
                        const effectShader = currentArea.effectImage.makeShaderCubic(
                            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                            1 / 3, 1 / 3, totalM3);
                        var mapEraseImage=currentBackgroundImage.eraseDrawImageData;

                        if(mapEraseImage==null)
                        {
                           //console.log("擦除数据为空，选择默认擦除面板");
                            mapEraseImage=this.defaultEraseImage;

                        }

                        const mapEraseShader=mapEraseImage.makeShaderCubic(
                        this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                        1/3, 1/3,totalM3);


                        var vectorEraseImage =vectorImages.eraseDrawImageData;
                        if(vectorEraseImage==null)
                        {
                           //console.log("擦除数据为空，选择默认擦除面板");
                            vectorEraseImage=this.defaultEraseImage;
                        }

                        if(layor!=-1) {
                            vectorImage.shapeOffset = 1;
                        }

                        //获取边界四个点的像素值
                        var director=1;

                     //   var pixel_left_top =currentArea.images.readPixels(point_left_top[0], point_left_top[1], this.imgFormat, null, oriWidth * 4);
                      //  var pixel_right_top =currentArea.images.readPixels(point_right_top[0], point_right_top[1], this.imgFormat, null, oriWidth * 4);
                       // var pixel_left_bottom = currentArea.images.readPixels(point_left_bottom[0], point_left_bottom[1], this.imgFormat, null, oriWidth * 4);
                   //     var pixel_right_bottom = currentArea.images.readPixels(point_right_bottom[0], point_right_bottom[1], this.imgFormat, null, oriWidth * 4);
                        const effectX = vectorImage.scaleEffectX;
                        const effectY = vectorImage.scaleEffectY;

                        const vectorEraseShader=vectorEraseImage.makeShaderCubic(
                            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                            1/3, 1/3,vtotalM3);

                        const lightShader=currentBackgroundImage.drawImageData.makeShaderCubic(
                            this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                            1/3, 1/3,totalM3);

                        const normalShader=currentArea.normalImage.makeShaderCubic(  this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                            1, 1,totalM3);


                        const alphaR = vectorImage.alphaVectorColor[0];
                        const alphaG = vectorImage.alphaVectorColor[1];
                        const alphaB = vectorImage.alphaVectorColor[2];
                        const vectorShader = this.vectorImageEffect.makeShaderWithChildren([layor, effectX, effectY, alphaR, alphaG, alphaB,vsx,vsy,this.vectorAlpha,vectorImage.lightAverage,vectorImage.dstx,vectorImage.dsty,vectorImage.dstWidth,vectorImage.dstHeight,currentBackgroundImage.dstx,currentBackgroundImage.dsty,currentBackgroundImage.dstWidth,currentBackgroundImage.dstHeight], [vectorImageShader,effectShader,mapEraseShader,vectorEraseShader,lightShader,normalShader]);
                        vectorShaders.push(vectorShader);
                    }
                }
            }


        }
        return vectorShaders;
    }


    makeBlendShadesSrcOver(shaders)
    {
        var sksl="";
        var uniformss="";
        var preocessss="";
        for(var i=0;i<shaders.length;i++)
        {
            var text = "uniform shader image" + i + ";";
            var process = "";

            process +=" half4 color"+i+" = "+"image" + i + ".eval(xy);"+"\r\n";
            process += " if(color" + i + ".a>0) {return color" + i + ";}";
            uniformss += text + "\r\n";
            preocessss += process + "\r\n";
        }
        preocessss+="return half4(0,0,0,0);";
        sksl = uniformss + "\r\n  half4 main(float2 xy) {" + "\r\n" + preocessss + "}";
        const tmpEffect = this.CK.RuntimeEffect.Make(sksl);

       //console.log("文件信息为："+sksl);

        const shader=tmpEffect.makeShaderWithChildren([],shaders);
        return shader;
    }

    //更新融合绘制
    generateBackgroundShaders(backgroundImages)
    {
        //处理背景图
        var imageShaders=[];
        for(var i=backgroundImages.length-1;i>=0;i--)
        {
            var backgroundImage=backgroundImages[i];
            const bow=Math.ceil(backgroundImage.oriWidth);
            const boh=Math.ceil(backgroundImage.oriHeight);
            const bdx=Math.ceil(backgroundImage.dstx);
            const bdy=Math.ceil(backgroundImage.dsty);
            const bdw=Math.ceil(backgroundImage.dstWidth);
            const bdh=Math.ceil(backgroundImage.dstHeight);
            const bsx=bdw/bow;
            const bsy=bdh/boh;
            const btranslateM3=this.CK.Matrix.translated(bdx,bdy);
            const bangel=Math.PI/180*backgroundImage.dstAngel;
            const bscaleM3 =this.CK.Matrix.scaled(bsx,bsy,0,0);

            var bctx=Math.ceil(bdw/2);
            var bcty=Math.ceil(bdh/2);
            const brotationM3=this.CK.Matrix.rotated(bangel,bctx,bcty);
            //矩阵是是从右往左依次执行，先缩放，后旋转，在平移
            const btotalM3=this.CK.Matrix.multiply(btranslateM3,brotationM3,bscaleM3);
            const background_Image=backgroundImage.drawImageData;
            if(background_Image==null)
            {
                //console.log("进入了纯色背景逻辑");
                const imageShader =this.generateColorImageFilter(backgroundImage.colors,bdx,bdy,bdw,bdh,backgroundImage.dstAngel);
                imageShaders.push(imageShader);

            }else {
                const imageShader=background_Image.makeShaderCubic(this.CK.TileMode.Decal, this.CK.TileMode.Decal,
                    1/3, 1/3,btotalM3);
                imageShaders.push(imageShader);
            }
        }

        return imageShaders;
    }

    isPointInsideShape(Point, triangle)
    {
        var nCross = 0;
        var nCount = triangle.length;
        var p = Point;
        for (var i = 0; i < nCount; i++)
        {
            var p1 = triangle[i];
            var p2 = triangle[(i + 1) % nCount];
            if (p1[1] == p2[1])
            {
                continue;
            }
            if (p[1] < Math.min(p1[1], p2[1]))
                continue;
            if (p[1] >= Math.max(p1[1], p2[1]))
                continue;
            var x = (p[1] - p1[1]) * (p2[0] - p1[0]) / (p2[1] - p1[1]) + p1[0];
            if (x > p[0])
                nCross = nCross + 1;
        }
        return (nCross % 2 == 1);
    }

    //绕点旋转,只针对于没有发生任何平移和旋转,即图像位于（0,0）的位置才可以

    rotatePoint(point,dstw,dsth,rotation)
    {
        var x1 = point[0];
        var y1 = point[1];

        var cos = Math.cos(rotation);
        var sin = Math.sin(rotation);
        var cx=dstw/2;
        var cy=dsth/2;
        var xoffset=x1-cx;
        var yoffset=y1-cy;
        var x4 = xoffset * cos - yoffset * sin;
        var y4 = xoffset * sin + yoffset * cos;

        var x6=x4+cx;
        var y6=y4+cy;
        return [x6,y6];
    }

    //旋转一个点，包含任意平移，但不包含缩放
    rotatePoint1(point,dx,dy,dstw,dsth,rotation)
    {
        var x1 = point[0]-dx;
        var y1 = point[1]-dy;

        var cos = Math.cos(rotation);
        var sin = Math.sin(rotation);
        var cx=dstw/2;
        var cy=dsth/2;
        var xoffset=x1-cx;
        var yoffset=y1-cy;
        var x4 = xoffset * cos - yoffset * sin;
        var y4 = xoffset * sin + yoffset * cos;

        var x6=x4+cx+dx;
        var y6=y4+cy+dy;
        return [x6,y6];
    }


    //对一个点进行旋转、缩放、平移，origin为原点，就是将图形移动到（0,0）位置，因为在网页窗口中，图像的位置为左上角作为坐标的原点，而不是图形的几何中心作为原点
    transformPoint(point, origin, rotation, scalex, scaley,translatex,translatey,dstw,dsth) {
        var x1 = point[0];
        var y1 = point[1];
        var x2 = origin[0];
        var y2 = origin[1];

        //console.log(point+"::"+origin+"::"+rotation+"::"+scalex+"::"+scaley+"::"+translatex+"::"+translatey);

        // 平移至原点
        var x3 = x1 - x2;
        var y3 = y1 - y2;


        // 缩放
        var x5 = x3 * scalex;
        var y5 = y3 * scaley;


        // 旋转
        var cos = Math.cos(rotation);
        var sin = Math.sin(rotation);
        var cx=dstw/2;
        var cy=dsth/2;
        var xoffset=x5-cx;
        var yoffset=y5-cy;
        var x4 = xoffset * cos - yoffset * sin;
        var y4 = xoffset * sin + yoffset * cos;

        var x6=x4+cx;
        var y6=y4+cy;



        // 平移回原来的位置
        var x = x6 + x2;
        var y = y6 + y2;
        return [(x+translatex), (y+translatey)];
    }

    //提取图像中最大的像素点大小
    ectractMaxPixelImage(image,width,height)
    {
        const png_imgInfo = {
            alphaType: this.CK.AlphaType.Unpremul,
            colorType:  this.CK.ColorType.RGBA_8888,
            colorSpace:  this.CK.ColorSpace.SRGB,
            width:width,
            height:height
        };

        var maxPixel=0;

        var bytes=image.readPixels(0,0,png_imgInfo,null,null);
        for(var i=0;i<height;i++)
        {
            for(var j=0;j<width;j++)
            {
                var index=(i*height+j)*4;
                if(bytes[index]>maxPixel)
                    maxPixel=bytes[index]
            }
            //去最小值和最大值
        }
        return maxPixel;
    }

    saveToPngBytes(x,y,w,h,vectorImages,backgroundImages,callBack)
    {
        var tmp=this;

        this.updateDrawCallBack(vectorImages,backgroundImages,function () {
            const png_img= tmp.surface.makeImageSnapshot(new tmp.CK.XYWHiRect(Math.ceil(x),Math.ceil(y),Math.ceil(w),Math.ceil(h)));
            var bytes=png_img.encodeToBytes(tmp.CK.ImageFormat.PNG, 97);
            if(callBack!=null)
            {
                callBack(0,bytes);
            }
        });
    }

    //按照所选的原图进行导出

    saveToPngBytesInOrigin(selectImage,vectorImages,callBack)
    {
        var tmp=this;
        const srcImage = this.CK.MakeImageFromEncoded(selectImage.srcData);
        if(srcImage==null) {
            if(callBack!=null)
            {
                callBack(-1,"图像为空");
            }
        }
        //这是背景图导出
        if(selectImage.type==1)
        {
            var backgroundImages=[selectImage];
            var subWidth = selectImage.oriWidth;
            var subHeight = selectImage.oriHeight;
            var subSurface = this.surface.makeSurface({
                width: subWidth,
                height: subHeight,
                alphaType: tmp.CK.AlphaType.Premul,
                colorType: tmp.CK.ColorType.RGBA_8888,
                colorSpace: tmp.CK.ColorSpace.SRGB
            });
            var canvas = subSurface.getCanvas();
            canvas.clear(tmp.CK.Color(0, 0, 0, 0));

            var backShaders=this.generateBackgroundShaders(backgroundImages);
            var vectorShaders=this.generateVectorShader(vectorImages,backgroundImages);
            var shadBack=this.defaultEffect.makeShaderWithChildren([],[]);
            if(backShaders.length>0) {
                shadBack = this.makeBlendShadesSrcOver(backShaders);
            }
            var shadVector=this.defaultEffect.makeShaderWithChildren([],[]);
            if(vectorShaders.length>0) {
                shadVector = this.makeBlendShadesSrcOver(vectorShaders);
            }

            const paint1=new tmp.CK.Paint();
            paint1.setShader(shadBack);
            canvas.drawPaint(paint1);
            shadBack.delete();
            paint1.delete();

            const paint=new tmp.CK.Paint();
            paint.setShader(shadVector);
            canvas.drawPaint(paint);
            shadVector.delete();
            paint.delete();

            var mattingImage = subSurface.makeImageSnapshot();
            var bytes = mattingImage.encodeToBytes(this.CK.ImageFormat.PNG, 100);
            canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            subSurface.dispose();
            if(callBack!=null)
            {
                callBack(0,bytes);
            }

        }else if(selectImage.type==2)
        {
            var subWidth = selectImage.oriWidth;
            var subHeight = selectImage.oriHeight;
            var subSurface = this.surface.makeSurface({
                width: subWidth,
                height: subHeight,
                alphaType: tmp.CK.AlphaType.Premul,
                colorType: tmp.CK.ColorType.RGBA_8888,
                colorSpace: tmp.CK.ColorSpace.SRGB
            });

            var canvas = subSurface.getCanvas();
            canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            const vectorImageShader= srcImage.makeShaderOptions(this.CK.TileMode.Decal,this.CK.TileMode.Decal,this.CK.FilterMode.Nearest,this.CK.MipmapMode.Linear);
            const shaderIF = tmp.CK.ImageFilter.MakeShader(vectorImageShader);
            var paint = new tmp.CK.Paint();
            //这里对图像进行平滑操作
            paint.setColor(tmp.CK.Color(0, 0, 0, 0));
            paint.setImageFilter(shaderIF);
            canvas.drawPaint(paint);
            var mattingImage = subSurface.makeImageSnapshot();
            var bytes = mattingImage.encodeToBytes(this.CK.ImageFormat.PNG, 100);
            vectorImageShader.delete();
            paint.delete();
            canvas.clear(tmp.CK.Color(0, 0, 0, 0));
            subSurface.dispose();
            if(callBack!=null)
            {
                callBack(0,bytes);
            }
        }



    }



}

