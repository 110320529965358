import { ElementBaseValue } from "./ElementBaseValue.js";
import { UIIndex } from "./UIIndex.js";


export class UIImageData extends ElementBaseValue
{
    constructor()
    {
        super();
        this.mImageUrl="";
        this.mImageWidth=1000;
        this.mImageHeight=1000;
        this.mImageBackground="";
        this.mImageName="";
        this.srcData="";
        this.srcBase64Data="";
        this.depthData="";
        this.depthBase64Data="";
        this.normalData="";
        this.normalBase64Data=""
        this.exrData=null;
        this.greyData="";
        this.greyBase64Data="";
        this.maskData="";
        this.maskBase64Data="";
        this.eraseImageBase64Data="";//存储的擦除图像初始数据
        this.eraseSize=10;//擦除画笔的大小
        this.eraseColor="";//擦除画笔的颜色
        this.eraseDrawImageData=null;
        this.scaleEffect=40;
        this.mImageType="image/png";
        this.effectMaps=[];
        this.drawImageData=null;
        this.shapeOffset=0;
        this.mattingThreload=0.15;
        this.lightAverage=0.5;



    }

   static copy(copyData)
    {
        if(copyData==null)
            return null;

        var uiImageData=new UIImageData();



        uiImageData.normalData=copyData.normalData;
        uiImageData.normalBase64Data=copyData.normalBase64Data;
        uiImageData.lightAverage =copyData.lightAverage;
        uiImageData.mattingThreload=copyData.mattingThreload;
        uiImageData.mImageUrl=copyData.mImageUrl;
        uiImageData.shapeOffset=copyData.shapeOffset;
        uiImageData.mImageType=copyData.mImageType;
        uiImageData.mImageBackground=copyData.mImageBackground;
        uiImageData.mImageWidth=copyData.mImageWidth;
        uiImageData.mImageName=copyData.mImageName;
        uiImageData.mImageHeight=copyData.mImageHeight;
        uiImageData.srcData=copyData.srcData;
        uiImageData.depthData=copyData.depthData;
        uiImageData.srcBase64Data=copyData.srcBase64Data;
        uiImageData.depthBase64Data=copyData.depthBase64Data;

        uiImageData.greyData=copyData.greyData;
        uiImageData.greyBase64Data=copyData.greyBase64Data;

        uiImageData.maskData=copyData.maskData;
        uiImageData.maskBase64Data=copyData.maskBase64Data;

        uiImageData.eraseImageBase64Data=copyData.eraseImageBase64Data;//存储的擦除图像初始数据
        uiImageData.eraseSize=copyData.eraseSize;//擦除画笔的大小
        uiImageData.eraseColor=copyData.eraseColor;//擦除画笔的颜色

        uiImageData.effectMaps=copyData.effectMaps;

        uiImageData.scaleEffect=copyData.scaleEffect;
        uiImageData.uuid=copyData.uuid;
        uiImageData.type=copyData.type;
        uiImageData.start=copyData.start;
        uiImageData.end=copyData.end;
        uiImageData.trackIndex=copyData.trackIndex;
        uiImageData.trackTopMargin=copyData.trackTopMargin;
        uiImageData.trackHeight=copyData.trackHeight;
        uiImageData.mPosX=copyData.mPosX;
        uiImageData.mPosY=copyData.mPosY;
        uiImageData.mShowWidth=copyData.mShowWidth;
        //最小一帧对应的时间，用于直接分割帧，避免缩放的时候产生无数的图片
        uiImageData.mShowHeight=copyData.mShowHeight; // frameTime / maxScale
        //最小时间切片的时间
        uiImageData.rotation=copyData.rotation;
        uiImageData.mRatio=copyData.mRatio;
        // val minStandPxInSecond = 1000 * App.instance.dp2px(8f) / minClipTime //最小切片占-帧边框的长度，标准是两倍，此时缩放到最小刚好满足
        uiImageData.bEnableTimeMove=copyData.bEnableTimeMove; //最小切片占-帧边框的长度，标准是两倍，此时缩放到最小刚好满足
        uiImageData.bEnableTimeRangeChange=copyData.bEnableTimeRangeChange;
        uiImageData.bEnableTrackChange=copyData.bEnableTrackChange;
        uiImageData.bEnableShowSizeChange=copyData.bEnableShowSizeChange;
        uiImageData.bEnableShowRotationChange=copyData.bEnableShowRotationChange;
        uiImageData.bEnableShowMove=copyData.bEnableShowMove;

        uiImageData.ELEMENTSPACING=copyData.ELEMENTSPACING; //其余行之间的间隙
        uiImageData.ELEMENTHEIGHT=copyData.ELEMENTHEIGHT; //其余行的高度
        uiImageData.FIRSTELEMENTHEIGHT=copyData.FIRSTELEMENTHEIGHT; //第一行的高度
        uiImageData.FIRSTSPACING=copyData.FIRSTSPACING; //第一行与顶边的间隙
        return uiImageData;
    }



    changeShowWidthAndHeight(){

        if(this.mRatio<1)
        {
            if(this.mShowHeight>this.mShowWidth)
            {
                //适当的调整width
                this.mShowWidth=(this.mShowHeight *this.mRatio);
            }else
            {
                //将高度拉伸
                this.mShowHeight=(this.mShowWidth/this.mRatio);
            }
        }else if(this.mRatio>1)
        {
            if(this.mShowHeight>this.mShowWidth)
            {
                //将高度拉伸
                this.mShowHeight=(this.mShowWidth/this.mRatio);
            }else
            {
                //适当的调整宽度
                this.mShowHeight=(this.mShowWidth/this.mRatio);
            }
        }else
        {
            if(this.mShowHeight>this.mShowWidth)
            {
                this.mShowHeight=this.mShowWidth;
            }else
            {
                this.mShowWidth=this.mShowHeight;
            }
        }
    }

}
