import ProjectEditorDetail from "./detail";
import Updator from "../../../context/updator";
import Global from "components/common/global";

export default function ProjectEditor(){
    return (
        <Global>
            <Updator>
                <ProjectEditorDetail />
            </Updator>
        </Global>
    )
}