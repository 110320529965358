
export default  class WebWorker {
    constructor()
    {
        let work=function() {
            onmessage = function (event) {

                const result = event.data;
                console.log('Received message from the main thread:' + result.cmd);
                switch (result.cmd) {
                    case "processDepthAndGrey":
                        var depthExrData = parseParseExrData(result.exrData);
                        console.log("exr::" + depthExrData.length + "::");
                        var outPutData = loadEffectImageDataByHigh(result.greyOriData, depthExrData, 0.3, result.imageWidth, result.imageHeight);
                        console.log("当前子线程数据大小为" + outPutData.length);
                        postMessage({cmd: "MergerDepthAndGreyData", data: outPutData});
                        break;
                    case "processGreyImageAverg":
                        //处理得到灰度度的均值\
                        console.log("processGreyImageAverg");
                        var average = calculateAverageInGrey(result.data);
                        console.log("当前灰度图的平均值为：：" + average / 255);
                        postMessage({cmd: "getGreyImageAverg", data: average / 255});
                        break;
                    case "processMattingImageThreshold":
                        //得到深度图的平面直方图
                        console.log("processMattingImageThreshold" + "::" + result.data);
                        var total = result.imageWidth * result.imageHeight;
                        var histogram = calculateHistogram(result.data);
                        //找到阈值
                        const {maxVal, threshold} = findMaxVal(histogram, total);
                        console.log("processMattingImageThreshold::" + threshold);
                        postMessage({cmd: "getMattingImageThreshold", data: threshold / 255});
                        break;
                    case "processImageBoundes":
                        let left = result.imageWidth;
                        let right = 0;
                        let top = result.imageHeight;
                        let bottom = 0;

                        for (let y = 0; y < result.imageHeight; y++) {
                            for (let x = 0; x <  result.imageWidth; x++) {
                                const index = (y *  result.imageWidth + x) * 4;


                                if (result.data[index + 3] !== 0) { // 透明度为0
                                    left = Math.min(left, x);
                                    right = Math.max(right, x);
                                    top = Math.min(top, y);
                                    bottom = Math.max(bottom, y);

                                }else
                                {

                                }
                            }
                        }

                        console.log(left+"::"+right+"::"+top+"::"+bottom);

                        var width=right-left;
                        var height=bottom-top;

                        postMessage({cmd: "getImageBoundes", data:[left,top,width,height]});
                        break;
                    case "processImageNormalMap":

                        var pixels={
                            data:result.data,
                            width:result.imageWidth,
                            height:result.imageHeight
                        };
                        var normapBytes =  newsobelfilter(pixels,3,10,"sobel");
                        console.log(normapBytes.data);
                        postMessage({cmd: "getImageNormalMap", data:normapBytes});
                        break;

                }
                // postMessage({cmd:"drawdadadasdasdasdas"});
            };
            function applyThresholding(imageData, threshold) {
                for (let i = 0; i < imageData.data.length; i += 4) {
                    const gray = imageData.data[i];
                    if (gray <= threshold) {
                        imageData.data[i] = 0;
                        imageData.data[i + 1] = 0;
                        imageData.data[i + 2] = 0;
                        imageData.data[i + 3] = 0;
                    }
                }
                return imageData;
            }

            function

            parseParseExrData(srcData) {
                // const { data, width, height } = parseExr(srcData, 1015);
                return null;
            }

            function

            findMaxVal(histogram, total) {
                let maxVal = 0;
                let threshold = 0;
                for (let i = 0; i < histogram.length; i++) {

                    maxVal += histogram[i];
                    if (maxVal >= total / 2) {
                        threshold = i;
                        break;
                    }
                    console.log("当前像素的立方图为：" + i + "::" + histogram[i]);
                }
                return {maxVal, threshold};
            }

// 计算直方图
            function

            calculateHistogram(imageData) {
                const histogram = new Array(256).fill(0);
                for (let i = 0; i < imageData.length; i += 4) {
                    histogram[imageData[i]]++;
                }
                return histogram;
            }

// 计算平均值
            function
            calculateAverageInGrey(imageData) {
                let sum = 0;
                let num = 0;
                for (let i = 0; i < imageData.length; i += 4) {
                    num++;
                    sum += imageData[i];
                }
                return sum / num;
            }


            function

            base64ToImageBase64(base64, type) {
                return "data:" + type + ";base64," + base64;
            }

            function

            bytesToBase64(bytes) {
                //console.log(bytes.length);
                var binaryString = '';
                for (var i = 0; i < bytes.length; i++) {
                    binaryString += String.fromCharCode(bytes[i]);
                }
                // 使用btoa对二进制字符串进行Base64编码
                return btoa(binaryString);
            }

            Number.prototype.mod = function(n) {
                return ((this % n) + n) % n;
            }

            function newsobelfilter(pixels, strength, level, type){
                var src = pixels.data;

                var w = pixels.width;
                var h = pixels.height;
                var output = {
                    width: w, height: h, data: new Float32Array(w*h*4)
                };

                var dst = output.data;

                var max_size = w*h*4;

                var tl, l, bl, t, b, tr, r, br, dX,dY,dZ,l;
                // blue value of normal map
                strength = Math.max (strength, 0.0001);
                var dZ = 1.0 / strength * (1.0 + Math.pow(2.0, level)); // very costly operation!
                var dZ2 = dZ * dZ;

                var filter_type = 0;
                if (type == "sobel")
                    filter_type = 0;
                else if (type == "scharr")
                    filter_type = 1;

                var wm4 = w*4;
                //console.log(w + h);
                for (var y=0; y<h; y++) {
                    for (var x=0; x<w; x++) {
                        var dstOff = (y*w+x)*4;

                        // very costly operation!
                        if (x == 0 || x == w-1 || y == 0 || y == h-1){
                            /*tl = src[(dstOff - 4 - wm4).mod(max_size)];   // top left
                            l  = src[(dstOff - 4      ).mod(max_size)];   // left
                            bl = src[(dstOff - 4 + wm4).mod(max_size)];   // bottom left
                            t  = src[(dstOff - wm4    ).mod(max_size)];   // top
                            b  = src[(dstOff + wm4    ).mod(max_size)];   // bottom
                            tr = src[(dstOff + 4 - wm4).mod(max_size)];   // top right
                            r  = src[(dstOff + 4      ).mod(max_size)];   // right
                            br = src[(dstOff + 4 + wm4).mod(max_size)];   // bottom right
                            console.log("before: tl: " + tl + ",l: " + l + ", bl: " + bl + ", t: " + t + ", b: " + b );*/
                            tl = src[((x-1).mod(w) + ((y-1).mod(h)*w)) * 4];   // top left
                            l  = src[((x-1).mod(w) + ( y		  *w)) * 4];   // left
                            bl = src[((x-1).mod(w) + ((y+1).mod(h)*w)) * 4];   // bottom left
                            t  = src[(  x 		   + ((y-1).mod(h)*w)) * 4];   // top
                            b  = src[(  x 		   + ((y+1).mod(h)*w)) * 4];   // bottom
                            tr = src[((x+1).mod(w) + ((y-1).mod(h)*w)) * 4];   // top right
                            r  = src[((x+1).mod(w) + ( y		  *w)) * 4];   // right
                            br = src[((x+1).mod(w) + ((y+1).mod(h)*w)) * 4];   // bottom right
                            //console.log("after: tl: " + tl + ",l: " + l + ", bl: " + bl + ", t: " + t + ", b: " + b );

                        }
                        else{
                            tl = src[(dstOff - 4 - wm4)];   // top left
                            l  = src[(dstOff - 4      )];   // left
                            bl = src[(dstOff - 4 + wm4)];   // bottom left
                            t  = src[(dstOff - wm4    )];   // top
                            b  = src[(dstOff + wm4    )];   // bottom
                            tr = src[(dstOff + 4 - wm4)];   // top right
                            r  = src[(dstOff + 4      )];   // right
                            br = src[(dstOff + 4 + wm4)];   // bottom right
                        }

                        // sobel
                        if (filter_type == 0){ // "sobel"
                            dX = tl + l*2 + bl - tr - r*2 - br;//tl*1.0 + l*2.0 + bl*1.0 - tr*1.0 - r*2.0 - br*1.0;
                            dY = tl + t*2 + tr - bl - b*2 - br;//tl*1.0 + t*2.0 + tr*1.0 - bl*1.0 - b*2.0 - br*1.0;
                        }
                        // scharr
                        else if (filter_type == 1){  // "scharr"
                            dX = tl*3.0 + l*10.0 + bl*3.0 - tr*3.0 - r*10.0 - br*3.0;
                            dY = tl*3.0 + t*10.0 + tr*3.0 - bl*3.0 - b*10.0 - br*3.0;
                        }

                        l = Math.sqrt((dX * dX) + (dY * dY) + dZ2);

                        dst[dstOff] = (dX/l * 0.5 + 0.5) * 255.0; 	// red
                        dst[dstOff+1] = (dY/l * 0.5 + 0.5) * 255.0; 	// green
                        dst[dstOff+2] = dZ/l * 255.0; 				// blue
                        dst[dstOff+3] = src[dstOff+3];
                    }
                }

                return output;
            }

            function

            base64ToByteArray(base64String) {
                const padding = '='.repeat((4 - base64String.length % 4) % 4);
                const base64 = (base64String + padding)
                    .replace(/\-/g, '+')
                    .replace(/_/g, '/');
                const rawData = window.atob(base64);
                const rawDataLength = rawData.length;

                const array = new Uint8Array(new ArrayBuffer(rawDataLength));
                for (let i = 0; i < rawDataLength; i++) {
                    array[i] = rawData.charCodeAt(i);
                }

                return array;
            }


            function

            loadEffectImageDataByHigh(greyOriData, depthExrData, blendP, imageWidth, imageHeight) {
                var max = 0;
                var min = 255;
                var center = 0;

                for (var i = 0; i < imageHeight; i++)
                    for (var j = 0; j < imageWidth; j++) {
                        var depth = depthExrData[i * imageHeight + j];
                        if (depth > max)
                            max = depth;
                        if (depth < min)
                            min = depth;
                        center += depth;
                    }

                center = center / (imageWidth * imageHeight);
                var total = imageHeight * imageWidth;
                for (var i = 0; i < imageHeight; i++)
                    for (var j = 0; j < imageWidth; j++) {
                        //如果深度值大于平均值，则按照如下算法
                        var depth = depthExrData[((imageHeight - i - 1) * imageHeight + j)];
                        if (depth > center)
                            depth = (1 + (255 - max) / 255) * depth;
                        else if (depth < center)
                            depth = (1 - (min) / 255) * depth;

                        //开始与灰度图进行融合
                        var index = (i * imageHeight + j) * 4;
                        greyOriData[index] = Math.round(depth * (1 - blendP) + greyOriData[index] * blendP);
                        greyOriData[index + 1] = Math.round(depth * (1 - blendP) + greyOriData[index + 1] * blendP);
                        greyOriData[index + 2] = Math.round(depth * (1 - blendP) + greyOriData[index + 2] * blendP);

                    }
                return [min, max, center, greyOriData];
            }
        };
        const code=work.toString();
        const blob=new Blob(["("+code+")()"]);
        return new Worker(URL.createObjectURL(blob));


    }





}




