import { RulerLineBaseValue } from "./RulerLineBaseValue.js";

export class RulerYView {

    constructor()
    {

        this.rulerLineBaseValue=new RulerLineBaseValue();

        this.divContainer=null;
        this.textPaint=null;
        /** 每小刻度的 单位像素长度 */
        this.rulerPxUnit = 1.0;
        /** 尺子的每个小刻度 单位时间长度 */
        this.rulerTimeUnit = 1;
        /** 大刻度的默认间隔 */
        this.standPxPs =this.dp2px(64);

        this.numberStr="";

        this.white30Color="#CCCCCC";

        this.white50Color="#CCCCCC";
        this.dp1=0.5;
        this.measuredWidth=100;
        this.measuredHeight=100;
        this.updateRuler=()=>{
            this.updateRulerState();
        };
        this.scaleChange =()=>{
          this.scaleChangeState();
        };
    }


    dp2px(dp) {
        var value = window.devicePixelRatio;
        // return Math.round(dp * value);
        return dp;
    }

    init(parent) {
        this.divContainer=document.createElement('div');
        this.divContainer.style.width="100%";
        this.divContainer.style.height="100%";
        this.divContainer.style.position="absolute";
        this.divContainer.style.background="transparent";
        this.divContainer.style.orientation="column";
        this.divContainer.className = "yCoord";
        parent.append(this.divContainer);
        var canvas =document.createElement('canvas');
        canvas.style.width="100%"
        canvas.style.height="100%";
        this.divContainer.appendChild(canvas);

        canvas.width=canvas.offsetWidth;
        canvas.height=canvas.offsetHeight;

        this.textPaint=canvas.getContext("2d");
        this.textPaint.strokeStyle  = this.white30Color;
        this.textPaint.lineWidth  = this.dp1;
        this.textPaint.textSize = this.dp2px(1);
        this.textPaint.font = 'bold 14px Arial'; // 设置字体大小和类型
        this.textPaint.textAlign = 'center'; // 设置水平居中对齐
        this.textPaint.fillStyle = this.white30Color; // 设置文字颜色为蓝色
        this.measuredWidth=this.divContainer.offsetWidth;
        this.measuredHeight=this.divContainer.offsetHeight;
        this.scaleChangeState();
        if(this.measuredWidth<=0)
        {
            var tmp=this;
            var timer=  setTimeout(()=>{
                canvas.width=canvas.offsetWidth;
                canvas.height=canvas.offsetHeight;
                tmp.measuredWidth=tmp.divContainer.offsetWidth;
                tmp.measuredHeight=tmp.divContainer.offsetHeight;
                tmp.textPaint.strokeStyle  = tmp.white30Color;
                tmp.textPaint.lineWidth  = tmp.dp1;
                tmp.textPaint.textSize = tmp.dp2px(1);
                tmp.textPaint.font = 'bold 14px Arial'; // 设置字体大小和类型
                tmp.textPaint.textAlign = 'center'; // 设置水平居中对齐
                tmp.textPaint.fillStyle = tmp.white30Color; // 设置文字颜色为蓝色
                clearTimeout(timer);
                tmp.scaleChangeState();
            },1000);
        }
    }

    setRulerLineBaseValue(rulerBaseValue)
    {
        this.rulerLineBaseValue=rulerBaseValue;
        this.scaleChangeState();
    }

    clearDraw()
    {
        this.textPaint.clearRect(0, 0, this.divContainer.offsetWidth, this.divContainer.offsetHeight);
    }

    onDraw()
    {

       //  if(this.rulerLineBaseValue==null)
       //      return;
       //
       //  if(this.rulerLineBaseValue.pxInMark<=0)
       //      return;
       //  this.clearDraw();
       //  var rulerLineValue = this.rulerLineBaseValue;
       //
       //  //这里指定最开始位置
       // // var startY = Math.floor(this.measuredWidth / 2.0);
       //  var startY = Math.floor(0);
       //
       //  var currentRuleFlag = (startY / rulerLineValue.pxInMark * 1000); //从0开始中间位置对应的刻度时间
       //
       //  if(rulerLineValue.position <= currentRuleFlag)
       //      currentRuleFlag=0;
       //  else
       //      currentRuleFlag=(Math.ceil(((rulerLineValue.position - currentRuleFlag) / this.rulerTimeUnit)) * this.rulerTimeUnit);
       //
       //  startY -= (rulerLineValue.position - currentRuleFlag) * rulerLineValue.pxInMark / 1000;
       //
       //  var minute=0;
       //
       //  while(startY<this.measuredHeight)
       //  {
       //
       //      if (currentRuleFlag / this.rulerTimeUnit % 5 == 0)
       //      {
       //          this.textPaint.strokeStyle  = this.white50Color;
       //
       //          this.textPaint.beginPath();
       //          this.textPaint.moveTo( 0,startY);
       //          this.textPaint.lineTo( this.measuredWidth,startY);
       //          this.textPaint.stroke();
       //
       //      var mark = currentRuleFlag/ 1000.0 % 60.0;
       //      var ms = Math.floor((1000.0 * mark));
       //      if (ms % 1000 == 0) {
       //          //整秒
       //          this.numberStr="";
       //          this.numberStr+=Math.round(ms / 1000);
       //          this.numberStr+="cm";
       //      }else {
       //          this.numberStr ="";
       //          this.numberStr +=Math.ceil(Math.round(mark * 100) / 100.0);
       //
       //          if (this.numberStr.indexOf(".") > 0) {
       //              while ((this.numberStr.indexOf(".") > 0)
       //                  && (this.numberStr.endsWith('0') || this.numberStr.endsWith('.'))
       //                  ) {
       //                  this.numberStr = this.numberStr.slice(this.numberStr.lastIndex);
       //              }
       //          }
       //          this.numberStr +="cm";
       //      }
       //
       //      minute = Math.floor(currentRuleFlag / 60000);
       //      if (minute > 0) { //满分钟
       //          this.numberStr=minute+"m"+this.numberStr;
       //      }
       //
       //      //绘制文本
       //      this.textPaint.fillText(this.numberStr,this.dp1 * 16.0,startY);
       //  }else
       //      {
       //          this.textPaint.strokeStyle = this.white30Color;
       //          this.textPaint.beginPath();
       //          this.textPaint.moveTo(0,startY);
       //          this.textPaint.lineTo(this.measuredWidth,startY);
       //          this.textPaint.stroke();
       //
       //        //  ////console.log("绘制线段2::"+startY+"::"+ this.dp1 *3.0);
       //      }
       //      currentRuleFlag += this.rulerTimeUnit;
       //      startY += this.rulerPxUnit;
       //  }

        ////console.log("绘制结束：："+this.rulerLineBaseValue+"::"+this.rulerLineBaseValue.pxInMark+"::"+this.measuredWidth);
    }

    updateRulerState()
    {
        this.onDraw();
    }

    scaleChangeState()
    {
        var pxInMarkScaled = this.rulerLineBaseValue.pxInMark;
    //     if (pxInMarkScaled < this.standPxPs) {
    //         while (true) {
    //             if (pxInMarkScaled >= this.standPxPs) {
    //                 break;
    //             }
    //             pxInMarkScaled *= 2.0;
    //         }
    //     }
    //     if (pxInMarkScaled >= this.standPxPs * 2.0) {
    //     while (true) {
    //         if (pxInMarkScaled < this.standPxPs * 2.0) {
    //             break;
    //         }
    //         pxInMarkScaled /= 2.0;
    //     }
    // }

        this.rulerPxUnit = pxInMarkScaled;
        this.rulerTimeUnit = Math.floor(this.rulerPxUnit * 1000 /  this.rulerLineBaseValue.pxInMark);

        this.onDraw();
    }

    bringToFront()
    {
        this.divContainer.style.zIndex=1000;
    }
};
