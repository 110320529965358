
import {ProjectDataManager} from "./Data/ProjectDataManager";
import React from "react";
import {ShowFrameLayout} from "./UIControlls/uishow/ShowFrameLayout";
import {UIGraphics} from "./UIControlls/uigraphics/UIGraphics";
import {UISelectEventType} from "./Data/UISelectEventType";
import {UIDepthImage} from "./UIControlls/uigraphics/UIDepthImage";
import {UIMattingImage} from "./UIControlls/uigraphics/UIMattingImage";
import {UIDrawGraphics} from "./UIControlls/uigraphics/UIDrawGraphics";

export class KeenBowImageEdit{
    constructor()
    {
        if (!KeenBowImageEdit.instance) {
            this.mProjectDataManager=new ProjectDataManager();
            this.showFrameLayout=null;
            this.uiGraphics=null;
            KeenBowImageEdit.instance=this;
        } else {
            return KeenBowImageEdit.instance;
        }
    }


    /**
     * 初始化画板。
     * @param {CanvasKitInit} canvaskit函數
     * @param {CanvasKitWasm} canvaskit文件所在的位置
     * @param {string} div_id - 画板放入的容器,容器必须要有真实大小，样式不允许设置为flex
     * @param {number} size_w - 画板的尺寸 mm作为单位  1px=33mm
     * @param {number} size_h- 画板的尺寸 mm作为单位  1px=33mm
     * @param {function} callBack- 初始化回调 function(code,result),其中code==0代表成功，其余作为错误码返回，result返回错误信息
     * @param {return} void
     */
    initCavas(CanvasKitInit,CanvasKitWasm,div_id,size_w,size_h,callBack)
    {
        var showFrameLayoutContainer=document.getElementById(div_id);
        this.showFrameLayout =new ShowFrameLayout();
        this.showFrameLayout.init(showFrameLayoutContainer,size_w,size_h);
        this.uiGraphics=new UIGraphics();
        UIGraphics.instance.init(showFrameLayoutContainer,CanvasKitInit,CanvasKitWasm,callBack);
    }

    /**
     * 添加MockUp功能
     * @param {url or file} vector -  背景图像对应的地址或者选中的文件
     * @param {url or file} vector -  向量图像对应的地址或者选中的文件
     * @param {function} callBack- 添加回调 function(code,result),其中code==0代表成功，小于0作为错误码返回，result返回错误信息，大于0代表当前处理状态
     * @param {return} void
     */
    addMockup(background,vector,callBack)
    {
        var tmp=this;
        this.addImage(1,background,function (code,result) {

            if(code==0)
            {
                tmp.addImage(2,vector,callBack);
            }else
            {
                if(callBack!=null)
                {
                    callBack(code,result);
                }
            }
        });
    }


    /**
     * 添加图像
     * @param {int} type - 1 or 2,1代表背景图，2代表向量图
     * @param {url or file} imageFile -  mokeup图像对应的地址或者选中的文件
     * @param {function} callBack- 添加回调 function(code,result),其中code==0代表成功，小于0作为错误码返回，result返回错误信息，大于0代表当前处理状态
     * @param {return} void
     */
    addImage(type,imageFile,callBack)
    {
        ProjectDataManager.instance.addImage(type,imageFile,true,false,function (code,result) {

            if(code==2)
            {
                ShowFrameLayout.instance.updateSelectedView(result);
                if(ShowFrameLayout.instance.eventsCallBack!=null)
                    ShowFrameLayout.instance.eventsCallBack(UISelectEventType.Added,result);
                callBack(0,result);
            }else
            {
                if(callBack)
                {
                    callBack(code,result);
                }
            }

        });
    }

    /**
     * 对选中的图片进行类型转换，如果选中的类型为1(背景图)，则会转换成2（向量图），如果是2（向量图），则会转换成1（背景图）
     * @param {function} callBack- 添加回调 function(code,result),其中code==0代表成功，小于0作为错误码返回，result返回错误信息，大于0代表当前处理状态
     * @param {return} void
     */
    transformImageTypeInSelect(callBack)
    {
        ProjectDataManager.instance.transformImageTypeInSelect(true,function (code,result) {
            if(code==0)
            {
                if(ShowFrameLayout.instance.eventsCallBack!=null)
                   ShowFrameLayout.instance.eventsCallBack(UISelectEventType.SwitchType,result);
                ShowFrameLayout.instance.dispatchUpdateUIEvent();
                if(callBack)
                {
                    callBack(0,result);
                }
            }else
            {
                if(callBack)
                {
                    callBack(code,result);
                }
            }
        });
    }

    /**
     * 得到当前选中图片
     * @param {return} imageData,没有选中则返回null,请做判空处理
     */
    getSelectImageData()
    {
        return ProjectDataManager.instance.getSelectCurrentData();
    }

    /**
     * 得到当前选中图片进行抠图操作，类型必须为2，否则失效
     * @param {function} callBack- 添加回调 function(code,result),其中code==0代表成功，小于0作为错误码返回，result返回错误信息，大于0代表当前处理状态
     * @param {return} void,没有任何返回
     */
    mattingSelectImage(callBack)
    {
        UIGraphics.instance.mattingSelectImageByPixel(callBack);
    }

    /**
     * 注册事件回调,重复注册将以最后一次注册的回调为准
     * @param {function} callBack-事件回调，当前以下事件会返回
     *   Selected:0,//选中了事件
     *   Moved:1,//移动结束事件
     *   Rotated:2,//旋转结束事件
     *   Scaled:3,//缩放结束事件
     *   QuitSelected:4,//取消选中事件
     *   Deleted: 5,//删除图像
     *   Added:6,//添加图像
     *   StartMove:7,//準備移動中
     *   Moving:8,//正在移动中
     *   None:9,
     *   StartScale:10,//开始缩放
     *   StartRotate:11,//开始旋转
     *   Scaling:12,//缩放中
     *   Rotating:13,//旋转中
     *   EraseDrawing:14, //擦除面板绘制中
     * @param {return} void  回调 function(eventType,selectData),其中eventType代表事件类型,selectData代表选中
     */
    registerEventsCallBack(callBack)
    {
        ShowFrameLayout.instance.eventsCallBack=callBack;
    }

    /**
     * 手动刷新画板
     * @param {return} void,没有选中则返回null,请做判空处理
     */
    updateCanvas()
    {
        ShowFrameLayout.instance.dispatchUpdateUIEvent();
    }
    /**
     * 对选中的图片进行保存下载
     * @param {string} fileName- 文件名，后缀规定为 png
     * @param {function} callBack- 添加回调 function(code,result),其中code==0代表成功，小于0作为错误码返回，result返回错误信息，大于0代表当前处理状态
     * @param {return} void
     */
    saveSelectImageToPngFile(fileName,callBack)
    {
        UIGraphics.instance.saveImage(fileName,function (code,result) {
            if(code==0)
            {
                if(ShowFrameLayout.instance.eventsCallBack!=null)
                    ShowFrameLayout.instance.eventsCallBack(UISelectEventType.SaveImage,result);
            }

            if(callBack!=null)
            {
                callBack(code,result);
            }

        })
    }

    /**
     * 设置服务地址
     * @param {string} depthUrl- 深度图服务器地址
     * @param {string} mattingUrl- 添加回调 function(code,result),其中code==0代表成功，小于0作为错误码返回，result返回错误信息，大于0代表当前处理状态
     * @param {return} void
     */

    setServerAddress(depthUrl,mattingUrl)
    {
        UIDepthImage.instance.depthImageRequireUrl=depthUrl;
        UIMattingImage.instance.matingUrl=mattingUrl;
    }

    /**
     * 修改向量图的透明度
     * @param {float} alpha- 0-1 0代表全透，1，代表不透明
     * @param {return} void
     */
    modifyVectorImageAlpha(alpha)
    {
        UIDrawGraphics.instance.vectorAlpha=alpha;
    }

}
export default KeenBowImageEdit;
