import axios from "axios"
// import http from 'http'
// import https from 'https'
import Cookies from 'js-cookie'

type AuthorizationHeader = {
    Authorization: string
}

type ContentTypeHeader = {
    'Content-Type': string
}

const ContentTypeKind = {
    JSON : 'application/json',
    FILE : 'multipart/form-data'
}



export type ResponseData = {
    errCode: number,
    errMsg ?: string,
    data ?: any,
    token ?: string
}

export async function HttpUtil(){
}

function customerAuthorizationHeader() : AuthorizationHeader {
    return {
        Authorization : Cookies.get('Authorization') || ''
    }
}

function contentTypeHeader(contentType: string = ContentTypeKind.JSON) : ContentTypeHeader {
    return {
        "Content-Type" : contentType
    }
}

HttpUtil.serverPost = async (url: string, bodyData: any) : Promise<ResponseData> => {

    return new Promise<ResponseData>((resolve) => {
        // const api_url = process.env.NEXT_PUBLIC_API_DOMAIN + url ;

        // const _http  =  /https/ig.test(api_url) ? https : http;
        
        // const request = _http.request(api_url,{
        //     method: 'POST',
        //     headers:{
        //         ...customerAuthorizationHeader(),
        //         ...contentTypeHeader()
        //     }
        // },(res) => {
        //     // console.info(res);
        //     let content = '';
        //     res.setEncoding('utf8');
        //     res.on('data',(msg) => {
        //         content += msg ;
        //     })
        //     res.on('end',() => {
        //         resolve(JSON.parse(content));
        //     })
        // })
    
        // request.write(JSON.stringify(bodyData))
        // request.end();
    })
    
}

const CLIENT_URL = process.env.REACT_APP_API_DOMAIN ;

HttpUtil.download = async(url: string, name: string) => {
    const download_url = CLIENT_URL + '/biz/images/download?url=' + url ;
    try{
        const response = await axios.get(download_url, {
            headers : {
                ...customerAuthorizationHeader()
            },
            responseType: 'blob'
        });
        // 创建一个 URL 对象
        const imageUrl = window.URL.createObjectURL(new Blob([response.data]));

        // 创建一个链接元素
        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute('download', name + '.' + url.split('.').pop()); // 设置下载的文件名

        // 触发下载
        document.body.appendChild(link);
        link.click();

        // 清理
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(imageUrl);
    }catch(e){
        // console.info(e);
        return {
            errCode: 500,
            errMsg: 'Error'
        } ;
    }

}

HttpUtil.post = async (url: string, bodyData: any) : Promise<ResponseData> => {
    try{
        const response = await axios.post(CLIENT_URL + url, bodyData, {
            headers : {
                ...customerAuthorizationHeader(),
                ...contentTypeHeader()
            }
        });
        const responseData = response.data ;
        if(responseData.errCode){ // 添加失败

        }
        return response.data;
    }catch(e){
        console.info(22, e);
        return {
            errCode: 500,
            errMsg: 'Error'
        } ;
    }
}

HttpUtil.upload = async (file: File) : Promise<ResponseData> => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(CLIENT_URL + '/biz/upload', formData, {
          headers :{
            ...customerAuthorizationHeader(),
            ...contentTypeHeader(ContentTypeKind.FILE)
          }
        });
        return response.data ;
      } catch (error) {
        return {
            errCode: 500,
            errMsg: 'Service error'
        }
      }
}
