import Bubble from "./bubble";

export default function FunctionButton(props : {
        children ?: any, onClick : Function, iconPath : string, isActive ?: boolean,
        bubble ?: {
            title: string, desc?: string
        }}){
    const { children, onClick, iconPath, isActive = false, bubble} = props ;

    return (
        <div className={`relative p-[5px] rounded-[3px] cursor-pointer ${isActive ? 'bg-[#EFF3F5]' : ''}`}
            onClick={() => onClick()}>
            <div className="relative group ">
                <img src={iconPath} className="w-[25px]" />
                {
                    bubble && <Bubble {...bubble} className='group-hover:block'/>
                }
            </div>
            {children}
        </div>
    )
}