import 'react-app-polyfill/ie11'; 
import 'react-app-polyfill/stable';
import KeenBowImageEdit from "../../../libs/editor/bundle";
import {useEffect, useRef, useState} from "react";
import CanvasKitInit from "canvaskit-wasm";
import CanvasKitWasm from "canvaskit-wasm/bin/canvaskit.wasm";
import { useHttpContext } from 'context/httpContext';

export default function MockupEditor(props: {
    action: Action | undefined,
    onDataListener : (params: EventCallbackParams) => void
}) {
    const parentElement = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<CanvasSize>();
    const [mockupApi, setMockupApi] = useState<MockupApiInterface>();
    const httpContent = useHttpContext();
    const { action, onDataListener } = props ;

    useEffect(() => {
        if(parentElement.current){
            const width = parentElement.current.clientWidth,
                height = parentElement.current.clientHeight;
            //初始化画板
            new KeenBowImageEdit();
            setSize({width,height})
            setMockupApi(KeenBowImageEdit.instance);
        }
    },[])

    useEffect(() => {
        if(mockupApi && size){
            // 实例后，初始化画布
            mockupApi.initCavas(CanvasKitInit,CanvasKitWasm, "mockupEditorScope",size.width * 33,size?.height * 33,function (code: any,result: any) {
                // code :0 成功 
                if(!code){
                    // 注册事件
                    mockupApi.registerEventsCallBack((type: any, data: any) => {
                        console.info('event callback', type, data)
                        switch(type){
                            case 0: // 对象选择
                                // console.info('image', mockupApi.getSelectImageData())
                                onDataListener({
                                    type: EventCallbackParamsType.OBJECT_SELECT,
                                    data: mockupApi.getSelectImageData()
                                })
                                break;
                            case 4: // 取消选择
                                onDataListener({
                                    type: EventCallbackParamsType.OBJECT_CANCEL_SELECT,
                                })
                                break;
                        }
                    })
                }
                
            })
        }
    },[size])

    useEffect(() => {
        if(!action) return ;
        switch(action.name){
            case ActionName.LOADING_IMAGE: // 加载图片
                mockupApi?.addImage(2, action.params, (code: number, result: string) => {
                    // if(code){
                    //     alert(result);
                    // }
                })
                break;
            case ActionName.MOCKUP: // 转mockup
                console.info('转mockup start ')
                mockupApi?.transformImageTypeInSelect((code: number, result: any) => {
                    console.info('转mockup end',code,result)
                    if(!code){
                        httpContent?.response(0, 'Successfully converted to mockup')
                        onDataListener({
                            type: EventCallbackParamsType.OBJECT_SELECT,
                            data: result
                        })
                    }else if(code === -1){
                        httpContent?.response(100, 'Failed to convert to mockup')
                    }
                });
                break;
            case ActionName.DOWNLOAD: // 下载
                mockupApi?.saveSelectImageToPngFile(`${action.params}.png`);
                break;
        }
    }, [action])

  return (
    <div className="w-full h-full overflow-hidden" ref={parentElement}>
      <div id="mockupEditorScope" style={{width:`${size?.width}px`,height:`${size?.height}px`,display:'block'}} >
        
      </div>
    </div>
  );
}


type CanvasSize = {
    width : number,
    height: number
}

type MockupApiInterface = {
    initCavas: Function, // 初始化画布
    addMockup: Function, // 添加mockup(backgroudUrl, imageurl)
    addImage: Function,  // 添加图片(type, imageUrl) : type: 1、背景(深度)  2、前景(向量图)
    transformImageTypeInSelect: Function, // 转mockup
    getSelectImageData : Function , // 获取选中的图片
    /**
     *  Selected:0,//选中了事件
        Moved:1, //移动结束事件
        Rotated:2//旋转结束事件
        Scaled:3,//缩放结束事件
        QuitSelected:4,//取消选中事件
        Deleted:5,/删除图像
        Added:6, //添加图像
        StartMove:7.,//準備移動中
        Moving:8,//正在移动中
        None.9,
        Startscale:10,//开始缩放
        StartRotate:11,//开始旋转
        Scaling:12,//缩放中
        Rotating:13,//旋转中
        EraseDrawing:14,//擦除面板绘制中
     */
    registerEventsCallBack: Function , // 注册事件(type,data)
    saveSelectImageToPngFile: Function // 下载图片(fileName)
}

export type EventCallbackParams = {
    type: EventCallbackParamsType,
    data?: MockupObject | null
}

export enum EventCallbackParamsType {
    OBJECT_SELECT,
    OBJECT_CANCEL_SELECT
}

export type MockupObject = {
    uuid: number,
    type: number,
    mImageWidth: number,
    mImageHeight: number
}

/**
 * 操作指令
 */
export type Action = {
    /**
     * 指令名称
     */
    name: ActionName,
    /**
     * 指令参数
     */
    params ?: string
}

export enum ActionName {
    /**
     * 加载图片
     */
    LOADING_IMAGE,
    /**
     * 转Mockup
     */
    MOCKUP,        
    /**
     * 下载(Mockup、Frame、图片)
     */
    DOWNLOAD,     
}
