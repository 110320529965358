

export default function DialogConfirm(props : { title ?: string, cancel : Function, confirm : Function}){
    const { title = 'Sure you want to delete it?', cancel, confirm } = props ;
    return (
        <div className="fixed w-full h-full left-0 top-0 bg-[#000000]/[0.5] z-[999]">
            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[320px] p-[20px] bg-[#ffffff] rounded-[15px]">
                <img src="/icon/icon-close.png" className="absolute right-[15px] top-[10px] w-[20px] cursor-pointer" 
                    onClick={() => {cancel()}}/>
                <p className="font-bold text-[16px]">{title}</p>
                <div className="text-right mt-[30px]">
                    <button className="rounded-[10px] bg-[#EBEBEB] text-[#000000] px-[16px] py-[6px]"
                        onClick={() => cancel()}>No</button>
                    <button className="rounded-[10px] bg-gradient-to-r from-[#5170FF] to-[#002EFF] text-[#FFFFFF] px-[16px] py-[6px] ml-[10px]"
                        onClick={() => confirm()}>Yes</button>
                </div>
            </div>
        </div>
    )
}