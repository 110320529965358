import Topbar from "./topbar";
import Menubar from "./menubar";
import Search from "../../context/search";
import Global from "components/common/global";

export default function Layout(props: {children: any}) {
  return (
      <Global>
        <div className="min-h-screen flex flex-col ">
            <Search>
              <Topbar />
              <div className="grow flex h-full">
                <Menubar />
                <div className="grow">
                  {props.children}
                </div>
              </div>
            </Search>
        </div>
      </Global>
  );
}
