import { createContext, useContext, useEffect, useState } from "react";
import Loading from "../components/common/loading";


interface LoadingContextObj {
  isLoadingLayout: boolean,
  setIsLoadingLayout: Function
}
export const LoadingContext = createContext<LoadingContextObj|null>(null);

export function useLoadingContext() : LoadingContextObj | null{
  return useContext(LoadingContext);
}

/**
 * Loading布局
 */
export default function LoadingLayout(props: {isDefaultLoading?: boolean , children: any}) {
    const {isDefaultLoading = true, children} = props ;

    const [isLoadingLayout, setIsLoadingLayout] = useState<boolean>(isDefaultLoading);

    return (
        <>
          <LoadingContext.Provider value={{
            isLoadingLayout, setIsLoadingLayout
          }}>
            {
              isLoadingLayout &&
                <div className="absolute w-full h-full left-0 top-0 z-[999999] bg-[#FFFFFF]">
                  <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <Loading />
                  </div>
                </div>
            }
            {children}
          </LoadingContext.Provider>
        </>
    );

}