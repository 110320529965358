import {UIImageData} from "./UIImageData";
import {UISelectType} from "./UISelectType";
import {UIIndex} from "./UIIndex";
import {ElementBaseValue} from "./ElementBaseValue";
import {UIGraphics} from "../UIControlls/uigraphics/UIGraphics";
import {ShowFrameLayout} from "../UIControlls/uishow/ShowFrameLayout";
import {UIMattingImage} from "../UIControlls/uigraphics/UIMattingImage";

export class ProjectDataManager {
    constructor() {
        if (!ProjectDataManager.instance) {
            this.scaleFactory = 1;
            this.mCurrentData = null;
            this.mImageDatas = [];
            this.mUIIndex = new UIIndex();
            this.depthImageRequireUrl = "http://y1.qbaidata.com:7000/service/depth_map";
            this.splitImageRequireUrl = "http://y1.qbaidata.com:7000/service/segmentation";
            new UIMattingImage();
            ProjectDataManager.instance = this;
        } else {
            return ProjectDataManager.instance;
        }
    }

    updateAllDatas(datas)
    {
        this.mCurrentData=null;
        this.mImageDatas=datas;
    }

    setSelectCurrentData(data) {
        this.mCurrentData = data;
    }

    getSelectCurrentData() {
        return this.mCurrentData;
    }

    getAllImageData() {
        return this.mImageDatas;
    }

    getImageDataByUuid(uuid) {
        for (var i = 0; i < this.mImageDatas.length; i++) {
            if (this.mImageDatas[i].uuid === uuid) {
                return this.mImageDatas[i];
            }
        }
        return null;
    }

    modifyVectorEffectData(imageData) {
        if (imageData.type === UISelectType.BackgroundImage)
            return;

        var image = this.getImageDataByUuid(imageData);
        if (image != null) {
            image.scaleEffect = imageData.scaleEffect;
        }

    }

    modifySelectImageEffect(effect) {
        var imageData=this.getSelectCurrentData();
       //console.log("当前选中的是：："+imageData+"::"+imageData.type);
        if(imageData==null)
            return;
        if (imageData.type === UISelectType.BackgroundImage)
            return;
        imageData.scaleEffect=effect;
    }

    getAllBackgroungImage(imageDatas) {
        var backgroungImages = [];
        for (var i = 0; i < imageDatas.length; i++) {
            if (imageDatas[i].type === UISelectType.BackgroundImage) {
                backgroungImages.push(imageDatas[i]);
            }
        }
        return backgroungImages;
    }

    getAllVector3Image(imageDatas) {
        var vectorImages = [];
        for (var i = 0; i < imageDatas.length; i++) {
            if (imageDatas[i].type === UISelectType.VectorImage) {
                vectorImages.push(imageDatas[i]);
            }
        }
        return vectorImages;
    }

    //删除数据逻辑
    deleteImageData(mUIImageDatas, callBack) {
        var message = "";
        for (var i = 0; i < this.mImageDatas.length; i++) {
            if (mUIImageDatas.uuid === this.mImageDatas[i].uuid) {
                this.mImageDatas.splice(i, 1);
                message = "删除成功";
                if (callBack != null)
                    callBack(0, message);
                return;
            }
        }

        message = "删除不成功,没有查找到数据";
        if (callBack != null)
            callBack(-1, message);
    }

    recomputeTrackIndex(imageData)
    {
        return  ElementBaseValue.getTrackIndex(imageData, this.mImageDatas);
    }

    //创建单个数据
    addImageData(type, callBack) {
        //console.log("tttttttttttttttt");
        var mUIImageDatas = new UIImageData();

        mUIImageDatas.type = type;
        mUIImageDatas.uuid = this.mUIIndex.generateIndex();
        mUIImageDatas.rotation = 0;
        mUIImageDatas.trackIndex = ElementBaseValue.getTrackIndex(mUIImageDatas, this.mImageDatas);
        if (type === UISelectType.BackgroundImage) {
            mUIImageDatas.mImageBackground="#FF00FF";
            var rect_p=ShowFrameLayout.instance.getCenterPositionAndSizeInParent(800,600);
            mUIImageDatas.mPosX =rect_p[0];
            mUIImageDatas.mPosY =rect_p[1];
            mUIImageDatas.mShowWidth = rect_p[2];
            mUIImageDatas.mShowHeight =  rect_p[3];
        } else {
            mUIImageDatas.mImageBackground="#0000FF";
            var rect_p=ShowFrameLayout.instance.getCenterPositionAndSizeInParent(300,300);
            mUIImageDatas.mPosX =rect_p[0];
            mUIImageDatas.mPosY =rect_p[1];
            mUIImageDatas.mShowWidth = rect_p[2];
            mUIImageDatas.mShowHeight =  rect_p[3];
        }
        var message = null;
        if (mUIImageDatas.trackIndex < 0) {

            if (mUIImageDatas.trackIndex === -1) {
                message = "背景图片渲染次序为负数";
            } else if (mUIImageDatas.trackIndex === -2) {
                message = "图片渲染次序为负数";
            }
        }

        message = mUIImageDatas;
        //console.log("22222222222222222::" + mUIImageDatas.trackIndex);
        //将数据添加列表中去
        this.mImageDatas.push(mUIImageDatas);
        if (callBack != null)
            callBack(mUIImageDatas.trackIndex, message);
    }

    //创建图像数据集，space,图片之间的间隙
    addImageDatas(numTotal,callBack)
    {
        var results=[];
        var vectorW=100*20;
        //console.log("开始执行多画板");
        var tmp=this;
        this.addImageData(UISelectType.BackgroundImage,function (code,result) {
            if(code>=0)
            {
                result.mImageBackground="#FFFFFF";
                result.mPosX = 100 * 20;
                result.mPosY = 100 * 20;
                result.mShowWidth = 600*20;
                result.mShowHeight = 400*20;
                result.bEnableShowRotationChange=false;
                result.bEnableShowSizeChange=false;
                //根据底图的大小及数量，确定几行几列
                var row=result.mShowWidth/vectorW;
                var col=result.mShowHeight/vectorW;
                results.push(result);
                //取最小值
                var max=Math.min(row, col);
                //根据数量确定每行的个数
                var row_num=numTotal;
                while (row_num>max)
                {
                    //console.log("是否跳出循环？::"+row_num);
                    row_num=Math.floor(row_num/2);
                }

                //console.log("是否跳出循环？"+row_num);

                var col_num=numTotal/row_num;
                if(numTotal%row_num!==0)
                {
                    col_num=col_num+1;
                }

                //确定每行的间隙
                var spacex=(result.mShowWidth-row_num*vectorW)/(row_num+1);
                //确定每列的间隙
                var spacey=(result.mShowHeight-col_num*vectorW)/(col_num+1);
                //开始生成控件
                for(var i=0;i<row_num;i++)
                    for(var j=0;j<col_num;j++)
                    {
                        var mUIImageDatas = new UIImageData();
                        mUIImageDatas.type = UISelectType.VectorImage;
                        mUIImageDatas.uuid = tmp.mUIIndex.generateIndex();
                        mUIImageDatas.rotation = 0;
                        mUIImageDatas.trackIndex = ElementBaseValue.getTrackIndex(mUIImageDatas, tmp.mImageDatas);
                        mUIImageDatas.mImageBackground="#0000FF";
                        mUIImageDatas.mShowWidth =vectorW;
                        mUIImageDatas.mShowHeight = vectorW;
                        mUIImageDatas.bEnableShowRotationChange=false;
                        mUIImageDatas.bEnableShowSizeChange=false;
                        mUIImageDatas.mPosX = result.mPosX+spacex*(i+1)+vectorW*i;
                        mUIImageDatas.mPosY =  result.mPosY+spacey*(j+1)+vectorW*j;
                        tmp.mImageDatas.push(mUIImageDatas);
                        results.push(mUIImageDatas);
                    }

                if(callBack!=null)
                {
                    callBack(code,results);
                }

            }else
            {
                if(callBack!=null)
                {
                    callBack(code,results);
                }
            }


        })
    }

    //通过底图，查找在底图上的向量图

    findVectorImagsDataInBackground(imageBackGroundData)
    {
        var result=[];
        if(imageBackGroundData.type===UISelectType.BackgroundImage)
        {

            var rect=[
                [imageBackGroundData.mPosX,imageBackGroundData.mPosY],
                [imageBackGroundData.mPosX+imageBackGroundData.mShowWidth,imageBackGroundData.mPosY],
                [imageBackGroundData.mPosX+imageBackGroundData.mShowWidth,imageBackGroundData.mPosY+imageBackGroundData.mShowHeight],
                [imageBackGroundData.mPosX,imageBackGroundData.mPosY+imageBackGroundData.mShowHeight]
            ];

            var position=[imageBackGroundData.mPosX,imageBackGroundData.mPosY];
            var size=[imageBackGroundData.mShowWidth,imageBackGroundData.mShowHeight];
            var rotation_angel = -(imageBackGroundData.rotation / 180) * Math.PI;

            for(var i=0;i<this.mImageDatas.length;i++)
            {

                var imageData=this.mImageDatas[i];
                if(imageData.type===UISelectType.VectorImage) {

                    var x = (imageData.mPosX);
                    var y = (imageData.mPosY);
                    var point = this.rotatePoint([x, y], position[0], position[1], size[0], size[1], rotation_angel);
                    if (this.isPointInsideShape([point[0], point[1]], rect)) {
                        result.push(imageData);
                    }
                }
            }
        }

        return result;


    }


    rotatePoint(point,dx,dy,dstw,dsth,rotation)
    {
        var x1 = point[0]-dx;
        var y1 = point[1]-dy;

        var cos = Math.cos(rotation);
        var sin = Math.sin(rotation);
        var cx=dstw/2;
        var cy=dsth/2;
        var xoffset=x1-cx;
        var yoffset=y1-cy;
        var x4 = xoffset * cos - yoffset * sin;
        var y4 = xoffset * sin + yoffset * cos;

        var x6=x4+cx+dx;
        var y6=y4+cy+dy;
        return [x6,y6];
    }

    isPointInsideShape(Point, triangle)
    {
        var nCross = 0;
        var nCount = triangle.length;
        var p = Point;
        for (var i = 0; i < nCount; i++)
        {
            var p1 = triangle[i];
            var p2 = triangle[(i + 1) % nCount];
            if (p1[1] === p2[1])
            {
                continue;
            }
            if (p[1] < Math.min(p1[1], p2[1]))
                continue;
            if (p[1] >= Math.max(p1[1], p2[1]))
                continue;
            var x = (p[1] - p1[1]) * (p2[0] - p1[0]) / (p2[1] - p1[1]) + p1[0];
            if (x > p[0])
                nCross = nCross + 1;
        }
        return (nCross % 2 === 1);
    }

    readFileFromSelect(fileSelect,callBack)
    {
        var reader = new FileReader();
        reader.onload = function(e) {
            const bytes = new Uint8Array(reader.result);
            if(callBack)
                callBack(0,bytes);
        };
        reader.readAsArrayBuffer(fileSelect);
    }

    readFileFromSelectToArrary(fileSelect,callBack)
    {
        var reader = new FileReader();
        reader.onload = function(e) {
            if(callBack)
                callBack(0,reader.result);
        };
        reader.readAsArrayBuffer(fileSelect);
    }

    base64ToImageBase64(base64,type)
    {
        return "data:"+type+";base64,"+base64;
    }

    bytesToBase64(bytes)
    {
        //console.log(bytes.length);
        var binaryString = '';
        for (var i = 0; i < bytes.length; i++) {
            binaryString += String.fromCharCode(bytes[i]);
        }
        // 使用btoa对二进制字符串进行Base64编码
        return btoa(binaryString);
    }
    readBlobByBinary(blob,callBack)
    {
        const reader = new FileReader();
// 定义读取完毕时的回调函数
        reader.onload = function(event) {
            if (event.target.result) {
                // 将读取的结果转换为ArrayBuffer
                const arrayBuffer = event.target.result;
                // 将ArrayBuffer转换为字节数组（Uint8Array）
                const uint8Array = new Uint8Array(arrayBuffer);
                // 处理字节数组
                //console.log("当前数据为：："+uint8Array); // 输出字节数组
                if(callBack!=null)
                    callBack(uint8Array);
            }
        };

// 以二进制形式读取Blob
        reader.readAsArrayBuffer(blob);
    }

    readBlobByArrary(blob,callBack)
    {
        const reader = new FileReader();
// 定义读取完毕时的回调函数
        reader.onload = function(event) {
            if (event.target.result) {
                // 将读取的结果转换为ArrayBuffer
                const arrayBuffer = event.target.result;
                // 将ArrayBuffer转换为字节数组（Uint8Array）
           //     const uint8Array = new Uint8Array(arrayBuffer);
                // 处理字节数组
                //console.log("当前数据为：："+uint8Array); // 输出字节数组
                if(callBack!=null)
                    callBack(arrayBuffer);
            }
        };

// 以二进制形式读取Blob
        reader.readAsArrayBuffer(blob);
    }

    uploadFileToArrary(bytes,fileName,url,callback) {
        var tmp=this;
        const file = new Blob([bytes]); // 获取选中的文件对象
        if (file) {
            var formData = new FormData(); // 创建表单数据对象
            formData.append('baseImage', file,fileName); // 添加要上传的文件到表单数据对象
            var xhr = new XMLHttpRequest(); // 创建XMLHttpRequest对象
            //console.log("当前上传的地址为1：："+url);
            xhr.open('POST', url); // 设置请求URL为服务器接收文件的路由或API
            xhr.responseType='blob';
            xhr.timeout=30*1000;
            xhr.onload = function () {
                const  blon=new Blob([xhr.response]);
                tmp.readBlobByArrary(blon,function (readDatas) {
                    if(callback!=null)
                        callback(2,readDatas);
                });
            };


            xhr.ontimeout = function() {
                if(callback!=null)
                    callback(-1,"请求超时");

                //console.log("请求超时");
            };

            xhr.onreadystatechange = function() {
                if (xhr.status === 200&&xhr.response&&xhr.readyState==4) {

                    //console.log("服务器返回：："+xhr.responseText);
                }else if(xhr.readyState == 4)
                {
                    if(callback!=null)
                        callback(-1,"服务器未响应");
                }
            };

            xhr.upload.addEventListener("progress", function(event) {
                if (event.lengthComputable) {
                    var percentComplete = event.loaded / event.total * 100; // 计算上传进度百分比
                    //console.log("上传进度： " + Math.round(percentComplete) + "%"); // 输出上传进度信息
                    if(callback!=null)
                        callback(1,percentComplete);
                }
            });

            xhr.send(formData); // 发送表单数据到服务器
        } else {
            if(callback!=null)
                callback(-1,"上传失败");
        }
    }

    uploadFileToUrl(bytes,fileName,url,callback) {
        var tmp=this;
        const file = new Blob([bytes]); // 获取选中的文件对象
        if (file) {
            var formData = new FormData(); // 创建表单数据对象
            formData.append('baseImage', file,fileName); // 添加要上传的文件到表单数据对象
            var xhr = new XMLHttpRequest(); // 创建XMLHttpRequest对象
            //console.log("当前上传的地址为1：："+url);
            xhr.open('POST', url); // 设置请求URL为服务器接收文件的路由或API
            xhr.responseType='blob';
            xhr.timeout=30*1000;
            xhr.onload = function () {
                const  blon=new Blob([xhr.response]);
                tmp.readBlobByBinary(blon,function (readDatas) {
                    if(callback!=null)
                        callback(2,readDatas);
                });
            };


            xhr.ontimeout = function() {
                if(callback!=null)
                    callback(-1,"请求超时");

                //console.log("请求超时");
            };

            xhr.onreadystatechange = function() {
                if (xhr.status === 200&&xhr.response&&xhr.readyState==4) {

                    //console.log("服务器返回：："+xhr.responseText);
                }else if(xhr.readyState == 4)
                {
                    if(callback!=null)
                        callback(-1,"服务器未响应");
                }
            };

            xhr.onerror = function() {
                if(callback!=null)
                    callback(-1,"连接被拒绝或者连接失败");
            };

            xhr.upload.addEventListener("progress", function(event) {
                if (event.lengthComputable) {
                    var percentComplete = event.loaded / event.total * 100; // 计算上传进度百分比
                    //console.log("上传进度： " + Math.round(percentComplete) + "%"); // 输出上传进度信息
                    if(callback!=null)
                        callback(1,percentComplete);
                }
            });

            xhr.send(formData); // 发送表单数据到服务器
        } else {
            if(callback!=null)
                callback(-1,"上传失败");
        }
    }
    splitByMultipleChars(str, chars) {
        // 将chars转换为正则表达式，并在字符类中添加了开始和结束锚点
        const charsRegexStr = chars.map(this.escapeRegExp).join('|');
        const regex = new RegExp(charsRegexStr, 'g');

        // 使用split()方法切割字符串
        return str.split(regex);
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getLastNameFromUrl(url)
    {
        if(url==null)
            return null;
        var splits=this.splitByMultipleChars(url,['/','\\']);
        var splitCount=splits.length-1;

        if(splits.length==0)
            return null;

        if(splits.length==1)
            return splits[0];
        var patnName=splits[splitCount];
        while (patnName==""&&splitCount>0)
        {
            splitCount--;
            patnName=splits[splitCount];
        }
        return patnName;
    }

    readFileFromUrlToFile(url,callback)
    {
        //从连接中获取文件名字
        var fileName=this.getLastNameFromUrl(url);
        var extend=fileName.substring(fileName.lastIndexOf('.')+1)
        try {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onprogress = function (e) {
                let percent = Math.floor(e.loaded / e.total * 100); // 下载进度
                if (callback)
                    callback(1, percent);
            };
            xhr.send();
            xhr.responseType = "blob";
            xhr.onload = function () {
                const  blob=new Blob([xhr.response]);
                const file = new File([blob], fileName);
                if(callback!=null)
                    callback(2,file);
            };
        }catch (e) {
            //console.log("当前的下载的状态为：：12113" );
            if (callback)
                callback(-1, e.toString());

        }
    }




    updateImage(imageFile,bsplit,bHigh,callBack)
    {
        var tmp=this;
        var file_back=null;
        var file_vector=null;
        var result=this.getSelectCurrentData();

        if(result==null)
        {
            if(callBack!=null)
                callBack(-1,"没有选中");
            return;
        }

        if(typeof imageFile==='string')
        {
            result.mImageUrl=imageFile;
            tmp.readFileFromUrlToFile(imageFile,function (rcode,rresult) {
                if(rcode==2)
                {
                    file_back=rresult;
                    //接下来请求深度图和分割图
                    tmp.setSelectCurrentData(result);
                    tmp.requireDepthAndSplitImage(file_back,bsplit,bHigh,function (dcode,dresult) {
                        if(dcode==0){

                            var imageData=result;
                            UIGraphics.instance.processImageData(imageData, function (ppcode, ppresult) {
                                if (ppcode == 0) {
                                    if (callBack != null)
                                        callBack(2, imageData);
                                } else if(ppcode>1)
                                {
                                    if (callBack != null)
                                        callBack(1, ppresult);
                                }else if (ppcode < 0) {
                                    if (callBack != null)
                                        callBack(-1, ppresult);
                                }
                            });
                        }
                        else if(dcode==1)
                        {
                            if(callBack!=null)
                                callBack(1,dresult);
                        }
                        else if(dcode<0)
                        {
                            if(callBack!=null)
                                callBack(-1,dresult);
                        }


                    });

                }else if(rcode==1)
                {
                    if(callBack!=null)
                        callBack(1,"正在下载");
                }else if(rcode<0)
                {
                    if(callBack!=null)
                        callBack(-1,rresult);
                }

            });
        }else
        {
            tmp.setSelectCurrentData(result);
            tmp.requireDepthAndSplitImage(imageFile,bsplit,bHigh,function (dcode,dresult) {
                if(dcode==0)
                {
                    //先生成灰度图
                    var imageData =  tmp.getSelectCurrentData();
                    //其次对深度，灰度，分割三种图像进行预处理
                    UIGraphics.instance.processImageData(imageData, function (ppcode, ppresult) {
                        if (ppcode == 0) {
                            if (callBack != null)
                                callBack(2, imageData);
                        }else if(ppcode>0)
                        {
                            if (callBack != null)
                                callBack(1, ppresult);
                        }
                        else if (ppcode < 0) {
                            if (callBack != null)
                                callBack(-1, ppresult);
                        }
                    });
                }else if(dcode==1)
                {
                    if(callBack!=null)
                        callBack(1,dresult);
                }
                else if(dcode<0)
                {
                    if(callBack!=null)
                        callBack(-1,dresult);
                }
            });
        }
    }

    addImage(type,imageFile,bsplit,bHigh,callBack)
    {
        //首先创建空数据
        var tmp=this;
        this.addImageData(type,function (code,result) {
            if(code>=0)
            {
                //请求深度图和分割图
                //如果是url，则先抓取，在处理
                var file_back=null;
                var file_vector=null;
                if(typeof imageFile==='string')
                {
                    result.mImageUrl=imageFile;
                    tmp.readFileFromUrlToFile(imageFile,function (rcode,rresult) {
                        if(rcode==2)
                        {
                            file_back=rresult;
                            //接下来请求深度图和分割图
                            tmp.setSelectCurrentData(result);
                            tmp.requireDepthAndSplitImage(file_back,bsplit,bHigh,function (dcode,dresult) {
                                if(dcode==0)
                                {
                                    //先生成灰度图
                                    var imageData = result;
                                    //其次对深度，灰度，分割三种图像进行预处理
                                    UIGraphics.instance.processImageData(imageData, function (ppcode, ppresult) {
                                        if (ppcode == 0) {
                                            if (callBack != null)
                                                callBack(2, imageData);
                                        }else if(ppcode>0)
                                        {
                                            if (callBack != null)
                                                callBack(1, ppresult);
                                        }
                                        else if (ppcode < 0) {
                                            if (callBack != null)
                                                callBack(-1, ppresult);
                                        }
                                    });
                                }else if(dcode==1)
                                {
                                    if(callBack!=null)
                                        callBack(1,dresult);
                                }
                                else if(dcode<0)
                                {
                                    if(callBack!=null)
                                        callBack(-1,dresult);
                                }


                            });

                        }else if(rcode==1)
                        {
                            if(callBack!=null)
                                callBack(1,"正在下载");
                        }else if(rcode<0)
                        {
                            if(callBack!=null)
                                callBack(-1,rresult);
                        }

                    });
                }else
                {
                    tmp.setSelectCurrentData(result);
                    tmp.requireDepthAndSplitImage(imageFile,bsplit,bHigh,function (dcode,dresult) {
                        if(dcode==0)
                        {
                            //先生成灰度图
                            var imageData =  tmp.getSelectCurrentData();
                            //其次对深度，灰度，分割三种图像进行预处理
                            UIGraphics.instance.processImageData(imageData, function (ppcode, ppresult) {
                                if (ppcode == 0) {
                                    if (callBack != null)
                                        callBack(2, imageData);
                                }else if(ppcode>0)
                                {
                                    if (callBack != null)
                                        callBack(1, ppresult);
                                }
                                else if (ppcode < 0) {
                                    if (callBack != null)
                                        callBack(-1, ppresult);
                                }
                            });
                        }else if(dcode==1)
                        {
                            if(callBack!=null)
                                callBack(1,dresult);
                        }
                        else if(dcode<0)
                        {
                            if(callBack!=null)
                                callBack(-1,dresult);
                        }
                    });
                }

            }else
            {
                if(callBack!=null)
                    callBack(-1,result);
            }
        });
    }

    addImagebyDirectUrl(type,imageFile,depthUrl, splitUrl,normalUrl,bsplit,callBack)
    {
        var tmp=this;
        this.addImageData(type,function (code,result) {
            var imageData = result;
            if(code>=0)
            {
                //请求深度图和分割图
                //如果是url，则先抓取，在处理
                var file_back=null;
                var file_vector=null;
                if(typeof imageFile==='string')
                {
                    tmp.readFileFromUrlToFile(imageFile,function (rcode,rresult) {
                        if(rcode==2)
                        {
                            file_back=rresult;
                            //接下来请求深度图和分割图
                            tmp.setSelectCurrentData(result);
                            console.log("22222222222222");
                            tmp.readFileFromUrlToFile(depthUrl,function (drcode,drresult) {

                                if(drcode==2)
                                {
                                    console.log("333333333333333333333333::"+normalUrl);

                                    tmp.readFileFromUrlToFile(normalUrl,function (nrcode,nrresult) {


                                        if(nrcode==2)
                                        {

                                            tmp.readFileFromSelect(nrresult,function (nrrcode,nrrresult) {

                                                if(nrrcode==0) {
                                                    imageData.normalData = nrrresult;
                                                    imageData.normalBase64Data = tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.normalData), "image/png");
                                                    console.log("kkkkkkkkkkkkk::" + nrrresult);
                                                    tmp.requireDepthAndSplitImageByUrl(file_back, drresult, null, false, function (dcode, dresult) {
                                                        if (dcode == 0) {
                                                            //先生成灰度图
                                                            UIGraphics.instance.loadGreyImageFromColorImage(imageData.srcData, imageData.mImageWidth, imageData.mImageHeight, function (greyCode, greyResult) {

                                                                if (greyCode == 0) {
                                                                    imageData.lightAverage = greyResult[0];
                                                                    imageData.greyData = greyResult[1];
                                                                    imageData.greyBase64Data = tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.greyData), "image/png");
                                                                    UIGraphics.instance.processImageData(imageData, function (ppcode, ppresult) {
                                                                        if (ppcode == 0) {
                                                                            if (callBack != null)
                                                                                callBack(2, imageData);
                                                                        } else if (ppcode < 0) {
                                                                            if (callBack != null)
                                                                                callBack(-1, ppresult);
                                                                        }
                                                                    });
                                                                } else {
                                                                    if (callBack != null)
                                                                        callBack(-1, greyResult);
                                                                }


                                                            });
                                                        } else if (dcode == 1) {
                                                            if (callBack != null)
                                                                callBack(1, dresult);
                                                        } else if (dcode < 0) {
                                                            if (callBack != null)
                                                                callBack(-1, dresult);
                                                        }

                                                    });
                                                }


                                            });



                                        }
                                    });

                                }else if(drcode==1)
                                {
                                    if(callBack!=null)
                                        callBack(1,"正在下载深度图");
                                }else if(drcode<0)
                                {
                                    if(callBack!=null)
                                        callBack(-1,drresult);
                                }

                            });




                        }else if(rcode==1)
                        {
                            if(callBack!=null)
                                callBack(1,"正在下载");
                        }else if(rcode<0)
                        {
                            if(callBack!=null)
                                callBack(-1,rresult);
                        }

                    });
                }else
                {
                    tmp.setSelectCurrentData(result);
                    tmp.requireDepthAndSplitImageByUrl(imageFile,depthUrl, splitUrl,bsplit,function (dcode,dresult) {
                        if(dcode==0)
                        {
                            //先生成灰度图
                            var imageData =  tmp.getSelectCurrentData();
                            UIGraphics.instance.loadGreyImageFromColorImage(imageData.srcData,imageData.mImageWidth,imageData.mImageHeight,function (greyCode,greyResult) {

                                if(greyCode==0)
                                {
                                    imageData.lightAverage=greyResult[0];
                                    imageData.greyData =greyResult[1];
                                    imageData.greyBase64Data=tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.greyData),"image/png");
                                    UIGraphics.instance.processImageData(imageData, function (ppcode, ppresult) {
                                        if (ppcode == 0) {
                                            if (callBack != null)
                                                callBack(2, imageData);
                                        } else if (ppcode < 0) {
                                            if (callBack != null)
                                                callBack(-1, ppresult);
                                        }
                                    });
                                }else
                                {
                                    if(callBack!=null)
                                        callBack(-1,greyResult);
                                }


                            });
                        }else if(dcode==1)
                        {
                            if(callBack!=null)
                                callBack(1,dresult);
                        }
                        else if(dcode<0)
                        {
                            if(callBack!=null)
                                callBack(-1,dresult);
                        }
                    });
                }

            }else
            {
                if(callBack!=null)
                    callBack(-1,result);
            }
        });
    }




    requireDepthAndSplitImageByUrl(file, depthUrl, splitUrl,bsplit, callBack) {
        var tmp = this;
        this.readFileFromSelect(file, function (code, result) {
            var imageData = tmp.getSelectCurrentData();
            console.log("44444444444444444"+imageData);
            imageData.mImageType = file.type;
            imageData.mImageName = file.name;
            imageData.srcData = result;
            imageData.srcBase64Data = tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.srcData), imageData?.mImageType);
            var image = document.createElement("img")
            image.src = imageData.srcBase64Data;
            image.style.position='absolute';
            image.style.display='none';
            image.style.left="0px";
            image.style.top='0px';
            image.onload = function () {
                imageData.mImageWidth = image.naturalWidth;
                imageData.mImageHeight = image.naturalHeight;
                imageData.mRatio = imageData.mImageWidth / imageData.mImageHeight;
                imageData.changeShowWidthAndHeight();
                image.remove();
                console.log("555555555555555555"+imageData);
                if (imageData.type === UISelectType.BackgroundImage) {
                    //加载深度图
                    //如果是背景图片，则加载深度图，由于未接接口，暂时按字符串进行匹配
                    //这里请求深度图
                    console.log("6666666666666666666666"+imageData+"::"+depthUrl);
                    tmp.readFileFromSelect(depthUrl, function (require_code, require_result) {
                        if (require_code === 0) {
                            //获取深度图的url，读取并且
                            console.log("777777777777777777777777777");
                            imageData.depthData = require_result;
                            imageData.depthBase64Data = tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.depthData), "image/png");
                            //这里去获取分割图
                            if(bsplit) {
                                console.log("8888888888888888888888888888888888888");
                                UIMattingImage.instance.requireImageMatting(imageData.srcData, imageData.mImageName, function (split_code, split_result) {
                                    if (split_code === 2) {
                                        //获取分割图的下载地址
                                        console.log("1111111111111111111111111111111111111111");
                                        imageData.maskData = split_result;
                                        imageData.maskBase64Data = tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.maskData), "image/png");
                                        if (callBack != null)
                                            callBack(0, "获取成功");
                                    } else if(split_code==1){

                                        if (callBack != null)
                                            callBack(1, "正在等待分割图返回");
                                    }else if(split_code<0)
                                    {
                                        if (callBack != null)
                                            callBack(-1, "生成分割图出现错误");
                                    }
                                });
                            }else {

                                if (callBack != null)
                                    callBack(0, imageData);
                            }
                        } else if(require_code==1){
                            if(callBack!=null)
                                callBack(1,"正在等待深度图返回");
                        }else if(require_code<0)
                        {
                            if (callBack != null)
                                callBack(-1, "获取深度图失败");
                        }
                    });
                } else {

                    if(callBack!=null)
                        callBack(0,"获取成功");
                }
            };
            document.body.appendChild(image);

        });
    }


    //请求获取深度图及灰度图，分割图
    requireDepthAndSplitImage(file,bsplit, bHigh,callBack) {
        var tmp = this;
        this.readFileFromSelect(file, function (code, result) {
            var imageData = tmp.getSelectCurrentData();
            imageData.mImageType = file.type;
            imageData.mImageName = file.name;
            imageData.srcData = result;
            imageData.srcBase64Data = tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.srcData), imageData?.mImageType);
            var image = document.createElement("img")
            image.src = imageData.srcBase64Data;
            image.style.position='absolute';
            image.style.display='none';
            image.style.left="0px";
            image.style.top='0px';
            image.onload = function () {
                imageData.mImageWidth = image.naturalWidth;
                imageData.mImageHeight = image.naturalHeight;
                imageData.mRatio = imageData.mImageWidth / imageData.mImageHeight;
                imageData.changeShowWidthAndHeight();
                image.remove();
                if(callBack!=null)
                    callBack(0,"获取成功");
            };
            document.body.appendChild(image);

        });
    }

    transformImageTypeInSelect(bsplit,callBack)
    {
        var imageData = this.getSelectCurrentData();
        if(imageData==null)
        {
            if(callBack!=null)
            {
                callBack(-1,"没有选中");
            }
            return;
        }

        if(imageData.type==UISelectType.BackgroundImage)
        {
            imageData.type=UISelectType.VectorImage;
            imageData.trackIndex=this.recomputeTrackIndex(imageData);
            //重新排序
            ElementBaseValue.sortByTrackIndex(this.getAllImageData());
            if(callBack!=null)
            {
                callBack(0,"转换成功");
            }
            return;
        }else if(imageData.type==UISelectType.VectorImage)
        {

            var tmp=this;
            imageData.type=UISelectType.BackgroundImage;
            UIGraphics.instance.processImageData(imageData,function(ppcode,ppresult){
                if(ppcode==0)
                {
                    imageData.type=UISelectType.BackgroundImage;
                    imageData.shapeOffset=0;
                    imageData.trackIndex=tmp.recomputeTrackIndex(imageData);
                    //重新排序
                    ElementBaseValue.sortByTrackIndex(tmp.getAllImageData());
                    if(callBack!=null)
                        callBack(0,imageData);
                }else if(ppcode>0)
                {
                    if(callBack!=null)
                        callBack(1,ppresult);
                }
                else if(ppcode<0)
                {
                    imageData.type=UISelectType.VectorImage;
                    if(callBack!=null)
                        callBack(-1,ppresult);
                }
            });

        }


    }

}
