import {UIGraphicsState} from "./UIGraphicsState";
import {UIAlignGraphics} from "./UIAlignGraphics";
import {UIDrawGraphics} from "./UIDrawGraphics";
import {UIEraseGraphics} from "./UIEraseGraphics";
import {UICoordinateView} from "../Ruler/UICoordinateView";

import {UISorptionBoundLines} from "../../Data/UISorptionBoundLines";
// import CanvasKitInit, {PaintStyle} from "canvaskit-wasm";
// import CanvasKitWasm from "canvaskit-wasm/bin/canvaskit.wasm";
import {UISelectType} from "../../Data/UISelectType";
import {ProjectDataManager} from "../../Data/ProjectDataManager";
import {ShowFrameLayout} from "../uishow/ShowFrameLayout";
import {UIMattingImage} from "./UIMattingImage";
import WebWorker from "./worker";
import {UIDepthImage} from "./UIDepthImage";
export  class UIGraphics {
    constructor() {
        if (!UIGraphics.instance) {
            this.parent = null;
            this.uiGraphicsState=UIGraphicsState.None;
            this.drawDatas=[];
            this.alignDraw=false;
            this.timer=null;
            this.mLastEnableMove=false;//上一个是否能移动；
            this.isMouseDrawing=false;//是否在鼠标绘制；
            this.childThreadCallBack=null;
            new UIDepthImage();
            UIGraphics.instance = this;
        } else {
            return UIGraphics.instance;
        }
    }
    //传入div id
    init(parentDiv,CanvasKitInit,CanvasKitWasm,callBack)
    {
        this.parent=parentDiv;
        var uiAlignGraphics=new UIAlignGraphics();
        var uiDrawGraphics=new UIDrawGraphics();
        var uiSorptionBoundLines= new UISorptionBoundLines();
        var uiEraseGraphics = new UIEraseGraphics();
        this.myWorker=null;
        var tmp=this;
        var uiCoordinateView=new UICoordinateView();

        setTimeout(()=>{
            uiDrawGraphics.init(this.parent,CanvasKitInit,CanvasKitWasm,function () {

                tmp.initTimer();
                uiAlignGraphics.init(uiDrawGraphics.CK,uiDrawGraphics.surface);
                if(callBack!=null)
                    callBack(0,"加载成功");
            });
        },1000);
    }

    sendMessageToChildWorks(sendData,callBack)
    {
        var myWorker = new WebWorker();
        myWorker.onmessage = function (event) {
            var message= event.data;
            if(callBack!=null)
            {
                callBack(message.cmd,message.data);
            }
        };
        myWorker.postMessage(sendData);
    }


    initTimer()
    {
        var tmp=this;

        if(this.timer!=null) {
            clearInterval(this.timer);
            this.timer=null;
        }
        //绘制定时器
        this.timer=setInterval(function () {

            if(tmp.uiGraphicsState!=UIGraphicsState.Drawing&&tmp.drawDatas.length>0)
            {
               //console.log("是否在执行绘制操作::"+tmp.drawDatas.length);
                tmp.uiGraphicsState=UIGraphicsState.Drawing;
                var data=  tmp.drawDatas[tmp.drawDatas.length-1];
                ShowFrameLayout.instance.updateEvent();
                tmp.processDrawRequireDatas(data,function () {
                    tmp.uiGraphicsState=UIGraphicsState.End;
                    tmp.drawDatas.splice(0,1);
                    if(tmp.drawDatas.length>4);
                    {
                        var remain=tmp.drawDatas.length-4;
                        tmp.drawDatas.splice(0,remain);
                    }
                   //console.log("updateEraseDraw11::测试是否执行");
                    //绘制辅助线
                    tmp.startAlignLineDraw();
                    //更新擦除面板和遮罩面板UI更新
                    tmp.updateEraseDraw();
                    //通知上层

                })

            }else if(tmp.drawDatas.length>0)
            {
                if(tmp.drawDatas.length>4);
                {
                    var remain=tmp.drawDatas.length-4;
                    tmp.drawDatas.splice(0,remain);
                }
            }


        },30);
    }

    startAlignLineDraw()
    {
        if(this.alignDraw)
        {
            var select=ProjectDataManager.instance.getSelectCurrentData();
            if(select!=null) {
                var line_datas = UISorptionBoundLines.instance.sorptionBoundLines(select.mPosX, select.mPosY, select, ProjectDataManager.instance.getAllImageData(), 100);
               //console.log("输出的的对象为：："+line_datas.length);
                UIAlignGraphics.instance.drawAlignLines(line_datas,UICoordinateView.instance);
            }
        }
    }

    switchAlignLineDrawState(bAlignDraw)
    {
        this.alignDraw=bAlignDraw;
    }

    drawExrData(srcData)
    {
        UIDrawGraphics.instance.drawExrData(srcData);
    }





    loadGreyImageFromColorImage(oriImageData,imageWidth,imageHeight,callBack)
    {
        var result=UIDrawGraphics.instance.loadGreyImageFromColorImage(oriImageData,imageWidth,imageHeight);
        //获取灰度值的均值,读取字节数
        var bytes = UIDrawGraphics.instance.extractOriDataFormImage(result[0],imageWidth,imageHeight);
        //获取灰度图的均值

        if(result==null||result[1]==null)
        {
            if(callBack!=null)
            {
                callBack(-1,"获取灰度图失败");
            }
        }



        this.sendMessageToChildWorks({cmd:"processGreyImageAverg",data:bytes},function (greyCode,childResult) {

            if(greyCode=="getGreyImageAverg")
            {
                if(callBack!=null)
                {
                    callBack(0,[childResult,result[1]]);
                }
            }

        });
    }

    loadGreyImageFromColorImageAndByte(oriImageData,imageWidth,imageHeight)
    {
        return UIDrawGraphics.instance.loadGreyImageFromColorImage(oriImageData,imageWidth,imageHeight);
    }

    base64ToImageBase64(base64,type)
    {
        return "data:"+type+";base64,"+base64;
    }

    bytesToBase64(bytes)
    {
        //console.log(bytes.length);
        var binaryString = '';
        for (var i = 0; i < bytes.length; i++) {
            binaryString += String.fromCharCode(bytes[i]);
        }
        // 使用btoa对二进制字符串进行Base64编码
        return btoa(binaryString);
    }

    bytesToImageBase64(bytes,type)
    {
       return this.base64ToImageBase64(this.bytesToBase64(bytes),type);
    }

    processHighImageData(imageData,callBack)
    {
        var tmp=this;
        if(imageData.type==UISelectType.BackgroundImage) {

            //首先计算根据灰度图提取绘图度原始数据
            var results = this.loadGreyImageFromColorImageAndByte(imageData.srcData,imageData.mImageWidth,imageData.mImageHeight);
            var greyImage=results[0];
            //读取原始数据
            var oriGreyData = UIDrawGraphics.instance.extractOriDataFormImage(greyImage,imageData.mImageWidth,imageData.mImageHeight);
            //获取深度exr数据


            imageData.greyData =results[1];
            imageData.greyBase64Data = this.base64ToImageBase64( this.bytesToBase64(imageData?.greyData), "image/png");
            //采用子线程去处理深度图和灰度图
            this.sendMessageToChildWorks({
                cmd:"processDepthAndGrey",
                greyOriData:oriGreyData,
                imageWidth:imageData.mImageWidth,
                imageHeight:imageData.mImageHeight,
                exrData:imageData.exrData
            },function (code,message) {

                if(code==0)
                {
                    var min=message[0];
                    var max=message[1];
                    var center=message[2];
                    var depthDataRgba=message[3];
                    //将原始数据封装成image,然后编码成png格式的图
                    imageData.depthData=UIDrawGraphics.instance.transformRGBADataToImagePngData(depthDataRgba,imageData.mImageWidth,imageData.mImageHeight);
                    imageData.depthBase64Data= tmp.base64ToImageBase64( tmp.bytesToBase64(imageData?.depthData), "image/png");

                    //得到效果图

                    UIDrawGraphics.instance.loadHighEffectImageData(imageData.uuid,imageData.depthData, imageData.maskData, imageData.mImageWidth, imageData.mImageHeight, function (code, result) {
                        if (code == 0) {
                            if (imageData.uuid == result.uuid) {
                                imageData.effectMaps = result.maps;
                                //将原图数据封装成绘制数据
                                imageData.drawImageData = UIDrawGraphics.instance.transformSrcDataToImage(imageData.srcData);

                                if (callBack != null)
                                    callBack(0, "处理成功");
                            } else {
                                if (callBack != null)
                                    callBack(0, "处理异常，uuid不一致");
                            }

                        } else {
                            //这里返回错误码
                            if (callBack != null)
                                callBack(-1, result);
                        }
                    });



                }


            });
        }else
        {
            imageData.drawImageData = UIDrawGraphics.instance.transformSrcDataToImage(imageData.srcData);
            if (callBack != null)
                callBack(0, "处理成功");

        }
    }

    //请求百度云抠图，得到前景图片和背景图片


    loadNormalImageFromColorImage(depthImage,imageWidth,imageHeight,callBack)
    {
        var bytes=UIDrawGraphics.instance.extractOriDataFormImage(depthImage,imageWidth,imageHeight);
        this.sendMessageToChildWorks({cmd:"processImageNormalMap",data:bytes,imageWidth:imageWidth,imageHeight:imageHeight},function (normalCode,normalResult){

            if(normalCode=="getImageNormalMap")
            {
                //将字节数转image
                var normalImageResults = UIDrawGraphics.instance.blurNormalImage(normalResult.data,normalResult.width,normalResult.height);
                if(callBack!=null)
                    callBack(0,normalImageResults);

            }else
            {
                if(callBack!=null)
                    callBack(-1,"生成法线贴图出错");
            }


        });

    }



    processImageData(imageData,callBack)
    {
        var tmp=this;
        if(imageData.type==UISelectType.BackgroundImage) {

            imageData.drawImageData = UIDrawGraphics.instance.transformSrcDataToImage(imageData.srcData);
            //获取原图的灰度信息
            var greyDatas = UIDrawGraphics.instance.loadGreyImageFromColorImage(imageData.srcData,imageData.mImageWidth,imageData.mImageHeight);
            imageData.greyData=greyDatas[1];
            imageData.greyBase64Data=tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.greyData), "image/png");
            var greyImage=greyDatas[0];
            var effects=[];
            //提取亮度的参数
            var lightBytes = UIDrawGraphics.instance.extractOriDataFormImage(greyImage,imageData.mImageWidth,imageData.mImageHeight);
            //获取灰度图的均值
            this.sendMessageToChildWorks({cmd:"processGreyImageAverg",data:lightBytes},function (greyCode,childResult) {

                if(greyCode=="getGreyImageAverg")
                {
                    imageData.lightAverage=childResult;
                    //请求得到分割的遮罩
                    UIMattingImage.instance.requireImageMatting(imageData.srcData,imageData.mImageName,function (code,result) {

                        if(code==2)
                        {

                            //通过遮罩得到前景图像和背景图像
                            imageData.maskData=result;
                            imageData.maskBase64Data= tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.maskData), "image/png");
                            var maskImage=UIDrawGraphics.instance.transformSrcDataToImage(imageData.maskData);
                            //分割
                            //背景请求深度
                            var background = UIDrawGraphics.instance.splitSingleEffectImageData(imageData.uuid,imageData.srcData,imageData.maskData,imageData.mImageWidth,imageData.mImageHeight,-1);
                            UIDepthImage.instance.requireDepthImage(background.imageBytes,imageData.mImageName,function (bcode,bresult) {

                                if(bcode==2)
                                {
                                    background.depthImage=UIDrawGraphics.instance.transformSrcDataToImage(bresult);
                                    background.depthData=bresult;
                                    //计算得到法向量
                                    UIGraphics.instance.loadNormalImageFromColorImage(background.depthImage,imageData.mImageWidth,imageData.mImageHeight,function (normalCode,normalResult) {
                                        if(normalCode==0) {
                                            background.normalImage = normalResult[0];
                                            background.normalBytes = normalResult[1];
                                            //开始融合深度信息和灰度信息
                                            var bmergeResult = UIDrawGraphics.instance.merageEffectImageData(greyImage, background.depthImage, maskImage, -1, imageData.mImageWidth, imageData.mImageHeight);
                                            background.effectImage = bmergeResult[0];
                                            background.effectBytes = bmergeResult[1];
                                            effects.push(background);
                                            //开始处理前景图像
                                            var foreground = UIDrawGraphics.instance.splitSingleEffectImageData(imageData.uuid, imageData.srcData, imageData.maskData, imageData.mImageWidth, imageData.mImageHeight, 0);
                                            //前景需要得到边界，获取边界
                                            var foregroundBytes = UIDrawGraphics.instance.extractOriDataFormImage(foreground.image, imageData.mImageWidth, imageData.mImageHeight);
                                            console.log(foregroundBytes);

                                            //将字节送到子线程获取边界
                                            tmp.sendMessageToChildWorks({
                                                cmd: "processImageBoundes",
                                                data: foregroundBytes,
                                                imageWidth: imageData.mImageWidth,
                                                imageHeight: imageData.mImageHeight,
                                            }, function (p, r) {

                                                if (p == "getImageBoundes") {
                                                    foreground.bounds = r;
                                                    console.log("当前边界为：："+r);
                                                    //得到边界之后进行裁剪
                                                    var clipPngBytes = UIDrawGraphics.instance.clipImageData(foreground.image, foreground.bounds);
                                                    //裁剪之后将请求得到深度图
                                                    UIDepthImage.instance.requireDepthImage(clipPngBytes, imageData.mImageName, function (fdcode, fdresult) {
                                                        if (fdcode == 2) {
                                                            foreground.depthImage = UIDrawGraphics.instance.merageBlackImageData(fdresult, imageData.mImageWidth, imageData.mImageHeight, foreground.bounds);
                                                            foreground.depthData = UIDrawGraphics.instance.extractPngDataFormImage(foreground.depthImage);
                                                            //获取法向量
                                                            UIGraphics.instance.loadNormalImageFromColorImage(foreground.depthImage, imageData.mImageWidth, imageData.mImageHeight,function (fncode,fnresult) {
                                                                if(fncode==0)
                                                                {
                                                                    foreground.normalImage = fnresult[0];
                                                                    foreground.normalBytes = fnresult[1];

                                                                    //开始融合深度信息和灰度信息
                                                                    var fmergeResult = UIDrawGraphics.instance.merageEffectImageData(greyImage, foreground.depthImage, maskImage, 0, imageData.mImageWidth, imageData.mImageHeight);
                                                                    foreground.effectImage = fmergeResult[0];
                                                                    foreground.effectBytes = fmergeResult[1];
                                                                    effects.push(foreground);
                                                                    imageData.effectMaps = effects;
                                                                    if (callBack != null)
                                                                        callBack(0, "处理成功");
                                                                }else
                                                                {
                                                                    if (callBack != null)
                                                                        callBack(0, "提取法线出错");
                                                                }


                                                            });


                                                        } else if (fdcode == 1) {
                                                            if (callBack != null)
                                                                callBack(1, "正在处理前景深度中..");
                                                        } else if (fdcode < 0) {
                                                            if (callBack != null)
                                                                callBack(-1, "正在处理前景失败");
                                                        }

                                                    });
                                                } else {
                                                    if (callBack != null)
                                                        callBack(-1, "得到边界失败");
                                                }


                                            })
                                        }else
                                        {
                                            if (callBack != null)
                                                callBack(-1, normalResult);
                                        }

                                    });






                                }else if(bcode==1)
                                {
                                    if (callBack != null)
                                        callBack(1, "正在处理背景深度中..");
                                }
                                else if(bcode<0)
                                {
                                    if (callBack != null)
                                        callBack(-1, "正在处理背景失败");
                                }
                            });
                        }else if(code>0)
                        {
                            if (callBack != null)
                                callBack(1, "正在分割中..");
                        }
                        else if(code<0)
                        {
                            if (callBack != null)
                                callBack(-1, "分割失败");
                        }



                    });
                }else
                {
                    if (callBack != null)
                        callBack(-1, "提取灯光信息失败");
                }

            });





       }else
        {
            imageData.drawImageData = UIDrawGraphics.instance.transformSrcDataToImage(imageData.srcData);
            if (callBack != null)
                callBack(0, "处理成功");

        }
    };

    startEraseDraw(callBack)
    {
        var select=ProjectDataManager.instance.getSelectCurrentData();
        if(select==null)
        {
            if(callBack!=null)
                callBack(-1,"没有选中任何图片")
            return;
        }

        var eraseDrawData = this.tranformImageDataToDrawData(select);
        var tmp=this;
        UIEraseGraphics.instance.init(UIDrawGraphics.instance.parent,eraseDrawData,function (code,result) {

            if(code==0)
            {
                tmp.isMouseDrawing=true;
                //暂时锁住所选的图片移动属性
                tmp.mLastEnableMove=select.bEnableShowMove;
                select.bEnableShowMove=false;
                if(callBack!=null)
                    callBack(0,"打开成功");
                tmp.updateDrawImage();
            }else
            {
                if(callBack!=null)
                    callBack(-1,result);
            }
        });
    }

    switchEraseType(type)
    {

        UIEraseGraphics.instance.switchDrawType(type);
    }

    getEraseType()
    {
        return UIEraseGraphics.instance.drawType;
    }




    updateEraseDraw()
    {

        var select=ProjectDataManager.instance.getSelectCurrentData();
        if(select==null)
            return;

       //console.log("updateEraseDraw::测试是否执行");
        var imageData = this.tranformImageDataToDrawData(select);

       //console.log(imageData);

        UIEraseGraphics.instance.updateUI(imageData);
    }

    stopEraseDraw(callBack)
    {
        this.isMouseDrawing=false;

        var tmp=this;
        UIEraseGraphics.instance.saveCanvasToImage(function (code,result) {
            //通过uuid将数据传递回去
            //测试
            var data=ProjectDataManager.instance.getImageDataByUuid(code);
            if(data==null)
            {
                if(callBack!=null)
                    callBack(-1,"没有查询到任何图片");
                return;
            }

            var base64=tmp.imageBase64ToBase64(result,"image/png");
            var bytes=tmp.base64ToByteArray(base64);

            //解除位置移动锁定
            data.bEnableShowMove=tmp.mLastEnableMove;
            data.eraseImageBase64Data=result;
            data.eraseDrawImageData=UIDrawGraphics.instance.transformSrcDataToImage(bytes);
            tmp.updateDrawImage();
            if(callBack!=null)
                callBack(0,"保存成功");
        });
    }

    getEraseDrawState()
    {
        return this.isMouseDrawing;
    }

    setEraseDrawLineWidth(width)
    {
        UIEraseGraphics.instance.setLineWidth(width);
    }




    processDrawDataToShader(datas)
    {
        //获取所有背景图
        var backgrounds=[];
        //获取所有的向量图
        var vectors=[];
        //将上层数据类转变成绘制数据类格式
        for(var i=0;i<datas.length;i++)
        {
            if(datas[i].type==1)
            {
                var drawData=this.tranformImageDataToDrawData(datas[i]);
                backgrounds.push(drawData);

            }else  if(datas[i].type==2)
            {
                var drawData=this.tranformImageDataToDrawData(datas[i]);
                vectors.push(drawData);
            }
        }
        return UIDrawGraphics.instance.processImagesToShader(vectors,backgrounds);
    }

    updateDrawImage()
    {
        var datas =ProjectDataManager.instance.getAllImageData();
        this.drawDatas.push(datas);
    }
    processDrawRequireDatas(datas,callBack)
    {
        //获取所有背景图
        var backgrounds=[];
        //获取所有的向量图
        var vectors=[];
        //将上层数据类转变成绘制数据类格式
        var mOriginVectorData=[];
        for(var i=0;i<datas.length;i++)
        {
            if(datas[i].type==1)
            {
                var drawData=this.tranformImageDataToDrawData(datas[i]);
                backgrounds.push(drawData);

            }else  if(datas[i].type==2)
            {
                var drawData=this.tranformImageDataToDrawData(datas[i]);
                vectors.push(drawData);
                mOriginVectorData.push(datas[i]);
            }
        }
        //通知渲染器进行绘制
        UIDrawGraphics.instance.updateDrawCallBack(vectors,backgrounds,callBack);
        //这里需要通知上层可选框更改大小
        for(var i=0;i<mOriginVectorData.length;i++)
        {
            mOriginVectorData[i].shapeOffset=vectors[i].shapeOffset;
        }
    }

    processDrawRequire()
    {
        //获取所有的图片信息
        var datas = ProjectDataManager.instance.getAllImageData();
       //获取所有背景图
        var backgrounds=[];
        //获取所有的向量图
        var vectors=[];
        //将上层数据类转变成绘制数据类格式
        for(var i=0;i<datas.length;i++)
        {
            if(datas[i].type==1)
            {
                var drawData=this.tranformImageDataToDrawData(datas[i]);
                backgrounds.push(drawData);

            }else  if(datas[i].type==2)
            {
                var drawData=this.tranformImageDataToDrawData(datas[i]);
                vectors.push(drawData);
            }
        }
        //通知渲染器进行绘制
        UIDrawGraphics.instance.updateDraw(vectors,backgrounds);
    }

    tranformImageDataToDrawData(imageData)
    {
        var position = UICoordinateView.instance.position2px(imageData.mPosX,imageData.mPosY);
        var size = UICoordinateView.instance.size2px(imageData.mShowWidth,imageData.mShowHeight);
        var color=[255,255,255,255];
        if(imageData.type==1)
        {
            color=[0,255,255,255];
        }else if(imageData.type==2)
        {
            color=[255,0,255,255];
        }

        const uiGraphicsData=
            {
                type:imageData.type,
                uuid:imageData.uuid,
                colors:color,
                dstx:position[0],
                dsty:position[1],
                dstWidth:size[0],
                dstHeight:size[1],
                dstAngel:imageData.rotation,
                oriWidth:imageData.mImageWidth,
                oriHeight:imageData.mImageHeight,
                srcData:imageData.srcData,
                eraseImageBase64Data:imageData.eraseImageBase64Data,
                eraseDrawImageData:imageData.eraseDrawImageData,
                eraseSize:imageData.eraseSize,
                alphaVectorColor:[0.9,0.9,0.9],
                effectImages:imageData.effectMaps,
                scaleEffectX:imageData.scaleEffect,
                scaleEffectY:imageData.scaleEffect,
                drawImageData:imageData.drawImageData,
                shapeOffset:imageData.shapeOffset,
                lightAverage:imageData.lightAverage
            }

        return uiGraphicsData;
    }

    saveImage(fileName,callBack)
    {
        var tmp=this;
        var selectData=ProjectDataManager.instance.getSelectCurrentData();
        if(selectData==null)
        {
            if(callBack!=null)
                callBack(-1,"没有选中");

            return;
        }

        if(selectData.type==2)
        {
            var drawVectorData=this.tranformImageDataToDrawData(selectData);
            drawVectorData.dstx=0;
            drawVectorData.dsty=0;
            drawVectorData.dstAngel=0;
            drawVectorData.dstWidth=drawVectorData.oriWidth;
            drawVectorData.dstHeight=drawVectorData.oriHeight;

            UIDrawGraphics.instance.saveToPngBytesInOrigin(drawVectorData,[],function (code,result) {

                if(code==0)
                {
                    tmp.saveBinaryDataToFile(result,fileName);
                    if(callBack!=null)
                        callBack(0,fileName);
                }else
                {
                    if(callBack!=null)
                        callBack(-1,result);
                }

            });
        }else if(selectData.type==1)
        {
            var drawBackData=this.tranformImageDataToDrawData(selectData);
            //先算缩放值
            var scaleX=drawBackData.oriWidth/drawBackData.dstWidth;
            var scaleY=drawBackData.oriHeight/drawBackData.dstHeight;

            //算出平移值
            var vectors=[];
            var datas=ProjectDataManager.instance.getAllImageData();
            for(var i=0;i<datas.length;i++)
            {
                if(datas[i].type==2)
                {
                    var drawVectorData=this.tranformImageDataToDrawData(datas[i]);


                    //先缩放

                    drawVectorData.dstAngel=drawVectorData.dstAngel-drawBackData.dstAngel;

                    var point = UIDrawGraphics.instance.transformPoint([drawVectorData.dstx,drawVectorData.dsty],[drawVectorData.dstx,drawVectorData.dsty],
                        0,
                        scaleX,
                        scaleY, 0,
                        0,drawVectorData.dstWidth,drawVectorData.dstHeight);

                    drawVectorData.dstWidth=drawVectorData.dstWidth*scaleX;
                    drawVectorData.dstHeight=drawVectorData.dstHeight*scaleY;

                    var point2 = UIDrawGraphics.instance.transformPoint(point,[drawBackData.dstx,drawBackData.dsty],
                        -(drawBackData.dstAngel/180)*Math.PI,
                        scaleX,
                        scaleY, -drawBackData.dstx,
                        -drawBackData.dsty,drawBackData.dstWidth,drawBackData.dstHeight);
                    drawVectorData.dstx=point2[0];
                    drawVectorData.dsty=point2[1];

                    vectors.push(drawVectorData);
                }
            }

            //修改背景图的数据
            drawBackData.dstx=0;
            drawBackData.dsty=0;
            drawBackData.dstAngel=0;
            drawBackData.dstWidth=drawBackData.oriWidth;
            drawBackData.dstHeight=drawBackData.oriHeight;

            UIDrawGraphics.instance.saveToPngBytesInOrigin(drawBackData,vectors,function (code,result) {

                if(code==0)
                {
                    tmp.saveBinaryDataToFile(result,fileName);
                    if(callBack!=null)
                        callBack(0,fileName);
                }else
                {
                    if(callBack!=null)
                        callBack(-1,result);
                }

            });
        }
    }

    //对选中的图片进行抠图
    mattingSelectImageByPixel(callBack)
    {
        var imageData=ProjectDataManager.instance.getSelectCurrentData();
        if(imageData==null)
        {
            if(callBack!=null)
                callBack(-1,"没有选中任何图片");
            return;
        }

        if(imageData.type!=UISelectType.VectorImage)
        {
            if(callBack!=null)
                callBack(-1,"该类型不支持抠图操作");
            return;
        }


        var tmp=this;
        UIMattingImage.instance.requireImageMatting(imageData.srcData,imageData.mImageName,function (split_code,split_result) {

            if(split_code==2)
            {
                console.log(split_result);
                imageData.maskData=split_result;
                imageData.maskBase64Data=tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.maskData));
                imageData.srcData = UIDrawGraphics.instance.mattingSingleEffectImageData(imageData.uuid,imageData.srcData,imageData.maskData,imageData.mImageWidth,imageData.mImageHeight);
                imageData.srcBase64Data=tmp.base64ToImageBase64(tmp.bytesToBase64(imageData?.srcData));
                imageData.drawImageData=UIDrawGraphics.instance.transformSrcDataToImage(imageData.srcData);
                if(callBack!=null)
                {
                    callBack(0,imageData);
                }
            }else
            {
                if(callBack!=null)
                {
                    callBack(split_code,split_result);
                }
            }

        });


    }

    bytesToBase64(bytes)
    {
        //console.log(bytes.length);
        var binaryString = '';
        for (var i = 0; i < bytes.length; i++) {
            binaryString += String.fromCharCode(bytes[i]);
        }
        // 使用btoa对二进制字符串进行Base64编码
        return btoa(binaryString);
    }

    base64ToImageBase64(base64,type)
    {
        return "data:"+type+";base64,"+base64;
    }

    //类型为image/jpeg image/png等
    imageBase64ToBase64(base64,type)
    {
        return base64.replace("data:"+type+";base64,","");
    }

    base64ToByteArray(base64String)
    {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        const rawData = window.atob(base64);
        const rawDataLength = rawData.length;

        const array = new Uint8Array(new ArrayBuffer(rawDataLength));
        for(let i = 0; i < rawDataLength; i++) {
            array[i] = rawData.charCodeAt(i);
        }

        return array;
    }

    saveBinaryDataToFile(data, fileName) {
        // 创建一个Blob对象，其中包含二进制数据
        const blob =  new window.Blob([data], { type: "application/octet-stream" });

        // 创建一个指向Blob对象的URL
        const url = window.URL.createObjectURL(blob);

        // 创建一个a标签
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // 触发下载
        document.body.appendChild(link);
        link.click();

        // 清理并移除元素和对象URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
}
