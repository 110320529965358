import {RulerLineBaseValue} from "./RulerLineBaseValue";
import {RulerXView} from "./RulerXView";
export class RulerLineXView {
    constructor() {

        this.divContainer=null;
        this.rulerLineBaseValue=null;
        this.scaleSpan=0.01;
        this.bEnableScroll=true;
        this.dispatchUpdateRuler=null;
        this.dispatchUpdateScaleRuler=null;
        this.bEnableMove=true;
        this.timer=null;

    }

    findUIElementsByClassNameInParent(parent,className,fuzzySearch,results)
    {
        var index=0;

        if(parent==null)
            return;

        if(!parent.hasChildNodes())
        {
            if(fuzzySearch) {
                if (parent.className != null
                    && parent.className.toString() != ""
                    && (parent.className.toString().includes(className) || className.includes(parent.className.toString())))
                    results.push(parent);
            }else
            {
                if (parent.className != null
                    && parent.className.toString() != ""
                    && (parent.className.toString()==className))
                    results.push(parent);
            }
            return;
        }

        while (parent.hasChildNodes()&&index<parent.childElementCount)
        {
            if(fuzzySearch) {
                if (parent.children[index].className != null &&
                    parent.children[index].className.toString() != "" &&
                    (parent.children[index].className.toString().includes(className) || className.includes(parent.children[index].className.toString())))
                    results.push(parent.children[index]);
            }else
            {
                if (parent.children[index].className != null &&
                    parent.children[index].className.toString() != "" &&
                    parent.children[index].className.toString()==className)
                    results.push(parent.children[index]);
            }
            this.findUIElementsByClassNameInParent(parent.children[index],className,fuzzySearch,results);
            index++;
        }
    }

    initEvent()
    {
        var tmp=this;
        // this.divContainer.addEventListener('mousewheel',function(e){
        //     e.preventDefault();
        //     if( tmp.bEnableScroll)
        //         return;
        //
        //     let offsetX = e.wheelDelta;
        //     if(offsetX==0)
        //         return;
        //
        //     var scale=offsetX/Math.abs(offsetX)*tmp.scaleSpan;
        //     var target = tmp.rulerLineBaseValue.scale+scale;
        //
        //     var x=e.clientX;
        //     var mlast1=tmp.rulerLineBaseValue.px2position(x);
        //     tmp.rulerLineBaseValue.set_scale(target);
        //     var mNew1=tmp.rulerLineBaseValue.px2position(x);
        //     tmp.rulerLineBaseValue.set_position(tmp.rulerLineBaseValue.getPosition()-mNew1+mlast1);
        //     if(tmp.dispatchUpdateScaleRuler!=null)
        //         tmp.dispatchUpdateScaleRuler();
        //     //分发
        // });
        //
        // this.divContainer.addEventListener('dblclick',function(e){
        //
        //     var  target=tmp.rulerLineBaseValue.maxScale;
        //     if(Math.abs(tmp.rulerLineBaseValue.scale- tmp.rulerLineBaseValue.maxScale)<tmp.scaleSpan)
        //     {
        //         target=tmp.rulerLineBaseValue.minScale;
        //     }
        //     tmp.start_scaleAnimation(target,e.clientX);
        // });



        // var mOriginRawX=0;
        //
        // this.divContainer.addEventListener('mousedown',function(e){
        //     tmp.bEnableScroll=true;
        //     mOriginRawX=e.clientX;
        // });
        //
        // this.divContainer.addEventListener('mousemove',function (e){
        //     e.preventDefault();
        //     if(!tmp.bEnableScroll)
        //         return;
        //     if(e.buttons<=0) {
        //         tmp.bEnableScroll=false;
        //         return;
        //     }
        //
        //     if(!tmp.bEnableMove)
        //         return;
        //
        //     //计算差值
        //     var offsetPosition=(tmp.rulerLineBaseValue.px2position(e.clientX)-tmp.rulerLineBaseValue.px2position(mOriginRawX))*2;
        //
        //     mOriginRawX=e.clientX;
        //
        //     if (offsetPosition != 0) {
        //         clearInterval(tmp.divContainer.timer);
        //         var target=tmp.rulerLineBaseValue.position +offsetPosition;
        //         tmp.start_timeAnimation(target);
        //     }
        // });
        // // this.divContainer.addEventListener('mouseout',function(e){
        // //     tmp.bEnableScroll=false;
        // // });
        //
        // document.addEventListener('mouseup',function(e){
        //     tmp.bEnableScroll=false;
        // });
    }

    initRulerView(parent,width,dispatchUpdateRuler,dispatchUpdateScaleRuler)
    {
        var results=[];
        this.findUIElementsByClassNameInParent( parent,"xCoord",false,results);
        for(var i=0;i<results.length;i++)
        {
            parent.removeChild( results[i]);
        }

        this.dispatchUpdateRuler=dispatchUpdateRuler;
        this.dispatchUpdateScaleRuler=dispatchUpdateScaleRuler;
        this.divContainer=parent;
        this.rulerLineBaseValue=RulerLineBaseValue.generateRulerValue(width);
        var ruleXView=new RulerXView();
        ruleXView.init(this.divContainer);
        ruleXView.setRulerLineBaseValue(this.rulerLineBaseValue);
        //console.log("初始的缩放为：："+ this.rulerLineBaseValue.scale)
        this.initEvent();

        return ruleXView;
    }

    setEnableScroll(bscroll)
    {
        this.bEnableMove=bscroll;
    }

    start_scaleAnimation(target,x)
    {
        //①先清除之前的定时器
        clearInterval( this.timer);
        //②利用对象属性定义定时器
        if(target>this.rulerLineBaseValue.maxScale)
            target=this.rulerLineBaseValue.maxScale;
        if(target<this.rulerLineBaseValue.minScale)
            target=this.rulerLineBaseValue.minScale;
        var step = (target - this.rulerLineBaseValue.scale)/10.00;
        var mlast=this.rulerLineBaseValue.px2position(x);
        this.rulerLineBaseValue.set_scale(this.rulerLineBaseValue.scale + step);
        var mNew=this.rulerLineBaseValue.px2position(x);
        this.rulerLineBaseValue.set_position(this.rulerLineBaseValue.position-mNew+mlast);
        this.onAnimationUpdateScale(this.rulerLineBaseValue.scale);
        var tmp=this;
        this.timer = window.setInterval(()=>{
            if(Math.abs(tmp.rulerLineBaseValue.scale- target)<0.01){
                //console.log("当前计时器结束了：："+(tmp.rulerLineBaseValue.scale- target));
                clearInterval(tmp.timer);//到达，清除定时器
            }else{
                var mlast1=tmp.rulerLineBaseValue.px2position(x);
                tmp.rulerLineBaseValue.set_scale(tmp.rulerLineBaseValue.scale + step);
                var mNew1=tmp.rulerLineBaseValue.px2position(x);
                tmp.rulerLineBaseValue.set_position(tmp.rulerLineBaseValue.getPosition()-mNew1+mlast1);
            }
            //        //console.log("滚动！！");
            tmp.onAnimationUpdateScale(tmp.rulerLineBaseValue.scale);
        },40);
    }

    onAnimationUpdateScale(scale) {
        //这里分发缩放事件事件
        if(this.dispatchUpdateScaleRuler!=null)
            this.dispatchUpdateScaleRuler();
        //console.log("执行了")
    }

    start_timeAnimation(target){
        var obj = this.divContainer;
        //①先清除之前的定时器
        clearInterval(obj.timer);

        // if(target>this.rulerLineBaseValue.totalLength)
        //     target=this.rulerLineBaseValue.totalLength;
        // if(target<0)
        //     target=0;
        var step = (target - this.rulerLineBaseValue.position)/2;
        step = Math.floor(step);
        this.rulerLineBaseValue.set_position(this.rulerLineBaseValue.position+step);
        this.onAnimationUpdatePosition(this.rulerLineBaseValue.position);
        var tmp=this;
        obj.timer = window.setInterval(()=>{
            if(Math.abs(this.rulerLineBaseValue.position -target)<100){
                clearInterval(obj.timer);//到达，清除定时器
            }else{
                tmp.onAnimationUpdatePosition(tmp.rulerLineBaseValue.position+step);
            }
        },30);
    }

    onAnimationUpdatePosition(value) {
        this.rulerLineBaseValue.set_position(value);
        if(this.dispatchUpdateRuler!=null)
             this.dispatchUpdateRuler();
    }




    scrollByPx(x,y)
    {
        if(x==0)
            return;
        var offsetPosition = this.rulerLineBaseValue.px2position(x);
        if (offsetPosition != 0) {
            clearInterval(this.timer);
            var target=this.rulerLineBaseValue.position +offsetPosition;
            this.start_timeAnimation(target);
        }
    }

    scroll(x,y)
    {
        if(x==0)
            return;
        clearInterval(this.timer);
        var target=this.rulerLineBaseValue.position +x*2;
        this.start_timeAnimation(target);
    }


}
