import { useLoadingContext } from "../../../context/loadingLayout";
import FunctionButton from "../../../components/common/funcButton";
import Score from "../../../components/common/score";
import { useProject } from "../../../hooks/useProject";
import { useNavigate, useParams } from "react-router";
import React, {  useEffect, useRef, useState } from "react";
import ColorSelector from "./color";
import EditorMeunBar from "./menubar";
import { useUpdatorContext } from "../../../context/updator";
import MockupEditor,{ Action, ActionName, EventCallbackParams, EventCallbackParamsType } from "./mockupEditor";
import { useHttpUtil } from "context/httpContext";

type DownloadParameter = {
    isAllowDownload: boolean,
    isShow : boolean,
    model ?: string,
    width ?: number,
    height ?: number
}

type Project = {
    projectId?: string,
    name ?: string,
}

export default function ProjectEditorDetail(){
    const navigate = useNavigate();
    const params = useParams() ;

    const [gotoAdd] = useProject();
    const loadingContext = useLoadingContext();
    const updatorContext = useUpdatorContext();
    const httpUtil = useHttpUtil();

    const [projectId, setProjectId] = useState<string>()

    const [project, setProject] = useState<Project>(() => {
        return {
            name : ''
        }
    });

    const [editorAction, setEditAction] = useState<Action>();

    // 编辑器的手势模式0-2
    const [modeType, setModeType] = useState<number>(0);

    // 编辑器选中的元素类型，0：未选中  1：图片  2：模型
    const [editorElementType, setEditorElementType] = useState<number>(0);

    // 编辑器选择图片时的工具栏模式1-4
    const [imageEditType, setImageEditType] = useState<number>(0);
    // 编辑器选择图片时的工具栏模式1-3
    const [modelEditType, setModelEditType] = useState<number>(0);

    // 下载参数
    const [downloadParameter, setDownloadParameter] = useState<DownloadParameter>({
        isAllowDownload: false,
        isShow : false,
        width: 0,
        height: 0
    });

    const inputNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setProjectId(params.id as string)
    }, [params.id])

    useEffect(() => {
        if(projectId){
            loadProject();
        }
    },[projectId]);


    const loadProject = async function(){
        const data = await httpUtil.post('/biz/project/detail', {
            projectId
        })
        if(!data.errCode){
            setProject(data.data);
            loadingContext?.setIsLoadingLayout(false);
        }
    }

    const saveProjectName = async function(){
        await httpUtil.post('/biz/project/update/name', {
            projectId,
            name : project.name
        })

        updatorContext?.updator(); // 触发更新积分信息
    }

    // 编辑器手势模式
    const selectedMode = (type : number) => {
        setModeType(type);
    }

    // 选中编辑器图片时的功能模式
    const selectedImageEditMode = (type : number) => {
        setImageEditType(type);
        switch(type){
            case 1: // 转模型
                setEditAction({
                    name: ActionName.MOCKUP
                })
                break;
            case 2: // 画笔
                break;
            case 3: // 去除背景
                break;
            case 4: // 转高清
                break;
        }
    }

    // 选中编辑器模型时的功能模式
    const selectedModelEditMode = (type : number) => {
        setModelEditType(type);
        switch(type){
            case 1: // 画笔
                break;
            case 2: // 获取画笔选中区域的颜色数组
                break;
            case 3: // 栅格化，转图片
                break;
        }
    }

    // 保存画布数据
    const saveProjectData = async function(content: string){
        await httpUtil.post('/biz/project/save', {
            projectId,
            content
        })
    }

    // 点击素材库的图片，需加载到编辑器中
    const choiceMaterialImage = (url: string) => {
        setEditAction({
            name: ActionName.LOADING_IMAGE,
            params: url
        })
    }

    // 颜色选择器，colors是在 选中模型状态下，使用了画笔功能后出现的，会自动识别画笔区域的所有颜色值
    const [colors, setColors] = useState<string[]>(['#FFFFFF', '#FFE27A' , '#FFDFA1', '#000000']);
    const onSelectedColor = (color: string , index: number) => {
        const tempColors = [...colors];
        tempColors[index] = color ;
        setColors(tempColors)
    }

    // 下载
    const download = () => {
        setEditAction({
            name: ActionName.DOWNLOAD,
            params: project.name
        })
    }

    // 编辑器的数据监听
    const onEditorDataListener = (data: EventCallbackParams) => {
        console.info('onEditorDataListener', data)
        switch(data.type){
            case EventCallbackParamsType.OBJECT_SELECT: // 对象选中
                data.data && setEditorElementType(data.data.type == 1 ? 2 : 1);
                setDownloadParameter({...downloadParameter, width: data.data?.mImageWidth, height: data.data?.mImageHeight, isAllowDownload: true})
                break;
            case EventCallbackParamsType.OBJECT_CANCEL_SELECT: // 取消选中
                setEditorElementType(0);
                setDownloadParameter({...downloadParameter, isAllowDownload: false})
                break;
        }
    }

    useEffect(() => {
        // const keydownHander = (e: KeyboardEvent) => {
        //     if(/delete/ig.test(e.code) || e.keyCode == 46){
        //         // 删除选中
        //     }
        // }
        // window.addEventListener('keydown', keydownHander)
        // return () => {
        //     window.removeEventListener('keydown', keydownHander);
        // }
    },[])

    return (
        <div className="min-h-screen flex flex-col ">
            <header className="flex items-center h-[60px] px-[30px] border-b-[#EBEBEB] border-b-[1px]">
                <div className="flex items-center gap-[13px]">
                    <img src="/logo-short.png" className="w-[45px] h-[45px] cursor-pointer"
                        onClick={() => {navigate('/')}}/>
                    <div className="relative pl-[10px] group">
                        <img src="/icon/icon-editor-more.png" className="w-[25px] cursor-pointer"/>
                        <div className="absolute z-[1002] top-[25px] left-[5px] flex flex-col w-[150px] bg-[#FFFFFF] rounded-[10px] shadow-xl text-center group-hover:flex hidden">
                            <span className="py-[8px] cursor-pointer hover:bg-[#F8F8F8] rounded-t-[10px]"
                                onClick={() => inputNameRef.current?.select()}>Rename Title</span>
                            <span className="py-[8px] cursor-pointer hover:bg-[#F8F8F8]"
                                onClick={() => gotoAdd()}>New Project</span>
                            <span className="py-[8px] cursor-pointer hover:bg-[#F8F8F8]"
                                onClick={() => gotoAdd(projectId)}>Duplicate Project</span>
                            <span className="py-[8px] cursor-pointer hover:bg-[#F8F8F8] rounded-b-[10px]" 
                                onClick={() => navigate('/')}>My Project</span>
                        </div>
                    </div>
                    <div className="">
                        <input type="text" value={project?.name} className="py-[7px] px-[10px] border border-[#5170FF] w-[140px] outline-none rounded-[6px] focus:select-all"
                            ref={inputNameRef}
                            onChange={(e) => project && setProject({...project, name:e.target.value})} 
                            onBlur={(e) => saveProjectName()}/>
                    </div>
                    <div className="flex gap-[10px]">
                        <FunctionButton isActive={modeType == 0} iconPath="/icon/icon-editor-selector.png" onClick={() => selectedMode(0)} 
                            bubble={{ title: 'Move'}}/>
                        <FunctionButton isActive={modeType == 1} iconPath="/icon/icon-editor-hand.png" onClick={() => selectedMode(1)} 
                            bubble={{ title: 'Hand'}}/>
                        <FunctionButton isActive={modeType == 2} iconPath="/icon/icon-editor-frame.png" onClick={() => selectedMode(2)} 
                            bubble={{ title: 'Frame', desc: 'Multiple images can be put together into a single whole.'}}/>
                    </div>
                </div>
                <div className="grow flex justify-center">
                {
                    editorElementType == 1 && 
                        <div className="flex gap-[8px]">
                            <FunctionButton isActive={false} iconPath="/icon/icon-editor-mockup.png" onClick={() => selectedImageEditMode(1)} 
                                bubble={{ title: 'Mockup', desc: 'Use the selected layer as a mockup image. Drag it to the mockup base and it will stretch to fit the surface.'}} />
                            <FunctionButton isActive={imageEditType == 2} iconPath="/icon/icon-editor-pen.png" onClick={() => selectedImageEditMode(2)}
                                bubble={{ title: 'Intelligent elimination', desc: 'Remove unwanted elements.'}} >
                                {
                                    imageEditType == 2 && 
                                    <div className="absolute top-[47px] left-[-65px] flex flex-col w-[210px] border rounded-[5px] bg-[#FFFFFF] z-[10] text-[14px] font-bold text-[#000000]">
                                        <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#F8F8F8]">
                                            <img src="/icon/icon-editor-image-pen-daub.png" className="w-[23px]" />
                                            <span className="">Daub</span>
                                        </div>
                                        <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#F8F8F8]">
                                            <img src="/icon/icon-editor-image-pen-frame.png" className="w-[23px]" />
                                            <span>Frame select</span>
                                        </div>
                                        <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#F8F8F8]">
                                            <img src="/icon/icon-editor-image-pen-select.png" className="w-[23px]" />
                                            <span>One click selection</span>
                                        </div>
                                    </div>
                                }
                            </FunctionButton>
                            <FunctionButton isActive={imageEditType == 3} iconPath="/icon/icon-editor-nobg.png" onClick={() => selectedImageEditMode(3)} 
                                bubble={{ title: 'Remove Background', desc: 'One click removal of background.'}} />
                            <FunctionButton isActive={imageEditType == 4} iconPath="/icon/icon-editor-hd.png" onClick={() => selectedImageEditMode(4)} 
                                bubble={{ title: 'Improve clarity', desc: 'Increases image resolution, making it sharper and cleaner.'}} />
                        </div>
                }
                {
                    editorElementType == 2 && 
                        <div className="flex gap-[8px]">
                            <FunctionButton isActive={modelEditType == 1} iconPath="/icon/icon-editor-model-pen.png" onClick={() => selectedModelEditMode(1)}
                                bubble={{ title: 'Geometrics recognition ', desc: 'Use the brush to paint areas where the pattern can be placed on the model.'}} >
                                {
                                    modelEditType == 1 && 
                                    <div className="absolute top-[47px] left-[-45px] flex flex-col w-[210px] border rounded-[5px] bg-[#FFFFFF] z-[10] text-[14px] font-bold text-[#000000]">
                                        <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#F8F8F8]">
                                            <img src="/icon/icon-editor-image-pen-daub.png" className="w-[23px]" />
                                            <span className="">Daub</span>
                                        </div>
                                        <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#F8F8F8]">
                                            <img src="/icon/icon-editor-pen-quick.png" className="w-[23px]" />
                                            <span>Quick brush</span>
                                        </div>
                                    </div>
                                }
                            </FunctionButton>
                            <FunctionButton isActive={modelEditType == 2} iconPath="/icon/icon-editor-model-frame.png" onClick={() => selectedModelEditMode(2)} 
                                bubble={{ title: 'Rasterizing', desc: 'Convert the mockup with all its attached layers into a raster image.'}} />
                            <FunctionButton isActive={modelEditType == 3} iconPath="/icon/icon-editor-model-color.png" onClick={() => selectedModelEditMode(3)} 
                                bubble={{ title: 'Color set', desc: 'Switch product model colors.'}} />
                        </div>
                }
                </div>
                
                <Score>
                    {
                        downloadParameter && downloadParameter.isAllowDownload &&
                        <div className="relative flex items-center rounded-[10px] bg-gradient-to-r from-[#5170FF] to-[#002EFF] text-[#FFFFFF] px-[20px] gap-[5px] cursor-pointer"
                            onClick={() => { downloadParameter && setDownloadParameter({...downloadParameter, isShow: !downloadParameter?.isShow})}}>
                            <img src="/icon/icon-editor-download.png" className="w-[21px]" />
                            <span>Download</span>

                            {
                                downloadParameter.isShow && 
                                <div className="absolute flex flex-col w-[260px] p-[15px] bg-[#FFFFFF] right-0 top-[48px] z-[1002] text-[#000000] text-[14px] gap-[15px] shadow-xl rounded-[10px]"
                                    onClick={(e) => e.stopPropagation()}>
                                    <p className="text-[16px] font-bold">Download Options</p>
                                    <span className="">File Type</span>
                                    <select className="border rounded-[5px] py-[11px] pl-[15px] pr-[9px] outline-none" value={downloadParameter.model}
                                        onChange={(e) => {setDownloadParameter({...downloadParameter, model: e.target.value})}}>
                                        <option value="png">PNG</option>
                                        <option value="jpg">JPG</option>
                                    </select>
                                    <div className="flex items-center gap-[10px]">
                                        <span>Width:</span><input type="text" value={downloadParameter.width + ''} className="outline-none text-[12px] border rounded-[5px] p-[10px] w-[58px] text-center "
                                            onChange={(e) => {setDownloadParameter({...downloadParameter, width: +e.target.value})}}/>
                                        <span>Height:</span><input type="text" value={downloadParameter.height + ''} className="outline-none text-[12px] border rounded-[5px] p-[10px] w-[58px] text-center"
                                            onChange={(e) => {setDownloadParameter({...downloadParameter, height: +e.target.value})}}/>
                                    </div>
                                    <button className="w-full py-[11px] rounded-[10px] bg-gradient-to-r from-[#5170FF] to-[#002EFF] text-[#FFFFFF] cursor-pointer"
                                        onClick={download}>Download</button>
                                </div>
                            }
                        </div>
                    }
                    
                </Score>
            </header>
            <div className="relative grow flex h-full">
                <EditorMeunBar onImageClick={choiceMaterialImage}/>
                <div className="flex grow bg-[#EFF3F5]">
                    <MockupEditor action={editorAction} onDataListener={onEditorDataListener}/>
                </div>
                {
                    modelEditType == 3 &&
                    <ColorSelector colors={colors} onSelectedColor={onSelectedColor}/>
                }
                
            </div>
        </div>
    )
}