import Loading from "../../../components/common/loading";
import { useEffect, useRef, useState } from "react";
import { useHttpUtil } from "context/httpContext";

type Material = {
    mid: string,
    name: string,
    url: string,
    width?: number,
    height?: number,
    status?: number,
    progress?:number,
    isChecked ?: boolean
}


export default function EditorMeunBar(props: { onImageClick: Function}){
    // 左侧工具栏的选择类型，0为未选择 1开始
    const [toolType, setToolType] = useState<number>(1);
    const httpUtil = useHttpUtil();

    // 素材库分页索引
    const [materialPage, setMaterialPage] = useState<number>(-1);

    const [materialList, setMaterialList] = useState<Material[]>();

    const [uploadMaterial, setUploadMaterial] = useState<Material>();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const listElement = useRef<HTMLDivElement>(null);

    
    useEffect(() => {
        materialPage > 0 && getMaterialList();
    },[materialPage]);

    useEffect(() => {
        if(toolType == 1 && materialPage == -1){
            setMaterialPage(1);
        }
    }, [toolType, materialPage]);


    const getMaterialList = async function(){
        setIsLoading(true);
        
        const responseData = await httpUtil.post('/biz/material/list', {
            currentPage: materialPage
        })
        if(responseData.data.length == 0) {
            setMaterialPage(0);
        }else if(materialPage == 1){
            setMaterialList(responseData.data)
        }else{
            setMaterialList(materialList?.concat(responseData.data))
        }
        setTimeout(() => {
            setIsLoading(false);
        },1500)
        
    }

    const uploadFile = async function(e: React.ChangeEvent<HTMLInputElement>){
        const files = e.target.files ;
        if(files?.length){
            const file = files[0];

            const reader = new FileReader();
            let item : Material =  {
                mid : new Date().getTime() + '',
                name : 'upload',
                url : '',
                status: 1,
                progress: 0
            }
            reader.onload = function(e) {
                item = {
                    ...item,
                    url: e.target?.result as string
                }
                setUploadMaterial(item)
            }
            reader.readAsDataURL(file);

            const responseData = await httpUtil.upload(file, (progress: number) => {
                setUploadMaterial({
                    ...item,
                    progress : 10
                })
            });

            const material = responseData.data as Material;
            await httpUtil.image(material.url, (progress: number) => {
                if(progress >= 100){
                    setUploadMaterial(undefined);
                    props.onImageClick(material.url)
                    if(materialList){
                        setMaterialList([material].concat(materialList))
                    }else{
                        setMaterialList([material]);
                    }
                    return 
                }
                const tmpProgress = Math.min(progress + 10, 95)
                setUploadMaterial({
                    ...item,
                    progress : tmpProgress
                })
            })
            // props.onImageClick(responseData.data)

            // setMaterialPage(1);
            // materialPage == 1 && getMaterialList();
        }
    }

    const scrollContent = async function () {
        if(listElement.current && materialPage != -1){
            const { scrollTop, scrollHeight, clientHeight} = listElement.current ;
            if(scrollTop + clientHeight + 20 > scrollHeight){
                setMaterialPage(materialPage + 1)
            }
        }
    }

    return (
        <div className="relative flex w-[100px] flex-none flex-col items-center p-[15px]">
            <div className={`flex flex-col items-center justify-center w-[70px] h-[70px] rounded-[10px] cursor-pointer ${toolType == 1 ? ' bg-[#EFF3F5]' : ''}`}
                onClick={() => setToolType(toolType == 1 ? 0 : 1)}>
                <img src="/icon/icon-editor-media.png" className="w-[24px]" />
                <span>Media</span>
            </div>
            {
                toolType == 1 && 
                <div className="absolute flex flex-col left-[100px] top-[0px] w-[320px] h-full p-[20px] shadow-lg overflow-y-auto z-[1001] bg-[#FFFFFF]"
                    ref={listElement}
                    onScroll={() => scrollContent()}>
                    <div className="flex relative w-full items-center justify-center rounded-[10px] bg-gradient-to-r from-[#5170FF] to-[#002EFF] cursor-pointer text-[#FFFFFF] text-[14px] py-[10px] gap-[10px]">
                        <img src="/icon/icon-editor-upload.png" className="w-[20px]" />
                        <span>Upload</span>
                        <input type="file" className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer" 
                            onChange={(e) => uploadFile(e)}/>
                    </div>
                    <div className="columns-2 mt-[20px] gap-y-[10px]">
                        {
                            uploadMaterial && 
                                <div className="rounded-[10px] relative">
                                    <img src={uploadMaterial.url} alt={uploadMaterial.name} className={`rounded-[10px] cursor-pointer`} />
                                    <div className="absolute w-full h-full left-0 top-0 bg-[#000000]/[0.5] rounded-[10px] px-[18px] flex items-center justify-center">
                                        <div className="w-full h-[10px] relative bg-[#FFFFFF] rounded-[10px]">
                                            <div style={{
                                                width : `${uploadMaterial.progress}%`
                                            }} className="absolute scale-[1.02] left-0 top-0 w-[0%] h-full rounded-[10px] bg-[#5170FF]"></div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            materialList?.map(({mid, name, url, width = 0, height = 1, isChecked = false}, index) => (
                                <div className="relative rounded-[10px] bg-[#EBEBEB] w-full" key={mid}
                                    style={{ aspectRatio: width / height}}>
                                    <img src={url} alt={name} className={`rounded-[10px] w-full bg-[#FFFFFF] cursor-pointer ${index > 0 || uploadMaterial ? 'mt-[10px]' : ''}`} 
                                        onClick={() => props.onImageClick(url)}/>
                                    {/* <div className="absolute w-full h-full left-0 top-0 ">
                                        {
                                            isChecked ?
                                            <div className="absolute top-[10px] left-[10px] p-[1px] bg-[#5170FF] border border-[#BFBFBF] rounded-[5px] w-[20px] h-[20px] cursor-pointer">
                                                <img src="/icon/icon-checked.png"/>
                                            </div>
                                            :
                                            <div className="absolute top-[10px] left-[10px] p-[1px] bg-[#ffffff] border border-[#BFBFBF] rounded-[5px] w-[20px] h-[20px] cursor-pointer">
                                            </div>
                                        }
                                        
                                        
                                        <div className="absolute top-[10px] right-[10px] bg-[#797979] rounded-[5px] w-[20px] h-[20px] text-center p-[2px] cursor-pointe">
                                            <img src="/icon/icon-more.png" className="w-[18px]"/>
                                        </div>
                                    </div> */}
                                </div>
                            ))
                        }
                    </div>
                    {
                        isLoading && <Loading />
                    }
                    {
                        !isLoading && !uploadMaterial && (!materialList || !materialList.length) ?
                            <div className="flex w-full h-full justify-center">
                                <div className="text-center mt-[120px]">
                                    <img src="/icon/icon-empty.png" className="w-[200px]" />
                                    <p className="text-[18px] text-[#BFBFBF] ">No Files</p>
                                </div>
                            </div>
                        : null
                    }
                    
                </div>
            }
        </div>
    )
}