import {ShowSelectAreaView} from "./ShowSelectAreaView.js";
import {UISelectKeyPair} from "../../Data/UISelectKeyPair.js";
import {UISelectType} from "../../Data/UISelectType.js";
import {ElementBaseValue} from "../../Data/ElementBaseValue.js";
import {UICoordinateView} from "../Ruler/UICoordinateView";
import {UIKeyboardShortCut} from "../../Data/UIKeyboardShortCut";
import {UIRevocationRollback} from "../../Data/UIRevocationRollback";
import {UISelectEventType} from "../../Data/UISelectEventType";
import {UIKeyBorderType} from "../../Data/UIKeyBorderType";
import {UIGraphics} from "../uigraphics/UIGraphics";
import {ProjectDataManager} from "../../Data/ProjectDataManager";

export class ShowFrameLayout{


    constructor() {
        if (!ShowFrameLayout.instance) {
            this.divContainer=null;
            this.elementList=[];
            this.selectAreaView=null;
            this.uiSelectKeyPair=new UISelectKeyPair();
            this.uiCoordinateView=null;
            this.eventsCallBack=null;
            ShowFrameLayout.instance = this;
        } else {
            return ShowFrameLayout.instance;
        }
    }

    //绕点旋转,只针对于没有发生任何平移和旋转,即图像位于（0,0）的位置才可以
    rotatePoint(point,dx,dy,dstw,dsth,rotation)
    {
        var x1 = point[0]-dx;
        var y1 = point[1]-dy;

        var cos = Math.cos(rotation);
        var sin = Math.sin(rotation);
        var cx=dstw/2;
        var cy=dsth/2;
        var xoffset=x1-cx;
        var yoffset=y1-cy;
        var x4 = xoffset * cos - yoffset * sin;
        var y4 = xoffset * sin + yoffset * cos;

        var x6=x4+cx+dx;
        var y6=y4+cy+dy;
        return [x6,y6];
    }

    isPointInsideShape(Point, triangle)
    {
        var nCross = 0;
        var nCount = triangle.length;
        var p = Point;
        for (var i = 0; i < nCount; i++)
        {
            var p1 = triangle[i];
            var p2 = triangle[(i + 1) % nCount];
            if (p1[1] == p2[1])
            {
                continue;
            }
            if (p[1] < Math.min(p1[1], p2[1]))
                continue;
            if (p[1] >= Math.max(p1[1], p2[1]))
                continue;
            var x = (p[1] - p1[1]) * (p2[0] - p1[0]) / (p2[1] - p1[1]) + p1[0];
            if (x > p[0])
                nCross = nCross + 1;
        }
        return (nCross % 2 == 1);
    }

    initDomInfo()
    {
        var mLastClientX;
        var mLastClientY;
        var tmp=this;

        // this.divContainer.onmousedown=(e)=>{
        //     mLastClientX=e.clientX;
        //     mLastClientY=e.clientY;
        // };

        this.divContainer.addEventListener("mousedown",(e)=>{
            if(UIGraphics.instance.getEraseDrawState())
            {
                return;
            }



            if(tmp.selectAreaView.isMouseInScaleAndRotateButton(e))
            {
                return;
            }


            if(e.button==1)
                return;

            var scrollY = window.scrollY || window.pageYOffset;
            var scrollX = window.scrollX || window.pageXOffset;

            //对父物体的位置进行补偿
            const parent_rect =  tmp.divContainer.getBoundingClientRect();
            mLastClientX=e.clientX+scrollX-parent_rect.left;
            mLastClientY=e.clientY+scrollY-parent_rect.top;

           //console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee::"+scrollX+":::"+scrollY);

            tmp.updateSelectedView(new ElementBaseValue());
            //存在重叠区域，选择最高渲染层级的那一个
            var currentZindex=-1;
            //选中物体
            var imageDatas=ProjectDataManager.instance.getAllImageData();
            var currentSelectData=null;
            for(var i=0;i<imageDatas.length;i++)
            {
                var imageData=imageDatas[i];
                var position = UICoordinateView.instance.position2px(imageData.mPosX,imageData.mPosY);
                var size = UICoordinateView.instance.size2px(imageData.mShowWidth,imageData.mShowHeight);
                var rect=[
                    [position[0],position[1]],
                    [position[0]+size[0],position[1]],
                    [position[0]+size[0],position[1]+size[1]],
                    [position[0],position[1]+size[1]]
                ];

                var rotation_angel=-(imageData.rotation/180)*Math.PI;
                var point = tmp.rotatePoint([mLastClientX,mLastClientY],position[0],position[1],size[0],size[1],rotation_angel);

                if(tmp.isPointInsideShape([point[0],point[1]],rect))
                {
                    var ndex= imageData.trackIndex;
                    if(ndex>currentZindex)
                    {
                        currentSelectData=imageData;
                        currentZindex=ndex;
                    }
                }

            }

            if(currentSelectData!=null)
            {
                tmp.updateSelectedView(currentSelectData);
                if(tmp.eventsCallBack!=null)
                    tmp.eventsCallBack(UISelectEventType.Selected,currentSelectData);
            }else
            {
                if(tmp.eventsCallBack!=null)
                    tmp.eventsCallBack(UISelectEventType.QuitSelected,new ElementBaseValue());
            }
        });
    }

    isInRect(x, y, rect)
    {
        return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
    }

    init(container,totalX,totalY)
    {
        if(this.divContainer!=null)
            return;
        this.initUI(container,totalX,totalY);
        this.initKeyEvents();

    }

    initUI(container,totalX,totalY)
    {
        this.divContainer=container;
        this.divContainer.style.overflowX="hidden";
        this.divContainer.style.overflowY="hidden";
        this.divContainer.style.position="relative";
        this.initDomInfo();
        this.initRulers(totalX,totalY);
    }

    initRulers(totalX,totalY)
    {
        var tmp=this;
        this.uiCoordinateView=new UICoordinateView();
        this.uiCoordinateView.init(this.divContainer,totalX,totalY,function () {
            for(var i=0;i<tmp.elementList.length;i++) {
                //分发更新坐标事件
              tmp.dispatchUpdateRulerEvent();
            }},function () {
               tmp.dispatchScaleChangeEvent();
               },function (code,result) {
            tmp.initShowSelectAreaView();
            for(var i=0;i<result.length;i++)
                tmp.elementList.push(result[i]);
        })
    }

    //获取当前画布的中心点位置
    getCenterPositionInParent()
    {
        const parent_rect =  this.divContainer.getBoundingClientRect();

        var centerx=(parent_rect.left+parent_rect.width)/2;
        var centery=(parent_rect.top+parent_rect.height)/2;

        var position = UICoordinateView.instance.px2position(centerx,centery);
        return position;
    }

    getCenterPositionAndSizeInParent(w,h)
    {
       var size = this.transformPxToSize(w,h);
       var position=this.getCenterPositionInParent();


        return [position[0]-size[0]/2,position[1]-size[1]/2,size[0],size[1]];
    }

    transformPxToSize(w,h)
    {
        return UICoordinateView.instance.px2Size(w,h);
    }


    initShowSelectAreaView()
    {
        this.selectAreaView=new ShowSelectAreaView();
        this.selectAreaView.init(this.divContainer,this);
        this.selectAreaView.setVisibility(false);
        this.selectAreaView.setRulerLineBaseValue(this.uiCoordinateView.rulerXBaseValue,this.uiCoordinateView.rulerYBaseValue);
        this.elementList.push(this.selectAreaView);

        var tmp=this;
        this.selectAreaView.eventsCallBack = function (eventType,data) {

            if(eventType===UISelectEventType.Moving) {
                //通知渲染器开始绘制对齐线
                if(ProjectDataManager.instance.getSelectCurrentData()!=null&&ProjectDataManager.instance.getSelectCurrentData().shapeOffset==0)
                {
                    UIGraphics.instance.switchAlignLineDrawState(true);
                }else
                {
                    UIGraphics.instance.switchAlignLineDrawState(false);
                }

            }else
            {
                if(eventType===UISelectEventType.Moved)
                {
                    UIRevocationRollback.instance.AddDataToUndo(ProjectDataManager.instance.getAllImageData());
                    UIGraphics.instance.switchAlignLineDrawState(false);
                    //需要在绘制一次
                    UIGraphics.instance.updateDrawImage();
                }


            }
            if (tmp.eventsCallBack != null)
                tmp.eventsCallBack(eventType, data);
        }

    }

    initKeyEvents()
    {
        //开启键盘监听事件
        var uiKeyborderShortCut=new UIKeyboardShortCut();
        uiKeyborderShortCut.init();
        //注册键盘事件
        var tmp=this;
        uiKeyborderShortCut.Regiter(UIKeyBorderType.Revoke,function () {
            var tmps=UIRevocationRollback.instance.undo();
            if(tmps!=null) {
               //console.log("执行了撤销逻辑：：" + tmps.length);
                if (tmps != null) {
                    ProjectDataManager.instance.updateAllDatas(tmps);
                    //通知更新ui
                    tmp.updateSelectedView(new ElementBaseValue());
                }
            }
        });

        uiKeyborderShortCut.Regiter(UIKeyBorderType.RollBack,function () {
            var tmps=UIRevocationRollback.instance.redo();
           //console.log("执行了前进逻辑:"+tmps)
            if(tmps!=null) {
                ProjectDataManager.instance.updateAllDatas(tmps);
                //通知更新ui
                tmp.updateSelectedView(new ElementBaseValue());
            }
        });

        uiKeyborderShortCut.Regiter(UIKeyBorderType.Delete,function () {
           tmp.deleteSelectUIImageData(function (code,result) {

               if(code==0)
               {
                   //通知渲染
                   //通知更新ui
                   tmp.updateSelectedView(new ElementBaseValue());

               }else
               {
                   //这里报错提示

               }

           })
        });

        uiKeyborderShortCut.Regiter(UIKeyBorderType.Cancel,function () {
            tmp.updateSelectedView(new ElementBaseValue());
        })

        //开启回退及撤销逻辑
        var uiRecocationRollback=new UIRevocationRollback();
        uiRecocationRollback.clear();
    }


    dispatchUpdateTrackEvent()
    {
        if(this.elementList==null||this.elementList.length==0)
            return;

        for(var i=0;i<this.elementList.length;i++) {

            if(this.elementList[i].updateTrack!=null)
                this.elementList[i].updateTrack(0);
        }
       UIGraphics.instance.updateDrawImage();
    }

    dispatchUpdateRulerEvent()
    {

        UIGraphics.instance.updateDrawImage();
    }

    updateEvent()
    {

        if(this.elementList==null||this.elementList.length==0)
            return;

        for(var i=0;i<this.elementList.length;i++) {
            if(this.elementList[i].updateRuler!=null)
                this.elementList[i].updateRuler();
            if( this.elementList[i].scaleChange!=null)
                this.elementList[i].scaleChange();
            if( this.elementList[i].updateUIEvent)
                this.elementList[i].updateUIEvent();
        }
    }


    //分发缩放事件
    dispatchScaleChangeEvent()
    {
        UIGraphics.instance.updateDrawImage();
    }



    dispatchUpdateUIEvent()
    {
        //分发当前选中的数据
        UIGraphics.instance.updateDrawImage();
    }


    updateSelectedView(elementBaseValue)
    {

        if(this.selectAreaView==null)
            return;

        if(elementBaseValue.uuid==-1)
        {
            this.uiSelectKeyPair=new UISelectKeyPair();
            this.uiSelectKeyPair.type=UISelectType.None;
            this.selectAreaView.setElementBaseValue(elementBaseValue);
            this.selectAreaView.setVisibility(false);
            //分发事件
            this.uiCoordinateView.setEnableScroll(true);
            ProjectDataManager.instance.setSelectCurrentData(null);
            if(this.eventsCallBack!=null)
                this.eventsCallBack(UISelectEventType.QuitSelected,null);
        }else
        {
            var currentVectorDatas = ProjectDataManager.instance.findVectorImagsDataInBackground(elementBaseValue);
           //console.log("测试数据是否传入："+elementBaseValue.mPosX);
            this.selectAreaView.setElementBaseValue(elementBaseValue, this.elementList);
            this.selectAreaView.setElementBaseChildValue(currentVectorDatas);
            this.selectAreaView.setVisibility(true);
            this.uiSelectKeyPair=new UISelectKeyPair();
            var uiSelectType = UISelectKeyPair.transformElementTypeToUISelectType (elementBaseValue.type);
            this.uiCoordinateView.setEnableScroll(false);
            ProjectDataManager.instance.setSelectCurrentData(elementBaseValue);
            if(this.eventsCallBack!=null)
                this.eventsCallBack(UISelectEventType.Selected,elementBaseValue);
            switch (uiSelectType)
            {
                case UISelectType.None:
                    this.uiSelectKeyPair.type=UISelectType.None;
                    this.uiSelectKeyPair.uuid=-1;
                    break;
                case UISelectType.BackgroundImage:
                    this.uiSelectKeyPair.type=UISelectType.BackgroundImage;
                    this.uiSelectKeyPair.uuid=elementBaseValue.uuid;

                    break;
                case UISelectType.VectorImage:
                    this.uiSelectKeyPair.type=UISelectType.VectorImage;
                    this.uiSelectKeyPair.uuid=elementBaseValue.uuid;

                    break;
                case UISelectType.Image:
                    this.uiSelectKeyPair.type=UISelectType.Image;
                    this.uiSelectKeyPair.uuid=elementBaseValue.uuid;
                    break;
                case UISelectType.Text:
                    this.uiSelectKeyPair.type=UISelectType.Text;
                    this.uiSelectKeyPair.uuid=elementBaseValue.uuid;
                    break;
            }
        }
        this.changeViewRenderIndex(elementBaseValue);
        UIGraphics.instance.updateDrawImage();
    }

    changeViewRenderIndex(elementBaseValue)
    {
        for(var i=0;i<this.elementList.length;i++) {

            var elementBaseValue1=this.elementList[i];
            if(elementBaseValue1==null||elementBaseValue1.elementBaseValue==null)
                continue;
            if(elementBaseValue1.elementBaseValue!=null&&elementBaseValue1.uuid==elementBaseValue.uuid)
            {
                //选中的置顶
                // elementBaseValue1.bringToFront();
            }

        }
    }

    addShowVectorImageView(imagedata)
    {
        // var imageView=new ShowVectorImageView();
        // imageView.init(this.divContainer);
        // imageView.setElementBaseValue(imagedata);
        // imageView.setRulerLineBaseValue(this.uiCoordinateView.rulerXBaseValue,this.uiCoordinateView.rulerYBaseValue);
        // this.elementList.push(imageView);

        // UIRevocationRollback.instance.AddDataToUndo(index.instance.mProjectDataManager.getAllImageData());
        // if(this.eventsCallBack!=null)
        //     this.eventsCallBack(eventType,data);
    }

    addShowImageViews(imagedatas)
    {
        ////console.log("当前图片集的大小为：："+imagedatas.length);
        // for(var i=0;i<imagedatas.length;i++)
        // {
        //     if(imagedatas[i].type==UISelectType.BackgroundImage)
        //         this.addShowVectorImageView(imagedatas[i]);
        //      else if(imagedatas[i].type==UISelectType.VectorImage)
        //          this.addShowVectorImageView(imagedatas[i]);
        // }
    }

    addShowBackgroundImageView(imagedata)
    {
        // var imageView=new ShowBackgroundImageView();
        // imageView.init(this.divContainer);
        // imageView.setElementBaseValue(imagedata);
        // imageView.setRulerLineBaseValue(this.uiCoordinateView.rulerXBaseValue,this.uiCoordinateView.rulerYBaseValue);
        // this.elementList.push(imageView);
    }

    clearAll()
    {
        this.clearSelectState();
        for(var i=0;i<this.elementList.length;i++)
        {
            if( this.elementList[i].release!=null&&typeof(this.elementList[i])!="ShowSelectAreaView")
                this.elementList[i].release();
        }

        this.elementList=[];
    }

    clearSelectState()
    {
        this.uiSelectKeyPair=new UISelectKeyPair();
        this.uiSelectKeyPair.uuid=-1;
        this.uiSelectKeyPair.type=UISelectType.None;
        var clearElementValue=new ElementBaseValue();
        clearElementValue.uuid=-1;
        this.updateSelectedView(clearElementValue);
    }

    //回退及撤销逻辑

    deleteSelectUIImageData(callBack)
    {
        var select=ProjectDataManager.instance.getSelectCurrentData()
        if(select!=null)
        {
            UIRevocationRollback.instance.AddDataToUndo(ProjectDataManager.instance.getAllImageData());
            ProjectDataManager.instance.deleteImageData(select,callBack);
            this.updateSelectedView(new ElementBaseValue());
            if(this.eventsCallBack!=null)
            {
                this.eventsCallBack(UISelectEventType.Deleted,select);
            }
        }
    }





}
