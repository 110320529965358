import { useProject } from "../../hooks/useProject";
import { useState } from "react";
import Score from "../common/score";
import { useSearchBarContext } from "../../context/search";

// import topbar from '@/css/topbar.module.css'

export default function Topbar(){
    const [gotoAdd] = useProject();
    const searchBarContext = useSearchBarContext();

    const [tmpKey, setTmpKey] = useState<string>('');

    const handleKeyDown = (e: any) => {
        if(e.key == 'Enter'){
            searchBarContext?.setKeyword(tmpKey);
        }
    }

    return (
        <header className="flex h-[80px]">
            <div className='flex items-center justify-center flex-none w-[230px] '>
                <img src="/logo.png" className=" h-[57px]" />
            </div>
            <div className="grow grid grid-flow-col justify-stretch pl-[10px] pr-[30px]">
                <div className="flex items-center relative">
                    <input type="text" placeholder="Search for projects or images" className="py-[10px] pr-[15px] pl-[45px] border w-[50%] outline-none rounded-[10px]"
                        value={tmpKey} 
                        onChange={(e) => setTmpKey(e.target.value)}
                        onKeyDown={handleKeyDown}/>
                    <img src="/icon/icon-search.png" className="absolute left-[15px] top-[50%] translate-y-[-10px] w-[20px] h-[20px]" />
                </div>
                <Score className="flex flex-row-reverse items-center">
                    <button onClick={() => gotoAdd()} className="flex items-center rounded-[10px] bg-gradient-to-r from-[#5170FF] to-[#002EFF] text-[#FFFFFF] px-[10px] gap-[3px]"><span className="text-[18px]">+</span><span>New Project</span></button>
                </Score>
            </div>
        </header>
    )
}