import {UIKeyBorderType} from "./UIKeyBorderType";

export class UIKeyboardShortCut {
    constructor() {
        if (!UIKeyboardShortCut.instance) {
            this._RegisterKeyShortCut = {
                "key1": [],
                "key2": []
            };


            UIKeyboardShortCut.instance = this;
        } else {
            return UIKeyboardShortCut.instance;
        }
    }
    //删除按钮
init()
{
    var tmp=this;
    document.addEventListener('keydown', function(event) {
        // 检查是否同时按下了Ctrl键和S键
     //   event.preventDefault();
        //console.log("按键执行：："+event.key);
        if (event.ctrlKey && event.key === 's') {
            // 保存数据
            if(tmp._RegisterKeyShortCut.hasOwnProperty(UIKeyBorderType.Save))
            {
                if (tmp._RegisterKeyShortCut[UIKeyBorderType.Save][0]!=null)
                    tmp._RegisterKeyShortCut[UIKeyBorderType.Save][0]();
            }
        }

        if (event.ctrlKey && event.key === 'z') {
            // 撤销
            if(tmp._RegisterKeyShortCut.hasOwnProperty(UIKeyBorderType.Revoke))
            {
                //console.log("执行撤销操作");

                if (tmp._RegisterKeyShortCut[UIKeyBorderType.Revoke][0]!=null)
                    tmp._RegisterKeyShortCut[UIKeyBorderType.Revoke][0]();
            }
        }

        if (event.ctrlKey && event.key === 'b') {
            // 撤销
            if(tmp._RegisterKeyShortCut.hasOwnProperty(UIKeyBorderType.RollBack))
            {
                if (tmp._RegisterKeyShortCut[UIKeyBorderType.RollBack][0]!=null)
                    tmp._RegisterKeyShortCut[UIKeyBorderType.RollBack][0]();
            }
        }

        if (event.key === 8||event.key ===46||event.key=="Delete") {
            // 删除
            if(tmp._RegisterKeyShortCut.hasOwnProperty(UIKeyBorderType.Delete))
            {
                if (tmp._RegisterKeyShortCut[UIKeyBorderType.Delete][0]!=null)
                    tmp._RegisterKeyShortCut[UIKeyBorderType.Delete][0]();
            }
        }
        if (event.key === "`") {

            //console.log("执行了取消选择的按键");
            if(tmp._RegisterKeyShortCut.hasOwnProperty(UIKeyBorderType.Cancel))
            {
                if (tmp._RegisterKeyShortCut[UIKeyBorderType.Cancel][0]!=null)
                    tmp._RegisterKeyShortCut[UIKeyBorderType.Cancel][0]();
            }
        }
    });
}

   getKeyBorderDescript(uiKeyBorderType)
   {
       switch (uiKeyBorderType) {

           case UIKeyBorderType.Cancel:
               return "`";
           case UIKeyBorderType.Delete:
               return "Delete  <-";
           case UIKeyBorderType.RollBack:
               return "Ctrl + b";
           case UIKeyBorderType.Revoke:
               return "Ctrl + z";
           case UIKeyBorderType.Save:
               return "Ctrl + s";
           case  UIKeyBorderType.None:
               return "";
       }
   }


    Regiter(uiKeyBorderType,eventCallBack)
    {

        this.addKeyToPair(uiKeyBorderType,eventCallBack);

    }



    addKeyToPair(key,eventCallBack)
    {
        this._RegisterKeyShortCut[key] = [eventCallBack];
    }

    Get(key)
    {
        if( !this._RegisterKeyShortCut.hasOwnProperty(key))
            return null;
        return this._RegisterKeyShortCut[key][0];
    }

    Remove(key)
    {
        if( !this._RegisterKeyShortCut.hasOwnProperty(key))
            return;
        //键值对移除
        delete  this._RegisterKeyShortCut[key];
    }


}
