export  class UIAlignGraphics {

    constructor() {
        if (!UIAlignGraphics.instance) {

            this.clearColor = [0, 0, 0, 0];
            this.alignLineColor=[255,0,0,255];
            this.alignStrokeWidth=2;
            this.pointShapeSize=20;
            this.openAntiAlias=true;
            this.points=[];
            this.paint=null;
            UIAlignGraphics.instance = this;
        } else {
            return UIAlignGraphics.instance;
        }
    }

    init(Canvaskit,surface)
    {
        this.CK=Canvaskit;
        this.drawStyle=this.CK.PaintStyle.Stroke;
        this.subSurface =surface;
        this.subCanvas=this.subSurface.getCanvas();
        //创建画笔
        this.paint = new this.CK.Paint(); // 创建画笔对象
        this.paint.setStrokeWidth(this.alignStrokeWidth); // 设置线宽
        this.paint.setAntiAlias(this.openAntiAlias); // 开启抗锯齿
        this.paint.setColor(this.CK.Color(this.alignLineColor[0], this.alignLineColor[1], this.alignLineColor[2], this.alignLineColor[3])); // 设置线的颜色
        this.paint.setStyle(this.drawStyle);
    }



    //绘制对齐线
    drawAlignLines(datas,uiCoordinateView)
    {
        var lines=datas;
        for(var i=0;i<lines.length;i++)
        {
            //每条数据中包含多条线段数据
            var segments=lines[i];
            var draw_lines=[];
            for(var k=0;k<segments.length;k++)
            {
                //获取具体的线段了，每条线段至少包含三个点

                var point=segments[k];
                var p=[point[0],point[1]];
                if(uiCoordinateView!=null)
                    p=uiCoordinateView.position2px(point[0],point[1]);
                //根据点绘制标志符；
                var p1=[p[0]-this.pointShapeSize/2,p[1]-this.pointShapeSize/2];
                var p2=[p[0]+this.pointShapeSize/2,p[1]+this.pointShapeSize/2];
                var p3=[p[0]+this.pointShapeSize/2,p[1]-this.pointShapeSize/2];
                var p4=[p[0]-this.pointShapeSize/2,p[1]+this.pointShapeSize/2];
                this.subCanvas.drawLine(p1[0],p1[1],p2[0],p2[1], this.paint);
                this.subCanvas.drawLine(p3[0],p3[1],p4[0],p4[1], this.paint);
                draw_lines.push(p);
            }

           //console.log("当前线段数据为"+i+"::"+draw_lines);
            //绘制线段
            for(var m=0;m<draw_lines.length-1;m++)
            {
                this.subCanvas.drawLine(draw_lines[m][0], draw_lines[m][1], draw_lines[m + 1][0], draw_lines[m + 1][1], this.paint);
            }
        }


      //
      //   var points=datas;
      //   //清空线段
      //
      //   for(var i=0;i<points.length;i++)
      //   {
      //       //绘制单条对齐线
      //       var linePoints=points[i];
      //      //console.log("点集合："+linePoints+"::"+linePoints.length);
      //       for (var j=0;j<linePoints.length;j++)
      //       {
      //           //绘制点
      //           var drawLinePoints=linePoints[j];
      //           var draw_point=[];
      //           for(var k=0;k<drawLinePoints.length;k++)
      //           {
      //               var p1=uiCoordinateView.position2px(drawLinePoints[k][0],drawLinePoints[k][1]);
      //               draw_point.push(p1);
      //               this.subCanvas.drawLine(p1[0],p1[1],p2[0],p2[1], this.paint);
      //               this.subCanvas.drawLine(p3[0],p3[1],p4[0],p4[1], this.paint);
      //               draw_point.push()
      //
      //           }
      //       }
      //   }
      //
      //
      //   //获取线段
      //   // for(var i=0;i<points.length;i++)
      //   // {
      //   //     var lineData=points[i];
      //   //     //获取线段上的点
      //   //     for(var k=0;k<lineData.length;k++)
      //   //     {
      //   //         var line_point=lineData[k];
      //   //         var draw_point=uiCoordinateView.position2px(line_point[0],line_point[1]);
      //   //         //绘制这个点
      //   //             var p1=[draw_point[0]-this.alignStrokeWidth/2,draw_point[1]-this.alignStrokeWidth/2];
      //   //             var p2=[draw_point[0]+this.alignStrokeWidth/2,draw_point[1]+this.alignStrokeWidth/2];
      //   //
      //   //             var p3=[draw_point[0]+this.alignStrokeWidth/2,draw_point[1]-this.alignStrokeWidth/2];
      //   //             var p4=[draw_point[0]-this.alignStrokeWidth/2,draw_point[1]+this.alignStrokeWidth/2];
      //   //             this.subCanvas.drawLine(p1[0],p1[1],p2[0],p2[1], this.paint);
      //   //             this.subCanvas.drawLine(p3[0],p3[1],p4[0],p4[1], this.paint);
      //   //
      //   //     }
      //   // }
      //
      //
      //
      //
      //   for(var i=0;i<draw_point.length;i++)
      //   {
      //       var p1=[draw_point[i][0]-this.pointShapeSize/2,draw_point[i][1]-this.pointShapeSize/2];
      //       var p2=[draw_point[i][0]+this.pointShapeSize/2,draw_point[i][1]+this.pointShapeSize/2];
      //       var p3=[draw_point[i][0]+this.pointShapeSize/2,draw_point[i][1]-this.pointShapeSize/2];
      //       var p4=[draw_point[i][0]-this.pointShapeSize/2,draw_point[i][1]+this.pointShapeSize/2];
      // //     //console.log(p1[0]+":"+p1[1]+":"+p2[0]+"::"+p2[1]);
      //       this.subCanvas.drawLine(p1[0],p1[1],p2[0],p2[1], this.paint);
      //       this.subCanvas.drawLine(p3[0],p3[1],p4[0],p4[1], this.paint);
      //
      //       //绘制连线
      //
      //       if(i<draw_point.length-2) {
      //           this.subCanvas.drawLine(draw_point[i][0], draw_point[i][1], draw_point[i + 1][0], draw_point[i + 1][1], this.paint);
      //       }

    //    }


    }
};
